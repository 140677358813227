.message {
    &.error, &.notice, &.success, &.warning {
        background: none;

        a {
            color: $black;
            &:hover {
                color: $black;
            }
            &.white-button {
                &:hover {
                    color: $white;
                }
            }
        }

        & > *:first-child:before {
            content: none;
        }
    }
    &.notice, &.error {
        color: $pink;
    }

    i {
        position: absolute;
        top: 33px;
        right: 40px;
        cursor: pointer;
        &:before {
            font-size: 20px;
        }

        @media (max-width:767px) {
            top: 15px;
            right: 15px;
            font-size: 14px;
        }
    }

    .popin-error-ph {
        position: fixed;
        top: 0;
        background-color: rgba(0,0,0,.4);
        z-index: 999;
        height: 100%;
        width: 100%;
        right: 0;
    }

    .popin-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        color: $black;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        padding: 50px;
        margin: auto;
        flex-direction: column;

        @media (max-width:767px) {
            width: 90%;
            padding: 30px;
        }

        .message {
            font-size: 20px;
            width: 80%;
            margin: auto auto 20px;
            text-align:center;

            @media (max-width:767px) {
                width: 80%;
                font-size: 14px;
            }
        }
    }
}