.checkout-cart-index{

  .container-ph {
    @media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
      max-width: unset;
    }
  }

  .page-footer {
    .prefooter-container, .footer {
      @media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
        padding: 40px 20px;
      }
    }
  }

  .columns {
      margin-top: 30px;

      @media (max-width: $screen-max-sm){
          margin-top: 0;
      }

      .column.main {
          @media (max-width: $screen-max-sm) {
              padding: 0;
          }
      }
  }
  .page-main{
      margin-top: 0;
      padding-left: 30px;
      padding-right: 30px;
  }

  .page-title-wrapper,
  .cart-title{
    @extend .page-title-type;
    padding: 18px 15px 30px;

    @media (max-width: $screen-max-sm){
      padding-bottom: 24px;
    }

  }

}

.cart-container{
  font-size: 0;
  @extend .summary-table-elements;

  &+.cart-container{

    .payment-option{

      &.opc-payment-additional{

        .block{
          border-top: none;
          &.gift {padding-bottom: 0}
        }

      }

    }

  }

  .cart-summary{
    @extend .summary-sidebar-container;
    @extend .summary-title;

      .checkout-methods-items{
          button{
              min-width: unset;
          }
      }
    @media (max-width: $screen-max-sm){
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 30px;

      .title{
        display: block;
      }

      .checkout-methods-items{
        margin-top: 14px;
        padding: 0;
      }

    }

    .block{

      .title{
        border: none;
      }

    }

    .btn{
      width: 100%;
      font-weight: initial;

    }

  }

  #cart-totals{
    padding-top: 0;
    border: none;

    @media (max-width: $screen-max-sm){
      padding-left: 0;
      padding-right: 0;
    }

  }

  .gift-payment,
  .cart-discount,
  #form-validate{
    @extend .summary-central-column;

    .actions-toolbar{
      vertical-align: top;
    }

  }

  #form-validate{
    margin-bottom: 45px;

    @media (max-width: $screen-max-md){
      margin-bottom: 10px;

      tbody.cart{
        border: 1px solid #D2D2D2;
      }

    }

    .cart{
      width: 100%;
      margin-bottom: 0;

      @media (max-width: $screen-max-md){
        display: block;
        margin-bottom: 16px;

        &.table-wrapper{
          margin-bottom: 0;
          border: none;
        }

      }

      .item-custom-actions {
        td {
          border-bottom: 1px solid #d2d2d2;
        }

        .action-gift {
          display: none;
        }
      }

    }

  }

  .gift-payment,
  .cart-discount{
    padding-right: 20px;
    border: none;

    .actions-toolbar {
      .action.cancel{
        border-radius: 0;
        color: $white;
        border-color: $black;
        background: $black;
        margin: 0;
        &:not(:focus){
          box-shadow: none;
        }
      }
        .btn {
            border-radius: 0 8px 8px 0 !important;
        }
    }

    .block{
      display: block;
      width: 100%;

      &.gift,
      &.discount{
        padding: 38px 0;
        border-top: 3px solid #000;
        border-bottom: 3px solid #000;

        @media (max-width: $screen-max-sm){
          padding: 30px 0;
            margin-bottom: 30px;
        }

      }
      &.cart-bag-no-border-top {
          border-top: 0;
          padding: 0 0 38px 0;
      }

      &.gift {border-bottom: none}

      .title{
        font-family: $futura-heavy;
        font-size: 15px;
        line-height: initial;
        color: #000;
        text-transform: uppercase;
        padding-bottom: 14px;

        @media (max-width: $screen-max-sm){
          border: none;
          padding: 0;
          margin-bottom: 15px;
        }

        &:after{
          content: none;
        }

      }

      .content{
        display: block;
        padding: 0 0 10px 0;

        @media (max-width: $screen-max-sm){
          padding: 0;
        }

      }

      &.no-border-top {
        border-top: none;
        padding-top: 0;
      }

      &.ph-block-discount {
        .title {
          margin-bottom: 0;
          padding-bottom: 0;
          color: $black;
        }
        .sub-title {
          padding-bottom: 14px;
          margin-bottom: 10px;

          @media (max-width: $screen-max-sm){
            padding: 0;
            margin-bottom: 15px;
          }
        }
      }

      &.ph-giftcard-message {
        .title {
          color: $black;
        }

        .ph-giftcard-message-content {
          display: flex;
          gap: 10px;

          .left {
            max-width: 340px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                line-height: 1.4;
              margin-bottom: 5px;
            }
          }
          .right {
            margin-right: 15px;

            .title {
              margin-bottom: 3px;
              padding-bottom: 0;
              color: $black;
            }

            .sub-title {
              line-height: 1;
            }
          }

          @media (max-width: $screen-max-sm){
            padding-top: 0px;
            flex-direction: column;

            .left {
              width: 100%;
              max-width: 100%;
                margin-bottom: 10px;
            }
            .right {
              width: 100%;
              max-width: 100%;
            }
          }
        }
        .ph-giftcard-message-buttons {
          width: 200px;
          height: 50px;

          @media (max-width: $screen-max-sm){
            margin-left: 0;
            width: 100%;
          }

          .cta {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

    }

    #block-discount-heading{
      display: inline-block;
        line-height: initial;
        margin-bottom: 15px;
    }

    #discount-coupon-form, .form-cart-bag, .form-cart-almond, .form-cart-candle{
      width: 100%;
      max-width: 565px;
    }

    .fieldset{
      display: table;
      width: 100%;
      margin-bottom: 0;

      @media (max-width: $screen-max-sm){
        display: block;

        .field{
          display: block;
          margin-bottom: 10px;
        }

      }

      .input-text{
        height: 50px;
        border: 1px solid $lightgrey;
        border-right: none;
        box-shadow: none;
        padding: 10px;
          font-family: FuturaBook,Arial,sans-serif;
          font-size: 16px;
        &:focus {
          border: 1px solid $black;
          border-right: none;
          transition: all .3s;
        }
        &::placeholder{
          text-transform: uppercase;
            font-family: FuturaBook,Arial,sans-serif;
            font-size: 12px;
            color: $grey;
        }
      }
      #coupon_code {
        @media (max-width: $screen-max-sm){
          border-right: 1px solid #e2e2e2;
        }
      }

      .actions-toolbar{
        padding-bottom: 0;
        display: table-cell;
        vertical-align: top;
        width: 1%;

        .btn{
          height: 50px;
          min-height: 50px;
          min-width: 200px;

          @media (max-width: $screen-max-sm){
            width: 100%;
          }

        }

      }

    }

  }

  .gift-payment{

    .fieldset{

      .field{
        margin-bottom: 15px;
      }

      .actions-toolbar{

        .btn{
          padding: 0 15px;
        }

      }

    }

    .amgiftcard_info{
      margin-bottom: 15px;
    }

  }

}

.amgiftcard-check{
  width: 100%;
}

.codes-list{
  display: block;
  width: 100%;
  margin: 10px 0;

  .code-one{
    display: flex;
    display: -webkit-flex;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e2e2e2;

    &:last-child{
      border-bottom: none;
    }

    .delete{
      margin-left: 35px;
      color: $black;
    }

  }

}

#shopping-cart-table{
  @include border-radius(2px);
  border-top: 1px solid #D2D2D2;
  border-left: 1px solid #D2D2D2;
  border-right: 1px solid #D2D2D2;
  border-collapse: unset;

  @media (max-width: $screen-max-md){
    border: none;
  }

  .item-info{

    @media (max-width: $screen-max-md){
      float: none;
      position: relative;
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
    }

    .col{

      &.qty{

        @media (max-width: $screen-max-md){
          display: inline-block;
          width: 50%;
          padding-left: 20px;
          padding-top: 0;
          border-bottom: 0;

          &[data-th]{

            &:before{
              content: none;
            }

          }

        }

        .field{
          margin-top: 20px;

          @media (max-width: $screen-max-md){
            margin-top: 8px;
          }

        }

        .control{
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: space-between;
          @extend .qty-controllers;

          @media (max-width: $screen-max-md) {
            max-width: 110px;
          }

          .input-text {
            border-color: $lightgrey;
            border-radius: 0;
            margin: -3px 8px 0;
            width: 100px;

            &:focus{
              box-shadow: none;
            }

            @media (max-width: $screen-max-md){
              width: 50px;
            }

            //@media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
            //  height: 30px;
            //  width: 55px;
            //}

          }

        }

      }

    }

    .cart-price{
      display: block;
      font-family: $futura-heavy;
      font-size: 12px;
      color: $black;

      @media (max-width: $screen-max-md){
        margin-top: 5px;
      }

    }

    .col{
      padding: 20px 8px 15px 0;
      border-bottom: 1px solid #ccc;

      @media (max-width: $screen-max-md){
        padding: 20px 10px 25px 0;
      }
      &.qty {
        width: 15%;
        vertical-align: middle;

        @media (max-width: $screen-max-md){
          width: 50%;
          vertical-align: top;
        }
      }

      &.subtotal{
        padding-right: 40px;
        width: 15%;
        vertical-align: middle;
        span{
          margin-bottom: 10px;
        }

        //@media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
          //padding-right: 10px;
        //}

        @media (max-width: $screen-max-md){
          vertical-align: top;
          display: inline-block;
          width: 50%;
          padding-right: 15px;
          text-align: right;
          border-bottom: 0;
          padding-top: 8px;
          padding-bottom: 40px;

          &:before{
            content: none;
          }

        }

        .price {
          font-weight: 400;
        }

      }

    }

    .item{

      &:first-child{
        padding-left: 30px;
        width: 100%;

        //@media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
        //  padding: 10px;
        //}

        @media (max-width: $screen-max-md){
          position: static;
          display: flex;
          display: -webkit-flex;
          padding: 20px 12px 0;
          border-bottom: 0;
          width: 100%;
        }

      }

      .product-item-details{
        padding-top: 20px;
        padding-bottom: 48px;
        height: 160px;
        vertical-align: middle;

        @media (max-width: $screen-max-md){
          height: auto;
        }

        .actions-toolbar {
          margin: 20px 0;

          @media (max-width: $screen-max-md){
            margin: 0;
            padding: 0;
          }

          .towishlist{
            position: absolute;
            bottom: -30px;
            width: 200px;
            left: 130px;
            margin: 0;
            top: unset;

            @media (max-width: $screen-max-md){
              bottom: 20px;
              left: 130px;
            }

          }
          .action-delete{
            position: absolute;
            bottom: -30px;
            width: 130px;
            left: 0;
            margin: 0;
            top: unset;

            @media (max-width: $screen-max-md){
              bottom: 20px;
              left: 20px;
            }
          }

          .gift-content{

            .fieldset{
              border: 1px solid $lightgrey;
              padding: 5px;

              input{
                padding: 5px;
                box-shadow: none;
                font-size: 11px;
              }

              .field{
                margin: 0;
                .control{
                  display: flex;
                  display: -webkit-flex;

                  .black-button{
                    padding: 0 10px;
                  }

                }

              }

            }

            .required-to-pass{
              border: 1px solid $black;
            }
          }

        }

        @media (max-width: $screen-max-md){
          position: static;
          display: inline-block;
          width: calc(100% - 85px);
          padding: 0 0 0 25px;
          vertical-align: top;

          .actions-toolbar {
            position: initial;
          }

        }

      }

      .product-image-container{
        width: 105px;
        max-width: 105px;

        @media (max-width: $screen-max-md){
          width: 85px;
          max-width: 85px;
        }

      }

      .product-item-photo{
        padding-right: 40px;
        vertical-align: middle;
        //@media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
        //  padding-right: 10px;
        //}

        @media (max-width: $screen-max-md){
          display: inline-block;
          position: relative;
          top: auto;
          left: auto;
          width: auto;
          max-width: 100%;
          padding: 0;
          vertical-align: top;
        }

      }

      .product-item-name{
        font-family: $futura-heavy;
        font-size: 14px;
        margin: 0 0 5px;

        @media (max-width: $screen-max-md){
          line-height: 1;
        }

        &+.item-options{
          margin-top: 0;
        }

        &:hover{
          text-decoration: none;
        }

      }

      .actions-toolbar{
        margin-top: 50px;
        padding-bottom: 0;

        @media (max-width: $screen-max-md){
          position: absolute;
          z-index: 1;
          margin: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 15px 18px;
        }


        .action-towishlist{
          position: relative;
          color: rgba($black, 0.3);
          &::before {
            content: $icon-heart;
            font-family: $icomoon-font-family;
          }
        }

        .action-edit {
          &:before {
            vertical-align: top;
            content: $icon-edit;
            font-family: $icomoon-font-family;
          }
        }

        .action-trash, .action-delete {

          &:before {
            vertical-align: top;
            content: $icon-trash;
            font-family: $icomoon-font-family;
          }

        }

        .action-edit, .action-delete, .action-towishlist {
          position: initial;
          color: rgba($black, 0.3);
          transition: color .3s;

          &:before {
            color: rgba($black, 0.3);
            font-size: 14px;
            vertical-align: bottom;
            margin-right: 5px;
            transition: color .3s;
          }

          & > span {
            height: unset;
            margin: 0;
            overflow: visible;
            position: initial;
            width: auto;
          }
        }

          .action-edit-compo {
              color: $black;
              &:before {
                  color: $black;
              }
          }

        .control {
          position: relative;
          .action {
            position: relative;
            top: auto;
            right: auto;
            text-decoration: none;

            @media (max-width: $screen-max-md) {
              margin-right: 5px;

              &:last-child {
                margin-right: 0;
              }

              &.action-edit {
                //TODO: @SK -> pas bien
                display: none;
              }

            }

            &:before {
              margin-right: 6px;
            }

            span {
              position: relative;
              width: auto;
              height: auto;
              clip: unset;
              white-space: nowrap;
              margin: 0;
              font-size: 12px;
              letter-spacing: .42px;
              color: #BDBDBD;
            }

            &.black-button {

              span {
                color: #fff;
              }

              &:hover {
                span {
                  color: $black;
                }
              }
            }
          }

          textarea {
            padding: 10px 90px 10px 10px;
          }
        }

        .gift-content {
          padding: 0;
          float: unset;
          border: none;

          .gift-summary {
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .gift-message-summary {
              padding: 0;
            }
            .actions-toolbar {
              margin: 0;
              position: relative;

              .secondary {
                @media (max-width: $screen-max-sm) {
                  text-align: left;
                }
              }

              button {
                width: unset;
                position: unset;
                margin-left: 0;

                &.action-delete {
                  margin-left: 5px;
                }
                &:before {
                  font-size: 14px;
                  color: #b4b4b4;
                }
                span {
                  display: none;
                }
              }
            }
          }

          .gift-options {
            margin: 0;

            .gift-message {
                #gift-message-error{
                    color: $pink;
                    display: none;
                    &.visible{
                        display: block;
                    }
                }
              .gift-options-title {
                margin: 0;
                margin-bottom: 10px;
                font-size: 12px;
                font-style: italic;

                &.gift-options-special-char {
                  font-size: 10px;
                  font-weight: bold;
                  margin-top: 5px;
                  margin-bottom: 5px;
                  color: rgba(0,0,0,.5);
                }
              }

              .gift-options-content {
                fieldset {
                  margin: 0;
                  .field {

                    textarea {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }

      }

    }

  }

}

.gift-item-block{
  border-bottom: none;
  .gift-options-content {
    .control{
      display: flex;
      align-items: flex-end;
    }
  }
}

.crosssell{

  &.block{
    width: 100%;
    padding: 0;
    margin-top: 20px;

    @media (max-width: $screen-max-sm){
      margin-top: 0;
    }

    .block-title{
      margin-top: 0;

      @media (max-width: $screen-max-sm){
        padding-top: 28px;
        padding-bottom: 0;

        strong {
          margin-bottom: 0;
        }

      }

      &:before{
        position: relative;
        top: auto;
        left: auto;
        height: 44px;
        @include transform(none);
        display: block;
        margin: 0 auto 12px;

        @media (max-width: $screen-max-sm){
          height: 30px;
        }

      }

    }

    .block-content{
      margin-top: 0;

      @media (max-width: $screen-max-sm){
        padding: 0 15px;
      }

      .products{
        margin-top: 0;
        margin-bottom: 0;

        .product-item{
          padding-top: 0;
          @media (min-width: $screen-min-sm){
            width: 50%;
            margin: 0;
            height:100%;
          }

          .product-item-info{

            @media (max-width: $screen-max-sm){
              display: block;

              .product-item-details{
                padding-left: 0;
                padding-right: 0;
              }

            }

          }

          .product-item-actions{

            .actions-secondary{

              .action{

                @media (max-width: $screen-max-sm){
                  width: 45px;
                }

              }

            }

          }

        }

      }

      .products-crosssell{
        margin-bottom: 80px;


        .shipping-container-block p {
          margin-bottom: 10px;
        }
      }

    }

  }

}

.cart-empty{
  text-align: center;
  .continue-shop{
    max-width: 300px;
    margin: 30px auto 0;
    @media (max-width: $screen-max-md) {
      width: 100%;
    }
    a{
      display: block;
    }
  }
}

.cart-bag{
  clear: both;
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: $screen-max-sm){
    width: 100%;
      margin-top: 30px;
      margin-bottom: 0;
  }

  .switch{

    input[type=checkbox]+label{
      font-size: 15px;
    }

    label{
      font-family: $futura-heavy;
      text-transform: uppercase;

      @media (max-width: $screen-max-sm){
        padding: 0 0 0 60px;
      }

    }

  }

}

/*TODO: remove*/
#block-shipping{
  display: none;
}

.giftbag-qty {
  text-align: center;
  font-size: 12px;
  margin-top: 25px;

  @media (max-width: $screen-max-md) {
    margin-top: 10px;
  }
}

.form-cart-bag, .form-cart-almond, .form-cart-candle {
  .fieldset {
    max-width: 550px;
    .select2-container .select2-selection--single {
      height: 50px;
      display: table;
      border: 1px solid #e2e2e2;
        border-radius: 0;

      .select2-selection__rendered {
        display: table-cell;
        vertical-align: middle;
      }
      .select2-selection__arrow {
        bottom: 15px;
          top: unset;
          height: unset;
      }
    }
  }
}
