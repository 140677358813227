.checkout-onepage-failure {
    .page-title{
        @extend .text-uppercase;
        padding: 40px 20px 20px;
        margin-top: 15px;
        text-align: center;
        position: relative;
        width: 100%;
        font-weight: initial;
        font-size: 25px;
        box-sizing: border-box;

        @media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
            padding: 40px 20px 0;
            margin-top: 35px;
        }

        @media (max-width: $screen-max-sm){
            padding: 35px 15px 20px;
            margin-top: 35px;
        }

        &.title{
            strong{
                font-size: 28px;
                font-family: $futura;
                font-weight: unset;
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 30px;
            background-color: #C72C48;
            left: 50%;
            top: 0;
            transform: translateY(-50%);

            @media (max-width: $screen-max-sm){
                height: 30px;
            }

        }
    }

    .columns .column.main {
        text-align: center;

        .btn {
            display: inline-block;
            margin-top: 30px;
        }
    }

}