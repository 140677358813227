.double-picture-legend-text{
  margin-bottom: 35px;
  .blocks{
    display: flex;
    justify-content: center;
    align-items: center;

    .block-double{
      .picture{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 280px;
        height: 280px;
        @media (max-width: $screen-max-sm){
          width: 147px;
          height: 147px;
        }

      }
      .legend-picture{
        margin-top: 10px;
        font-size: 12px;
        color: $grey-widget;
        letter-spacing: 0.54px;
      }
    }
  }
  .contenu{
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.6px;
    line-height: 30px;
    width: 90%;
    margin: 40px auto 0;
    @media (max-width: $screen-max-md){
      font-size: 16px;
    }
  }
}