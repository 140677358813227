.showcase-slider {
    &-title {
        font-size: 25px;
        padding: 40px 0 0;
        margin: 50px 0 0;
        text-align: center;

        @media (max-width: $screen-max-sm) {
            padding-top: 32px;
        }
    }
    &-content {
        &-item {
            padding: 25px;
            &--thumb {
                margin: 0 20px;
            }
            &--name {
                font-weight: initial;
                font-size: 18px;
                font-family: $futura-heavy;
                color: $black;
                hyphens: none;
                line-height: 26px;
                max-width: 85%;
                text-transform: uppercase;
                margin: 0 20px 10px 20px;

                @media (max-width: $screen-max-sm) {
                    max-width: 100%;
                    font-size: 14px;
                    line-height: 1.1;
                }
            }
            &--desc {
                margin: 0 20px;
                > p {
                    font-family: $futura-light;
                    font-size: 16px;
                }
            }
        }
    }

    #showcase-slider {
        position: relative;
        padding-bottom: 20px;

        .owl-nav {

            button {
                box-shadow: none;
                position: absolute;
                top: 50%;
                background: transparent;
                border: none;
                @include transition(all ease 0.4s);


                i {
                    font-size: 25px;
                    color: $black;
                }

                &.owl-prev {
                    left: 0;

                    @media (max-width: $screen-max-sm) {
                        left: -10px;
                        margin: 0;
                    }

                }

                &.owl-next {
                    right: 0;

                    @media (max-width: $screen-max-sm) {
                        right: -10px;
                        margin: 0;
                    }

                }

                &:hover, &:focus {
                    i {
                        color: $black;
                    }
                }
            }

        }

        .owl-dots {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 50%;
            @include transform(translateX(-50%));

            @media (max-width: $screen-max-md) {
                margin-top: 0;
            }


            button {

                &.owl-dot {

                    span {
                        display: none;
                    }

                }

            }

        }
    }
}
