.btn{
    font-family: $futura-light;
    font-weight:200;
    font-size: 13px;
    letter-spacing: .46px;
    text-transform: uppercase;
    line-height: 1.2;
    min-height: 52px;
    padding: 18px;
    color: $black;
    background-color: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    text-align: center;
    min-width: 280px;
    @include border-radius(0px);
    @include transition(all ease 0.3s);
    text-decoration: none;
    display: inline-block;

    @media (max-width: $screen-max-xs) {
        min-width: 100%;
    }

    &.btn-transparent{
        background-color: transparent;
        color: $white;
        border: 1px solid $white;
        &:hover{
            background-color: $white;
            color: $black;
            border: 1px solid $white;
        }
    }

    &.btn-black{
        color: $white;
        background-color: $black;
        border-color: $black;
    }

    &.dark-pink{
        color: $white;
        background-color: $dark-pink;
        border-color: $dark-pink;
        &:hover{
            background-color: $white;
            color: $dark-pink;
            border-color: $dark-pink;
        }
        &.fill{
            &:hover {
                background-color: darken($dark-pink, 5%);
                border-color: $dark-pink;
                color: $white;
            }
        }
    }

    &.btn-red{
        color: $white;
        background-color: $black;
        border-color: $black;
    }

    &:hover{
        background: $black;
        color: $white;
        text-decoration: none;
    }
    &.xs{
        padding: 9px 17px;
        min-height: unset;
        min-width: unset;
        font-family: $futura;
    }
}
