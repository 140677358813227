.three-blocks{
    margin-top: 70px;
    @media (max-width: $screen-max-sm) {
        margin-top: 75px;

        .title-trait{
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            line-height: 1.2;
        }

    }

    .items-three-block{
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        margin-top: 85px;

        @media (max-width: $screen-max-md) {
            flex-direction: column;
            margin-top: 30px;
        }

        @media (max-width: $screen-max-sm){
            margin-left: -15px;
            margin-right: -15px;
            margin-top: 0;
        }

        .item-three-block{
            margin: 0 10px;
            width: calc((100% / 3) - 20px);

            @media (max-width: $screen-max-md) {
                width: 100%;
                margin: 0 auto 20px;
            }

            &:nth-child(2){
                margin-top: -85px;

                .descr-three-block{
                    border-bottom: 3px solid $black;
                    margin-top: -72px;
                }

                @media (max-width: $screen-max-md) {
                    height: auto;
                    margin-top: 0;
                }

            }

            .picture-three-block{
                width: 100%;
                height: 335px;
                background-repeat: no-repeat;
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center;

                @media (max-width: $screen-max-sm){
                    height: 328px;
                }

            }

            .descr-three-block{
                background-color: $white;
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 90%;
                margin: auto;
                height: 200px;
                border-bottom: 3px solid $black;
                margin-top: -90px;

                @media (max-width: $screen-max-sm){
                    display: block;
                    margin-top: -84px;
                    text-align: center;
                    padding-top: 30px;
                    padding-bottom: 24px;
                }

                .threeblocks-titleblock{
                    height: 60px;
                    margin-top: 25px;

                    &.title-trait{
                        font-size: 18px;
                        line-height: 1.2;
                        padding: 20px 40px 0;
                        line-height: inherit;
                        font-family: $futura-heavy;
                        font-weight: initial;
                        margin-bottom: 20px;

                        @media (max-width: $screen-max-sm){
                            margin-top: 0;
                            height: auto;
                            padding: 0 15px;
                            margin-bottom: 34px;
                        }

                        &::before{
                            height: 21px;

                            @media (max-width: $screen-max-sm){
                                position: relative;
                                display: block;
                                margin: 0 auto;
                                left: auto;
                                top: -10px;
                                transform: none;
                            }

                        }

                    }

                }

                .cta{
                    margin-bottom: 25px;
                    padding: 8px 14px;

                    @media (max-width: $screen-max-sm){
                        display: inline-block;
                        margin: 0;
                    }

                }

            }

        }

    }
    &.slider-blocks{
        .items-three-block{
            &:nth-child(2){
                margin-top: 0;
            }
            &.slick-slider{
                display: flex;
                flex-direction: column;

                .slick-list{
                    .slick-track {
                        margin-top: 85px;

                        @media (max-width: $screen-max-md) {
                            margin-top: 30px;
                        }

                        @media (max-width: $screen-max-sm){
                            margin-top: 0;
                        }

                        .slick-slide{
                            margin: 0 10px;
                            max-width: calc(100vw - 70px);
                            width: 100%;
                            @media (min-width: 420px) {
                                max-width: 300px;
                                width: 300px;
                                margin: 0 10px;
                            }

                            &.slick-current {
                                .item-three-block{
                                    margin-top: -85px;

                                    @media (max-width: $screen-max-md) {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-dots{
                    padding: 40px 0 0 0;
                }
            }
        }
    }
}

