.switch {

  input[type=checkbox],
  input[type="checkbox"] {
    display: none;

    & + label {
      position: relative;
      display: inline-block;
      font-size: 14px;
      padding-left: 60px;
      cursor: pointer;


      @media (max-width: $screen-max-sm) {
        width: 100%;
        font-size: 13px;
      }

      &:before,
      &:after {
        display: inline-block;
        box-sizing: border-box;
      }

      &:before {
        content: "";
        width: 50px;
        height: 30px;
        position: absolute;
        top: -7px;
        left: 0;
        @include border-radius(15px);
        border: 1px solid rgba(#000, .1);
        background-color: #F3F3F3;
        margin-right: 15px;
        vertical-align: middle;
        @include transition(background-color .3s $easeOutQuad, border-color .3s $easeOutQuad, box-shadow .3s $easeOutQuad);

        @media (max-width: $screen-max-sm) {
          vertical-align: top;
        }

      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -6px;
        left: 0;
        background-color: #FFF;
        width: 28px;
        height: 28px;
        @include border-radius(50%);
        border: 1px solid rgba(#000, .1);
        @include box-shadow(0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1));
        @include transition(left .3s $easeOutQuad);
      }

      span {
        vertical-align: middle;
        color: $black;
      }

    }

    &:hover {

      & + label {

        &:before {
          @include box-shadow(-2px -2px 6px -3px rgba(0, 0, 0, .2) inset);
        }

      }

    }

    &:checked {

      & + label {

        &:before {
          background-color: #60A285;
          border-color: rgba(#000, .1);
        }

        &:after {
          left: 22px;
        }

      }

    }

  }

}

.admin__field-control,
.radio {

  input[type=radio],
  input[type="radio"] {
    display: none;

    & + label {
      position: relative;
      display: inline-block;
      font-family: $futura-light;
font-weight:200;
      font-size: 12px;
      color: #000;
      width: auto;
      line-height: 1.6;

      &:before,
      &:after {
        display: inline-block;
        box-sizing: border-box;
      }

      &:before {
        content: "";
        width: 18px;
        height: 18px;
        @include border-radius(50%);
        border: 1px solid #979797;
        background-color: #FFF;
        margin-right: 14px;
        vertical-align: top;
        @include transition(background-color .3s $easeOutQuad, border-color .3s $easeOutQuad);
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background-color: $black;
        width: 10px;
        height: 10px;
        @include border-radius(50%);
        @include transform(scale(0));
        opacity: 0;
        @include transition(transform .3s $easeOutQuad, opacity .3s $easeOutQuad);
      }

    }

    &:hover {

      & + label {

        &:before {
          border-color: #000;
        }

      }

    }

    &:checked {

      & + label {

        &:before {
          border-color: #000;
        }

        &:after {
          @include transform(scale(1));
          opacity: 1;
        }

      }

    }

  }

}

.group-radio {
  width: 100%;

  .radio {
    display: inline-block;

    label {
      cursor: pointer;
    }
  }

}

form {

  textarea,
  input[type=text] {
    font-family: $futura-light;
font-weight:200;
    font-size: 16px;
    border: none;
  }

}


input[type=checkbox] {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.classic-input {
  width: 100%;
  font-family: $futura-light;
font-weight:200;
  font-size: 13px;
  height: 32px;
  color: #000;
  border: none;
  padding: 0;
  background-color: #FFF;
  @include transition(border-color .3s $easeOutQuad);

  &:focus {
    border-color: #000;
  }

}

.password-none {

  .password-strength-meter {
    font-size: 13px;
    font-style: italic;
    background-color: #E9E9E9;

    &:before {
      width: 100%;
    }

  }

}

.form-disclaimer {
  font-family: $futura-light;
font-weight:200;
  line-height: 1.4;
  margin: 0;
  color: #999;
  font-style: italic;
  font-size: 11px;

  @media (max-width: $screen-max-sm) {
    line-height: 1.5;
  }

  a {
    color: #858585;
    text-decoration: underline;
    transition: all ease .4s;
  }

}

.password-very-strong, .password-strong {
  color: $white;
  text-align: center;
  font-style: italic;
  font-size: 13px;

  .password-strength-meter {
    &::before {
      background-color: $green;

    }
  }
}

.fieldset > .field {
  margin-bottom: 35px;
}

.field {
  margin-bottom: 35px;
  position: relative;

  .password-strength-meter {
    font-size: 11px;
  }

  &.street .field.additional .label,
  .label {
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
    z-index: 3;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    cursor: text;
    font-weight: initial !important;
    // Added le 28/08/2019
    width: auto;
    height: auto;
    clip: unset;
  }

  &.street {

    &.admin__control-fields {

      & > .label {
        display: none;
      }

    }

  }

  &.focused .label {
    transform: translateY(-100%);
    font-size: 14px;
  }

  input:not([type='checkbox']):not([type='number']) {
    position: relative;
    padding: 12px 0 5px 0;
    width: 100%;
    outline: 0;
    border: 0;
    box-shadow: 0 2px 0px -1px $black;
    transition: box-shadow 150ms ease-out;
    border-radius: 0;
    background-clip: inherit;
      -webkit-box-shadow: 0 2px 0px -1px $black;
      -webkit-appearance: none;

    &:focus {
      //box-shadow: 0 1px 0 0 $pink;
    }

  }

  input:not([type='checkbox']):not([type='number']).filled {
    //box-shadow: 0 1px 0 0 $pink;
  }

  &.required {

    .label {

      &:after {
        color: #999999;
      }

    }

  }

  & > .label {
    color: #999999;
    font-size: 14px;
  }

  & > .control {
    width: 100%;
  }

  &._error input:not([type='checkbox']):not([type='number']),
  input:not([type='checkbox']):not([type='number']).mage-error {
    box-shadow: 0 1px 0 0 $pink; //Dans general.scss > important deja present
  }

}

fieldset {

  &.field {

    &.street {

      & > legend.label {
        position: relative;
        display: block;
        width: 100%;
        top: auto;
        left: auto;
        margin-bottom: 25px;
      }

    }

  }

}

.field-name-prefix, .choice, .configurable, .qty {
  .label {
    position: unset;
  }
}

.choice {

  .label {
    cursor: pointer;
  }

}

select {
  border: none;
  border-bottom: 1px solid $lightgrey;
}

/* asterisque */
.fieldset > .field.required > .label:after, .fieldset > .fields > .field.required > .label:after, .fieldset > .field._required > .label:after, .fieldset > .fields > .field._required > .label:after {
  margin: 0;
}


div.mage-error[generated], div.mage-error, span.mage-error, span.mage-error[generated] {
  color: $pink;
}

.required-fields-info {
  font-style: italic;
  color: #999;
  font-size: 13px;
}

.field {

  &.date {

    ._has-datepicker {
      cursor: pointer;
    }

  }

}

.fluide-field .field.required > .label:after,
.fieldset .field.required > .label:after,
.fieldset .field.required .field._required > .label:after,
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px;
}

// for form amasty custom

.rendered-form.amform-form .fieldset .fields .field.form-group {
  margin-bottom: 45px;
}

.fb-radio {
  display: flex;
  margin-bottom: 42px;

  .label {
    text-transform: uppercase;
    color: $black;
    font-size: 13px;
    width: 15%;
    margin: 0 0 8px;
    display: inline-block;
    position: unset;
    @media (max-width: $screen-max-md) {
      width: 100%;
    }
  }

  .control {
    width: 64%;
    @media (max-width: $screen-max-md) {
      width: 100%;
    }

    .radio-group {
      width: 100%;

      > div {
        margin-left: 15px;
        @media (max-width: $screen-max-md) {
          margin-left: 0;
        }

        input[type='radio'] {
          display: none;

          &:hover {
            & + label {
              &:before {
                border-color: $black;
              }
            }
          }

          &:checked {
            & + label {
              &:before {
                border-color: $black;
              }

              &:after {
                transform: scale(1);
                opacity: 1;
              }
            }
          }
        }

        label {
          cursor: pointer;
          font-family: $trade-gothic-light;
          font-weight: initial;
          display: block;
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 8px;
          position: relative;

          &:before {
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 50% 50% 50% 50%;
            border: 1px solid #979797;
            background-color: $white;
            margin-right: 14px;
            vertical-align: top;
            transition: background-color .3s cubic-bezier(.25, .46, .45, .94), border-color .3s cubic-bezier(.25, .46, .45, .94);
            display: inline-block;
            box-sizing: border-box;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 4px;
            left: 4px;
            background-color: $pink;
            width: 10px;
            height: 10px;
            border-radius: 50% 50% 50% 50%;
            -ms-transform: scale(0);
            transform: scale(0);
            opacity: 0;
            transition: transform .3s cubic-bezier(.25, .46, .45, .94), opacity .3s cubic-bezier(.25, .46, .45, .94);
            display: inline-block;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  @media (max-width: $screen-max-md) {
    flex-direction: column;
  }

}

.fb-date.field {
  label {
    transform: translateY(-100%);
    font-size: 14px;
  }

  .control {
    ._has-datepicker {
      padding: 20px 0;
      width: 100%;
      font-family: $futura-heavy;
      font-size: 12px;
      transition: all .3s;
      cursor: pointer;
      vertical-align: middle;

      & ~ .ui-datepicker-trigger {
        margin-left: 10px;
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 10px;
        font-size: 18px;
        color: #999;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -1px;

        &:before {
          content: $icon-calendar;
          font-family: $icomoon-font-family;
          font-size: 20px;
        }
      }
    }
  }
}
