.video-container.yt {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin: auto;

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
