.img-text-superpose {
    display: flex;
    position: relative;

    @media (max-width: $screen-max-sm) {
        flex-direction: column;
    }

    .picture {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 440px;
        @media (max-width: $screen-max-sm) {
            width: 100%;
            height: 350px;
        }
    }

    .contenu {
        background-color: $white;
        font-family: $futura-light;
        min-width: 50%;
        font-size: 18px;
        padding: 30px 30px 30px 70px;
        letter-spacing: 0.4px;
        max-width: 500px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__title{
            font-family: $futura-light;
            text-transform: uppercase;
            font-size: 26px;
            @media(max-width: $screen-max-sm) {
                font-size: 22px;
            }
            text-align: center;
        }
        @media (max-width: $screen-max-sm) {
            padding: 30px;
            width: 100%;
            max-width: unset;
            font-size: 16px;
            text-align: center;
        }

        p {
            margin: 0;
        }

    }

    &.right {
        flex-direction: row-reverse;

        .contenu {
            margin-left: 0;
            padding: 30px 70px 30px 30px;
            text-align: right;
        }
        @media (max-width: $screen-max-sm) {
            flex-direction: column;

            .contenu {
                padding: 30px;
                text-align: center;
            }
        }
    }

    &.left {
        flex-direction: row;

        .contenu {
            margin-right: 0;
            padding: 30px 30px 30px 70px;
            text-align: left;
        }

        @media (max-width: $screen-max-sm) {
            flex-direction: column;

            .contenu {
                padding: 30px;
                text-align: center;
            }
        }
    }

    &.rectangle {
        @media (max-width: $screen-max-md) {
            flex-direction: column;
        }

        .picture {
            width: 50%;
            height: 420px;
            @media (max-width: $screen-max-md) {
                width: 100%;
            }
        }

        .contenu {
            @media (max-width: $screen-max-md) {
                margin: auto;
                justify-content: flex-start;
                text-align: center;
                position: relative;
                align-items: flex-start;
                padding: 30px;
                width: 90%;
                max-width: unset;
                height: auto;
                font-size: 16px;
            }
        }

    }

    &.has-button {
        .contenu {
            flex-direction: column;
            align-items: center;

            .btn-container {
                margin-top: 25px;
            }
        }
    }
}
