.mfp-bg {
    opacity: .4;
}

.mfp-preloader {
    color: $black;
    font-size: 28px;
    font-family: $futura-heavy;

    &:after {
        background: #f7f3eb url('../images/loader-1.gif') no-repeat 50% 30%;
    }
}

#mpquickview-popup {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    max-height: 100vh;
    width: 40%;
    z-index: 14;
    background-color: #fff;
    transition: all .3s ease-in;
    overflow-y: hidden;

    .ph-customtag {
        width: 100%;
        text-align: center;

        @media (max-width: $screen-max-md) {
            text-align: left;
        }

    }

    #maincontent {
        height: 100%;
        margin-top: 0;
        padding-right: 30px !important; // like ph-container
        padding-left: 30px !important; // like ph-container
        overflow: auto;
    }

    // style product from _product.scss
    .main-wrapper-product {
        display: flex;
        flex-direction: column-reverse !important; // surcharger comportement  fiche produit

        .product-info-main {
            width: 100%;
            .page-title-wrapper {
                &.product {
                    .product {
                        &.attribute {
                            &.overview {
                                display: block !important;
                            }
                        }
                    }
                }
            }
            .product-add-form {
                .field {
                    &.qty {
                        display: none;
                    }
                }
                .product-options-wrapper {
                    .configurable {
                        width: 100%;
                    }
                }
                .actions {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    width: 100%;

                    #product-addtocart-button {
                        @media (max-width: $screen-max-md) {
                            position: initial;
                        }
                    }
                }
                .gotocart {
                    display: block;
                    border-radius: 22px !important;
                    padding: 8px 14px;
                }
            }
        }

        .product {
            &.media {
                width: 100%;

                .gallery-placeholder__image {
                    opacity: 1;
                    max-width: 50%; // TODO : voir si possibilité d'ajouter une taille sur l'image / si oui supprimer
                }
            }
        }

        @media (max-width: $screen-max-md) {
            flex-direction: column !important;
        }
    }

    @media (max-width: $screen-max-sm){
        width: 100%;
    }
}

.mpquickview-button {
    color: #000;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all .3s;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-radius: 50%;
    width: 45px;
    margin-top: 10px;

    //.icon {
    //    display: none;
    //}

    .label {
        display: none;
    }

    img {
        display: none;
    }

    @media (max-width: $screen-max-xs) {
        width: 38px;
        height: 38px;
    }

    &:before {
        content: $icon-cart;
        font-family: $icomoon-font-family;
    }
    &:hover {
        background-color: #000;
        color: #fff;
    }
}
