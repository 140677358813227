.address-tree-cols{

  address {
    display: flex;
    display: -webkit-flex;

    @media (max-width: $screen-max-sm) {
      flex-direction: column;
    }

    .col {
      display: block;
      min-height: initial;
      padding: 0 5px;
      width:100%;
      flex-grow: 1;

      &.grow {
        flex-grow: 2;
      }
      &.phone {
        max-width: 170px;
      }
      &.name {
        max-width: 215px;
      }
      @media (max-width: $screen-max-sm) {
        margin-bottom: 10px;
      }

      p {
        line-height: 20px;
        margin: 0;
        font-size: 14px;
      }
    }
  }

}

.address-box-actions {

  .box-actions {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;

    @media (max-width: $screen-max-sm) {
      top: 30px;
    }

    .action {
      position: initial;
      font-size: 16px;
      transition: color .3s;

      &.delete {
        margin-left: 5px;
      }

    }

  }

}
