.product.media {
    .availability {
        position: relative;
        z-index: 1;
        top: 30px;
        left: 98px;
        display: none;
        height: auto;
        background-color: $black;
        color: #FFF;
        font-family: $futura;
        font-size: 13px;
        padding: 6px 12px;

        &.active {
            display: inline-block;
        }

        @media (max-width: $screen-max-sm) {
            font-size: 12px;
            top: 50px;
            left: 15px;
        }
        @media (max-width: $screen-max-xs) {
            left: 10px;
        }

    }
}

.gallery-placeholder__image{
    opacity: 0;
    @include transition(opacity .3s $easeOutQuad);
}

.fotorama{
    &__active{
        .fotorama__dot{
            @media (max-width: $screen-max-sm){
                background-color: $black;
                border-color: $black;
            }
        }
    }
    &__dot{
        @media (max-width: $screen-max-sm){
            width: 12px;
            height: 12px;
        }
    }

    &__stage{
        position: relative;
        display: block;
        max-width: calc(100% - 100px);
        margin-left: 38px;
        // border: 1px solid #e2e2e2;

        .fotorama__arr {
            display: none !important;
        }

        @media (max-width: $screen-max-sm){
            padding: 0;
            max-width: 100%;
            margin: 0 auto;
        }

        &__shaft{
            display: block;
        }

        &__frame{
            .fotorama__img{
                opacity: 1;
                position: relative !important;
                transform: translate3d(0, 0, 0) !important;
                left: initial !important;
                top: initial !important;
                @media (max-width: $screen-max-sm){
                    width: 100%;
                    padding: 30px;
                }
            }
        }

    }

    &__nav-wrap--vertical{

        .fotorama__thumb__arr--left{

            .fotorama__thumb--icon{
                @include transform(rotate(0deg));
            }

        }

    }

    &-item{

    }

    &__wrap{

        .fotorama__nav__frame {
            padding: 0;
            margin-bottom: 20px;
            cursor: pointer;
            .fotorama__thumb{
                width: 100%;
                max-width: 100%;
                background: transparent;
                border: 1px solid rgba(#7B7B7B, .15);
            }

        }
        .fotorama__thumb-border{
            //border-color: $black;
            border-color: transparent;
            background-color: $black;
            opacity: 0.2;
            background-image: none;
        }

    }

}


.fotorama__stage.fotorama__shadows--left, .fotorama__stage.fotorama__shadows--right {
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
}

.catalog-product-view {
    .fotorama__stage__shaft {
        cursor: zoom-in;
    }
}
.catalog-product-view.fotorama__fullscreen {
    .fotorama__stage__shaft {
        cursor: initial;
    }
}
