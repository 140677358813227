.shipping-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 3px 0 0 0;

    @media (max-width: $screen-max-sm) {
        padding: 0 !important;
    }

    .shipping-container-block {
        position: relative;
        color: #909090;
        display: flex;
        align-items: center;
        line-height: 18px;
        padding-right: 7px;
        margin-right: 7px;
        margin-bottom: 6px;
        font-size: 14px;
        font-family: $futura-light;
        cursor: pointer;

        .infobulle {
            position: absolute;
            bottom: 50%;
            left: 0;
            width: 150px;
            border: 1px solid black;
            background-color: #fff;
            color: #000;
            padding: 8px;
            font-size: 13px;
            line-height: 1.2;
            text-align: center;
            opacity: 0;
            transition: all .3s ease;
            pointer-events: none;
            z-index: 2;

            p{
                margin: 0;
            }

            &:after {
                content: '';
                border: 1px solid black;
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 10px;
                height: 10px;
                z-index: 1;
                background: white;
                border-top: none;
                border-left: none;
            }

        }

        &:hover {
            .infobulle {
                opacity: 1;
                display: block;
                bottom: 30px;
            }
        }

        i {
            z-index: 1;
            font-size: 16px;
            width: auto;
            margin-right: 6px;
            opacity: 0.8;
            @media (max-width: $screen-max-sm) {
                width: 20px;
            }
        }

        .legend {
            margin-bottom: 0;
            border-bottom: 0;
            opacity: 0.8;
        }

        &.active {
            color: $black;
            font-family: $futura;

            i {
                color: $black;
                opacity: 1;
            }

            .legend {
                opacity: 1;
            }
        }

        &:after {
            content: '|';
            display: block;
            position: absolute;
            right: -2px;
            top: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
        @media (max-width: $screen-max-xs) {
            &:after {
                display: none;
            }
        }
    }

}
