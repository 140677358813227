.input-error {
  display: none;
  position: relative;
  width: 100%;
  font-family: $futura-heavy;
  font-size: 13px;
  font-weight: 600;
  color: #FFF;
  background-color: $pink;
  padding: 18px 20px 18px 70px;

  @media (max-width: $screen-max-sm) {
    padding: 16px 15px 20px 60px;
  }

  i {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 35px;
    font-size: 12px;
    font-style: normal;
    line-height: 22px;
    color: #FFF;
    text-align: center;
    border: 1px solid #FFF;
    @include border-radius(50%);
    @include transform(translateY(-50%) translateX(-50%));

    @media (max-width: $screen-max-sm) {
      top: 16px;
      @include transform(translateX(-50%));
    }

  }

  p {
    margin: 0;
  }

}

.error > .input-error {
  display: block;
  opacity: 1;
}
