.double-citation-text-image{
  margin-bottom: 35px;


  .double-block{
    &:last-child{
      margin-top: -20px;
      @media (max-width: $screen-max-md) {
        margin-top: 20px;
      }
    }
    display: flex;
    justify-content: space-between;
    @media (max-width: $screen-max-md){
      align-items: center;
     flex-direction: column;
      &:first-child{
        flex-direction: column-reverse;
        padding: 20px;
      }
    }
    .picture{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .citation-block{
      display: flex;
      align-items: center;
      width: 50%;
      @media (max-width: $screen-max-md){
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
    .citation{
      &-bold{
        font-family: $futura-heavy;
        font-size: 22px;
        letter-spacing: 0.9px;
        line-height: 30px;
        @media (max-width: $screen-max-md){
          font-size: 18px;
        }
      }
      font-style: italic;
      &::before{
        content: "''";
        margin: 0 5px;
      }
      &::after{
        content: "''";
        margin: 0 5px;
      }
    }
    .auteur{
      font-size: 12px;
      color: $grey-widget;
      letter-spacing: 0.54px;
    }
    .picture-one{
      width: 360px;
      height: 320px;
      @media (max-width: $screen-max-sm){
        width: 90%;
        height: 235px;
      }
    }
    .picture-two{
      width: 460px;
      height: 430px;
      @media (max-width: $screen-max-md){
        width: 100%;
        height: 310px;
      }
    }

    .contenu{
      background-color: $white;
      width: 65%;
      font-size: 18px;
      padding: 30px 30px 30px 80px;
      letter-spacing: 0.6px;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 140px;
      @include transform(translateX(-70px));

      @media (max-width: $screen-max-md){
        width: 90%;
        text-align: center;
        margin: auto;
        padding: 30px;
        height: auto;
        font-size: 16px;
        @include transform(translateX(0));
        @include transform(translateY(-40px));
      }

      .contenu-wrapper{
        width: 75%;
        p{
          margin: 0;
        }
        @media (max-width: $screen-max-md){
          width: 100%;
        }
      }

      .btn{
        align-self: flex-start;
        margin-top: 20px;
        @media (max-width: $screen-max-md){
         align-self: center;
        }
      }
    }
  }
}