.img-left-block{
  margin-top: 42px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 35px;
  @media (max-width: $screen-max-md) {
    flex-direction: column;
  }

  @media (max-width: $screen-max-sm) {
    margin: 34px auto 0;
  }

  .picture-block{
    width: 60%;
    height: auto;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(684, 342);

    @media (max-width: $screen-max-md) {
      width: 100%;
    }

    @media (max-width: $screen-max-sm) {
      @include aspect-ratio(320, 236);
    }

  }

  .descr-left-block{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid $black;
    min-height: 85%;
    width: 45%;
    position: absolute;
    padding: 0 30px 0 70px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $screen-max-md) {
      position: initial;
      width: 90%;
      padding: 100px 15px 65px;
      margin: 50px auto 0;
      z-index: 10;
    }

    @media (max-width: $screen-max-sm){
      width: calc(100% - 40px);
      margin: -52px 10px 0;
      z-index: 10;
      transform: translateY(0);
    }

    h3{
      text-align: center;
      line-height: inherit;
      font-family: $futura-heavy;
      font-weight: initial;
      font-size: 20px;
      margin: 0 0 15px;
      line-height: 1.3;
    }

  }

  &.right{
    flex-direction: row-reverse;
    @media (max-width: $screen-max-md) {
      flex-direction: column;
    }
    .descr-left-block{
      padding: 0 70px 0 30px;
      left: 0;
      border-color: $black;
      @media (max-width: $screen-max-md) {
        padding: 100px 15px 65px;
      }

    }
  }

}

.cms-index-index{
  .img-left-block{
    .descr-left-block{
      border-color: $black;
    }
  }
}
