.service-entreprise{
  margin-bottom: 35px;
  @media(max-width: $screen-max-md){
    padding: 20px;
  }
  .intro {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.6px;
    line-height: 30px;
    @media(max-width: $screen-max-md){
     font-size: 16px;
    }
  }

  .link-pdf{
    text-align: center;
    a{
      color: $black;
      font-size: 18px;
      text-decoration: underline;
      @media(max-width: $screen-max-md){
        font-size: 16px;
      }
    }
  }

  .service{
    margin-top: 20px;
    font-size: 13px;
    text-align: center;
    .bold-text{
      font-family: $futura-heavy;
    }
  }

  .btn-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
      padding: 18px 100px;
    }
  }
}
