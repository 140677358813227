.simple-widgets{
    &.simple-text{
        margin-bottom: 35px;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0.6px;
        line-height: 30px;
        padding: 20px;
        width: 70%;
        @media (max-width: $screen-max-sm) {
            font-size: 16px;
            width: 100%;
        }
        a{
            text-decoration: underline;
        }
        &.seo-block{
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            @media (min-width: $screen-min-md) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    &.simple-text-image{
        margin-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $screen-max-sm) {
            flex-direction: column;
        }
        .block-picture{
            width: 50%;
            display: flex;
            justify-content: flex-end;
            @media (max-width: $screen-max-sm) {
                width: 100%;
                justify-content: center;
            }
            .picture{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 350px;
                height: 310px;
                padding: 20px;
                @media (max-width: $screen-max-sm) {
                    width: 320px;
                    height: 270px;
                }

            }
        }

        .contenu{
            width: 50%;
            padding: 20px;
            @media (max-width: $screen-max-sm) {
                width: 100%;
            }
            p{
                .citation{
                    font-family: $futura-heavy;
                    font-style: italic;
                    &::before{
                        content: "''";
                        margin: 0 5px;
                    }
                    &::after{
                        content: "''";
                        margin: 0 5px;
                    }
                }
                .auteur{
                    font-size: 12px;
                    color: $grey-widget;
                    letter-spacing: 0.54px;
                }
                font-size: 18px;
                letter-spacing: 0.58px;
                line-height: 30px;
                width: 460px;
                @media (max-width: $screen-max-md) {
                    font-size: 16px;
                    width: auto;
                    text-align: center;
                }
            }
        }
    }

    &.simple-image{
        margin-bottom: 35px;
        .picture{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 360px;
            @media (max-width: $screen-max-md) {
                height: 160px;
            }
        }
    }
}
