.bandeau-banner-title{

   margin-bottom: 45px;

  .banner-image-bg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 360px;
  }
  .title-trait{
    padding: 20px;
    @media (max-width: $screen-max-sm){
      padding-top: 40px;
    }
    &:before{
      @include transform(translateY(-80%));
      @media (max-width: $screen-max-sm){
        @include transform(translateY(-30%));
      }
    }
  }
}