
body .columns .column.main {
  .payment-method .payment-method-content .fieldset .payment-method-icon .payment_method_monetico_desjardins {
    width: 80px;
    height: 25px;
  }

  .payment-method .payment-method-content .fieldset .payment-method-icon .payment_method_monetico_cic {
    width: 40px;
    height: 20px;
    margin-left: 0;
  }

  .payment-method .payment-method-content .fieldset .payment-method-icon .payment_method_monetico_cm {
    width: 125px;
    height: 25px;
  }

  .payment-method .payment-method-title .label .allowed-payment-icon .payment-icon.payment_method_monetico_amex {
    width: 45px;
    height: 28px;
    content: url(../../images/monetico/amex.jpg);
    background: url(../../images/monetico/amex.jpg) no-repeat;
    background-size: cover;
  }
}