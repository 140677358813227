body.contact-index-index, .phestimateform-index-index, .demandededevis-index-index{

  .page-title{
    @extend .text-uppercase;
    padding: 40px 20px 20px;
    margin-top: 15px;
    text-align: center;
    position: relative;
    width: 100%;
    font-weight: initial;
    font-size: 25px;
    box-sizing: border-box;

    @media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
      padding: 40px 20px 0;
      margin-top: 35px;
    }

    @media (max-width: $screen-max-sm){
      padding: 35px 15px 20px;
      margin-top: 35px;
    }

    &.title{
      strong{
        font-size: 28px;
        font-family: $futura;
        font-weight: unset;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 30px;
      background-color: #C72C48;
      left: 50%;
      top: 0;
      transform: translateY(-50%);

      @media (max-width: $screen-max-sm){
        height: 30px;
      }

    }
  }

  .devis{
    min-width: 600px;
    min-width: 600px;
    width: 50%;
    float: none;
    @media (max-width: $screen-max-md) {
      width: 100%;
      padding: 0 20px;
    }
    .actions-toolbar {
      margin-bottom: 30px;
    }
  }

  .column{
    &:not(.sidebar-additional){
      .form{
        &.contact{
          @media (max-width: $screen-max-md) {
            min-width: unset;
            width: 90%;
          }
        }
      }

    }
  }

  .contact-container{
    display: flex;
    @media (max-width: $screen-max-md) {
      flex-direction: column;
    }
    aside{
      width: 30%;
      @media (max-width: $screen-max-md) {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }

      .bloc-contact{
        padding: 20px;
        line-height: 20px;

        &:not(:last-child){
          border-bottom: 1px solid rgba($grey-widget, 0.5);
          @media (max-width: $screen-max-md) {
            border-bottom: none;
          }

        }

        &:first-child{
          padding-top: 0;
        }

        p{
          margin-bottom: 0;
        }
        .text-bold{
          font-family: $futura-heavy;
        }
      }
    }

    iframe{
      width: 100%;
      height: 1400px;

      form{
        width: 90%;
      }

    }

    .form{
      margin: auto;

      .field-inline{
        display: flex;
        display: -webkit-flex;
        margin-bottom: 42px;
        @media (max-width: $screen-max-sm) {
          flex-direction: column;
        }

        &>label{
          width: 15%;
          @media (max-width: $screen-max-sm) {
            width: 20%;
          }
          color: #000;
          text-transform: uppercase;
        }

        .control{
          width: 64%;
        }

      }

      .field-name-prefix{
        label{
          font-size: 13px;
        }
      }
      label{
        font-family: $trade-gothic-light;
      }

      .about, .comment, .message{
        .label{
          color: $black;
        }
      }

      .select2-container{
        .select2-selection--single{
          border: 0;
          min-height: 32px;
          border-bottom: 1px solid $black;
          padding-left: 0;
            border-radius: 0;
          .select2-selection__rendered{
            padding-left: 0;
          }
        }
      }

      .comment, .message{
        .label{
          position: initial;
          margin: 20px 0;
        }
        textarea{
          border: 1px solid $black;
          resize: none;
          &.mage-error{
            border-color: $pink;
          }
          &:focus{
            box-shadow: none;
          }
        }
      }

      .radio {
        margin-left: 15px;
        @media (max-width: $screen-max-sm) {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
        input[type=radio]+label{
          @extend .customer-account-create-label;
          font-family: $trade-gothic-light;
          font-weight: initial;

          &:before {
            @media (max-width: $screen-max-sm) {
              margin-right: 10px;
            }
          }
        }
      }

      .file{
        .control{
          display: flex;
          align-items: center;
          position: relative;
          span{
            width: 100px;
          }
          .input-file{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 1;
          }
          .file-name-wrapper{
            padding: 5px 10px;
            margin-right: 10px;
            box-shadow: none;
            border: 1px solid $black;
            flex: 1;
            display: flex;
            align-items: center;
            p{
              width: 100%;
              margin-bottom: 0;
              &.no-file-selected{
                font-family: $trade-gothic-light;
                font-size: 12px;
                color: #9E9E9E;
              }
            }
            .file-name{
              font-family: $trade-gothic-light;
              font-size: 12px;
              color: #9E9E9E;
              padding: 0;
              box-shadow: none;
            }
          }
        }
        .btn{
            padding: 14px;
            min-height: unset;
        }
      }


      .actions-toolbar{
        margin-bottom: 30px;
        .primary{
          width: 100%;
          .btn{
            width: 100%;
          }
        }
      }
    }

      form {
          @media (max-width: $screen-max-md) {
              width: 100%;
          }
      }
  }

}

.ph-custom-rgpd-disclaimer {
  color: #999;
  font-size: 13px;
  font-style: italic;
  a {
    color: #999;
    font-size: 13px;
    transition: all .3s;
    &:hover {
      color: #5b5b5b;
      transition: all .3s;
    }
  }
}
