.gamme-slider {
    padding-top: 60px;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $screen-max-sm) {
        padding-top: 10px;
    }

    .gamme-btn {
        display: block;
        margin: auto 0 0 0 !important;
        min-width: 260px;
    }

    .slick-dots {
        padding: 40px 0 0 0;
    }

    &-title {
        text-transform: uppercase;
        font-family: $futura-light;
        font-size: 26px;
        text-align: center;
        margin-bottom: 30px;
        @media(max-width: $screen-max-sm) {
            font-size: 22px;
        }
    }

    // Slick container
    &-container {
        width: 100%;
        margin: 0;

        .slick-slide {
            margin: 0;
            max-width: calc(100vw - 70px);
            width: 100%;

            & > div {
                height: 100%;
            }

            @media (min-width: 420px) {
                max-width: 300px;
                width: 300px;
                margin: 0 10px;
            }
        }

        // Slick slides
        .gamme-slide {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            width: 100%;
            height: 100%;

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            // Image
            .image {
                width: 100%;
                aspect-ratio: 1 / 1.08393285372;
                margin-bottom: 25px;
                max-width: 300px;

                @supports not (aspect-ratio: 1 / 1.08393285372) {
                    padding-top: 108.393%;
                    height: 0;
                    position: relative;
                    overflow: hidden;
                }

                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @supports not (aspect-ratio: 1 / 1.08393285372) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }

            // Title
            .gamme-name {
                text-transform: uppercase;
                font-family: $futura-light;
                font-size: 22px;
                margin-bottom: 10px;
            }

            // Subtitle
            .gamme-subtitle {
                font-family: $futura-light;
                font-size: 18px;
                @media(max-width: $screen-max-sm) {
                    font-size: 16px;
                }
                margin-bottom: 20px;
            }

            // Link
            .gamme-link {
                flex: 1;
                display: flex;
                width: 100%;
                justify-content: center;
            }
        }
    }
}
