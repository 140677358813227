.page-header{
    transition: all .5s ease;
    background-color: #fff;

    &.sticky {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 30;

        .header {
            &.content {
                padding: 10px 20px;

                @media (min-width: $screen-min-md) {
                    .logo {
                        transform: scale(0.85);
                    }
                }
            }
        }
    }

    .header {
        &.content {
            .logo {
                transition: all .5s ease;

                @media (min-width: $screen-min-md) {
                    transform: scale(1);
                }
            }
        }
    }

    @media (max-width: $screen-max-md){
        position: fixed;
        z-index: 13;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid $lightgrey;
    }
}

.header{

    &.content {
        font-family: $futura;
        display: flex;
        display: -webkit-flex;
        justify-content: space-around;
        align-items: flex-start;
        max-width: 100%;

        @media (max-width: $screen-max-md){
            position: static;
            padding-left: 50px;
            padding-right: 15px;
        }

        /*MENU FIRST */
        .menu-sup {
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            flex: 1;
            padding-left: 10px;
            padding-top: 10px;

            @media (max-width: $screen-max-md){
                display: none;
                padding-left: 0;
                padding-top: 0;
            }

            a {
                margin: 0 8px;
                color: $black;
                font-family: $futura;
                font-weight: 400;
                font-size: 16px;
                transition: all .3s;
                letter-spacing: .74px;
                white-space: nowrap;

                &:first-letter {
                    text-transform: uppercase;
                }

                @media (min-width: $screen-min-pad) and (max-width: $screen-max-lg){
                    margin: 0 12px;
                }

                @media (min-width: $screen-min-md) and (max-width: $screen-max-pad){
                    margin: 0 10px;
                    font-size: 14px;
                }

                &:hover {
                    text-decoration: none;
                    color: $pink;
                    transition: all .3s;
                }

            }

        }

        .logo {
            flex: 1;
            margin: 0 auto 0;
            text-align: center;
            color: #000;

            i{
                font-size: 75px;
            }

            @media (max-width: $screen-max-md){
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                @include transform(translateY(-50%) translateX(-50%));
                flex: auto;

                i{
                    font-size: 36px;
                }
            }

            img {
                margin: auto;
                width: 264px;
            }

        }

        .header-container-settings {
            flex: 1;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 15px;
            padding-top: 4px;

            @media (max-width: $screen-max-md){
                flex: auto;
                padding-right: 0;
            }

            &>div {
                width: 20px;
                margin: 0 8px;
                vertical-align: middle;
                align-items: center;
                justify-content: center;

                @media (max-width: $screen-max-md){
                    width: 18px;
                    margin: 0 5px;

                    &:nth-child(2) {
                        display: none;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .label {
                    display: none;
                }

                i{
                    font-size: 20px;

                    @media (max-width: $screen-max-md){
                        font-size: 18px;
                        margin: 0;
                    }

                    &.icon-enveloppe {
                        font-size: 16px;
                    }
                }

            }

            .search-header {
                cursor: pointer;
                font-size: 12px;
                margin-top: -1px;
                @include transition(all ease 0.4s);
                display: flex;
                align-items: flex-end;

                &:hover{
                    color: $pink;
                }

                @media (max-width: $screen-max-md){
                    display: none;
                }

                i {
                    font-size: 18px;
                }

                .search-label {
                    margin-left: 5px;
                }
            }
            .store-locator {
                i {
                    font-size: 18px;

                    @include transition(all ease 0.4s);
                    &:hover{
                        color: $pink;
                    }
                }
                @media (max-width: $screen-max-md){
                    display: none;
                }

            }

            .switcher {
                order: unset;
                margin-left: 0;
                font-size: 12px;
                margin-right: 15px;
                width: auto;

                @media (max-width: $screen-max-md){
                    display: none;
                }

                .label {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;


                }

                .options {
                    display: inline-block;
                    position: relative;
                }
                .dropdown {

                    &:hover{
                        color: $pink;
                    }
                    .switcher-trigger{
                        display: flex;
                        align-items: center;
                        position: relative;
                        cursor: pointer;

                        strong {
                            font-weight: 400;

                            span {
                                text-transform: capitalize;
                            }
                        }
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none none;
                        background: #fff;
                        border: 1px solid #bbb;
                        margin-top: 4px;
                        z-index: 100;
                        box-sizing: border-box;
                        display: none;
                        position: absolute;
                        min-width: 100px;
                        left: -39px;
                        top: 20px;
                        box-shadow: none;

                        &:before {
                            left: auto;
                            right: 19px;
                            border: 6px solid;
                            border-color: transparent transparent #fff transparent;
                            z-index: 99;
                            content: "";
                            display: block;
                            height: 0;
                            position: absolute;
                            width: 0;
                            top: -12px;
                        }

                        &:after {
                            left: auto;
                            right: 18px;
                            border: 7px solid;
                            border-color: transparent transparent #bbb transparent;
                            z-index: 98;
                            content: "";
                            display: block;
                            height: 0;
                            position: absolute;
                            width: 0;
                            top: -14px;
                        }

                        .switcher-option {
                            margin: 0;
                            padding: 0;

                            a {
                                color: #333;
                                text-decoration: none;
                                display: block;
                                padding: 8px;
                            }
                        }
                    }

                }
            }

            .switcher .options .action.toggle:after,
            .page-footer .switcher .options .action.toggle:after {
                position: absolute;
                font-size: 8px;
                margin-left: 5px;
                right: -13px;
                top: 0;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 10px;
                line-height: 22px;
                color: inherit;
                content: '\e622';
                font-family: 'luma-icons';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            .account, .contact{
                @include transition(all ease 0.4s);
                cursor: pointer;
                &:hover{
                    color: $pink;
                }

                a {
                    transition: all .3s;
                    &:hover {
                        i {
                            color: $pink;
                            transition: all .3s;
                        }
                    }
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                //@media (max-width: $screen-max-md) {
                //    display: none;
                //}
                .label {
                    @media (min-width: $screen-min-md) and (max-width: $screen-max-lg) {
                        display: none;
                    }
                }
            }

            .open-sidebar-account{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                label {
                    @media (min-width: $screen-min-md) and (max-width: $screen-max-lg) {
                        display: none;
                    }
                }
            }


            .minicart-wrapper{
                a{
                    &::before{
                        @include transition(all ease 0.4s);
                    }
                    &:hover{
                        &::before{
                            color: $black;
                        }
                    }
                }

            }
        }

    }

}


/*MAIN NAV BAR*/
.nav-sections, .navigation{
    background-color: $white;
}
.nav-sections{
    margin-bottom: 0;
    border-top: 1px solid #D5D5D5;
    margin-top: 15px;

    @media (min-width: $screen-min-md){
        &.sticky {
            position: sticky;
            top: 85.5px;
            left: 0;
            width: 100%;
            z-index: 30;
        }
    }

    @media (max-width: $screen-max-md){
        margin-top: 0;
    }
}
.navigation {
    .level0.active > .level-top, .level0.has-active > .level-top {
        border: none;

    }
    ul {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
            span {
                text-transform: uppercase;
                font-family: $futura;
                font-weight: 400;
                color: $black;



            }

            &:hover {
                span{
                    color: $pink;
                    @media (max-width: $screen-max-md) {
                        color: $black;
                    }
                }
            }
            &.level0{

                &.has-active{
                    a{
                        span{
                            color: $pink;
                        }
                    }
                }



                &.active{
                    span{
                        color: $pink;

                    }
                    .level-top{
                        border:none;
                        color: $pink;
                        @media (max-width: $screen-max-md){
                            color: initial;
                        }
                    }
                }
                .level-top{
                    .ui-menu-icon{
                        &.ui-icon{
                            &::after{
                                font-size: 9px;

                                @media (min-width: $screen-max-sm), print {
                                    display: none;
                                }

                                @media (min-width: $screen-min-md), print {
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: inherit;
                                    content: '\e622';
                                    font-family: 'luma-icons';
                                    vertical-align: middle;
                                    display: inline-block;
                                    font-weight: normal;
                                    overflow: hidden;
                                    speak: none;
                                    text-align: center;
                                }

                            }
                        }

                    }
                }
                .submenu {
                    box-shadow: none;
                    width: 100%;
                    border: none;
                    border-top: 1px solid $lightgrey;
                    min-width: 100%;
                    justify-content: center;
                    border-bottom: 1px solid rgba($lightgrey, 0.5);
                    li{
                        a {
                            padding: 15px 20px;
                            transition: all .3s;
                            &.ui-state-focus{
                                background: none;
                            }

                            span {
                                color: $black;
                                transition: all .3s;
                            }
                            &:hover {
                                transition: all .3s;
                                background-color: transparent;

                                span {
                                    color: $pink;
                                    transition: all .3s;
                                }
                            }
                        }
                        &.active{
                            >a{
                                span{
                                    color: $pink;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
