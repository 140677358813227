.block-search{
  max-width: 100%;
  width: 100%;
  margin: 10px auto;
    padding: 0 30px;

  &:not(.block-search-mob){
    display: none;

    .search-autocomplete {
      margin-left: 43px;
    }
    &.open{
      display: block;
    }
    @media (max-width: $screen-max-md) {
      display: none;
    }
  }
  .search-autocomplete{
    margin-top: 0;
    ul:not(:empty) {
      border: 1px solid $black;
      border-top: none;
    }
    ul li:first-child {
      border-top: none;
    }

    li {
      border-top: 1px solid $black;
      padding: 10px 40px 10px 20px;
    }
  }
  .search {
    margin: 0;
    .control {
      input {
        box-shadow: none;
        padding: 0;
        margin-left: 70px;
        border: none;
        width: 100%;
        font-size: 22px;

        &[type=text] {
          border: none;
          position: initial;
          box-shadow: none;
        }

        &::placeholder {
          color: $black;
        }

        color: $black;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .label{
      display: none;
    }
  }
  .actions{

    .search{
      right: unset;
      left: 15px;
      padding: 0 15px;
      border-right: 1px solid $black;
      border-radius: 0;
      &:hover {
        border-right: 1px solid $black;
      }
      &.action{
        &::before{
          font-size:28px;
          color: $black;
        }
      }
    }
  }
   &.sticky{
       position: fixed;
       background: white;
       width: 100%;
       max-width: 100%;
       top: 143px;
       margin: 0;
       padding-bottom: 20px;
       .block-content{
           max-width: 80%;
           margin: auto;
           position: relative;
       }
       .actions{
           .search{
               left: 0;
           }
       }
   }
}
