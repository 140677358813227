.cms-index-index {

    .mgk-adcpopup-modal {
        @include transition(all ease 0.5s);
        right: -100%;

        &._show {
            right: 0;
        }

        @media (max-width: $screen-max-sm) {
            left: 0;
        }

        .product-image-photo {
            max-width: 100% !important;
            height: auto !important;
        }

        .modal-inner-wrap {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            margin: 0;
            height: 100%;
            max-width: 676px;
            box-sizing: border-box;

            @media (max-width: $screen-max-sm) {
                width: 100%;
            }

        }

        .modal-header {
            font-family: $futura-light;
font-weight:200;
            color: $black;
            padding-top: 22px;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(#979797, .26);
            background-color: #FFF;

            @media (max-width: $screen-max-sm) {
                padding: 25px 25px 5px;
            }

        }

        .modal-title {
            font-size: 20px;
            line-height: 1.2;
            text-transform: uppercase;
            border: none;

            @media (max-width: $screen-max-sm) {
                padding-right: 25px;
            }

        }

        .action-close {
            top: 12px;
            right: 15px;

            @media (max-width: $screen-max-sm) {
                right: 5px;
            }

        }

        .modal-content {
            padding: 0;
        }

        .mgk-adcpopup-block {

            .adcpopup-summary {
                display: block;
            }

            .shopping-actions,
            .adcpopup-summary,
            .adcpopup-content {
                width: 100%;
                box-sizing: border-box;
            }

            .adcpopup-summary {
                padding-top: 22px;
                padding-bottom: 32px;
                padding-left: 40px;
                padding-right: 40px;
                background-color: $beige;

                @media (max-width: $screen-max-sm) {
                    display: block;
                    padding: 30px;
                }

            }

            .adcpopup-content {
                padding: 30px 40px;
                border: none;
                background-color: #FFF;

                @media (max-width: $screen-max-sm) {
                    padding: 15px 25px 30px;
                }

                .content-inner {
                    padding: 0;
                    border: none;
                }

            }

            .bottom-modal {
                bottom: 0;
                position: absolute;
                width: 100%;

                @media (max-width: $screen-max-sm) {
                    position: initial;
                }

                .shopping-actions {
                    padding: 20px 30px;
                    background-color: #000;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    z-index: 100;

                    @media (max-width: $screen-max-sm) {
                        display: block;
                        padding: 30px 18px;
                    }

                    .btn {
                        width: 48%;
                        @extend .btn;

                        &.btn-black {
                            border: 1px solid $white;
                        }


                        &:hover {
                            background: $black;
                            color: $white;
                            border: 1px solid $white;
                        }

                        @media (max-width: $screen-max-sm) {
                            display: block;
                            width: 100%;

                            &.secondary {
                                margin-bottom: 12px;
                            }

                        }

                    }

                    .action {
                        &:last-child {
                            display: none;
                        }
                    }

                }
            }


            .shopping-actions {
                padding: 20px 30px;
                background-color: #000;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;

                @media (max-width: $screen-max-sm) {
                    display: block;
                    padding: 30px 18px;
                }

                .btn {
                    width: 48%;
                    @extend .btn;

                    &:hover {
                        background: $black;
                        color: $white;
                        border: 1px solid $white;
                    }

                    @media (max-width: $screen-max-sm) {
                        display: block;
                        width: 100%;

                        &.primary {
                            margin-bottom: 12px;
                        }

                    }

                }

            }

        }

        .content-cart {

            .product-item {
                width: 100%;
                margin: 0;
                padding: 0;
                border: none;

                &-photo {
                    box-sizing: border-box;
                    width: 145px;
                    border: 1px solid rgba(#979797, .22);
                    padding: 0 15px;

                    @media (max-width: $screen-max-sm) {
                        float: left;
                        width: 60px;
                        padding: 0;
                    }

                }

                &-details {
                    width: calc(100% - 145px);
                    padding-left: 34px;
                    padding-top: 15px;
                    box-sizing: border-box;

                    @media (max-width: $screen-max-sm) {
                        float: left;
                        width: calc(100% - 60px);
                        padding-left: 10px;
                        padding-top: 0;
                    }

                }

                &-name {
                    font-family: $futura-heavy;
                    font-size: 20px;
                    line-height: 1;
                    letter-spacing: .95px;
                    margin: 0 0 4px;

                    a {
                        @include transition(all ease 0.4s);
                    }


                    @media (max-width: $screen-max-sm) {
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                }

                &-subtitle {
                    font-family: $futura-light;
font-weight:200;
                    font-size: 14px;
                    letter-spacing: .56px;
                    line-height: 1.2;
                    margin-bottom: 22px;

                    @media (max-width: $screen-max-sm) {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                }

                &-cart {

                    &-wrapper {
                        display: flex;
                        display: -webkit-flex;
                        align-items: flex-end;
                        font-family: $futura-heavy;
                        font-size: 14px;
                        line-height: 1.2;
                        letter-spacing: .49px;

                        @media (max-width: $screen-max-sm) {
                            display: block;
                            font-size: 13px;
                        }

                    }

                    &-left {
                        width: 50%;

                        @media (max-width: $screen-max-sm) {
                            width: 100%;
                        }

                    }

                }

                &-pricing {
                    margin: 0;
                    padding: 0;
                    width: 50%;
                    text-align: right;
                    border: none;

                    @media (max-width: $screen-max-sm) {
                        width: 100%;
                        padding-right: 10px;
                        margin-top: 10px;
                        box-sizing: border-box;
                    }


                    .price-container {
                        float: none;
                    }

                    .price {
                        font-size: 14px;
                        font-family: $futura-heavy;
                        letter-spacing: .28px;
                        line-height: 1;
                    }

                }

                .details-qty {
                    margin-top: 0;
                    font-weight: initial;
                    font-family: $futura;

                    .qty-text {
                        padding: 0;
                        margin-left: 6px;
                        border: none;
                    }

                }

                .options {

                    @media (max-width: $screen-max-sm) {
                        margin-top: 4px;
                    }

                    .list {

                        .option-item {
                            font-weight: initial;

                            .label,
                            .values {
                                display: inline-block;
                                border: none;
                                padding: 0;
                                font-weight: initial;
                                font-family: $futura-light;
font-weight:200;

                                span {
                                    margin: 0 10px;
                                }
                            }

                            .value {
                                margin-left: 6px;
                            }

                        }

                    }

                }

            }

        }

        .shopping-cart {
            margin: 0;

            .summary {

                &-title {
                    padding: 0;
                    margin: 0;
                    color: $black;
                    margin-bottom: 30px;

                    @media (max-width: $screen-max-sm) {
                        margin-bottom: 18px;
                    }

                }

                &-items {
                    display: block;
                }

                &-item {
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: $futura-light;
font-weight:200;
                    padding: 10px 0;

                    @media (max-width: $screen-max-sm) {
                        position: relative;
                        display: block;
                        font-size: 0;
                    }

                    .photo {
                        width: 64px;
                        height: 64px;
                        background-size: cover;
                        background-position: center center;

                        @media (max-width: $screen-max-sm) {
                            width: 78px;
                            height: 78px;
                            margin-bottom: 5px;
                        }

                    }

                    .details {
                        width: 50%;

                        @media (max-width: $screen-max-sm) {
                            display: inline-block;
                            vertical-align: top;
                            width: calc(100% - 55px);
                        }

                    }

                    .label {
                        font-size: 14px;
                        line-height: 1.1;
                        padding: 0;
                        font-weight: 300;
                    }

                    .subtitle {
                        font-size: 12px;
                        color: rgba(0, 0, 0, .42);
                    }

                    .price {
                        font-family: $futura-heavy;
                        font-size: 14px;
                        letter-spacing: .28px;
                        margin-left: 20px;

                        @media (max-width: $screen-max-sm) {
                            position: absolute;
                            z-index: 1;
                            top: 20px;
                            right: 10px;
                        }

                    }

                    .actions {
                        display: flex;
                        display: -webkit-flex;

                        @media (max-width: $screen-max-sm) {
                            display: inline-block;
                            vertical-align: top;
                            width: 55px;
                        }

                    }

                }

            }

        }

    }


    .page-main {
        max-width: unset;
        padding: 0;
        margin-top: 0;

        @media (max-width: $screen-max-sm) {
            padding: 0 20px;
        }

        /*SLIDER DEB PAGE */
        .carousel-container {
            @media (max-width: $screen-max-sm) {
                margin-left: -20px;
                margin-right: -20px;
            }
            @media (max-width: $screen-max-sm) {
                display: none;
            }

            &.mobile-carousel {
                display: none;
                @media (max-width: $screen-max-sm) {
                    display: block;
                }

                #banner-slider-carousel {
                    .owl-image {
                        @media (max-width: $screen-max-sm) {
                            &::before {
                                padding-top: 50%;
                            }
                        }
                        @media (max-width: $screen-min-xs) {
                            &::before {
                                padding-top: 85%;
                            }
                        }
                    }
                }
            }

            #banner-slider-carousel {
                height: auto;
                margin: 0 auto;
                overflow: hidden;
                //max-width: 1440px;


                .owl-carousel,
                .owl-stage-outer,
                .owl-item,
                .banner-item,
                .item-image,
                .owl-image {
                    height: auto;
                }

                .owl-image {
                    display: block;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    @include keep-ratio(1440, 500);
                    @media (max-width: $screen-min-xs) {
                        &::before {
                            padding-top: 50%;
                        }
                    }
                }

                .owl-nav button {
                    top: 50%;
                    transform: translateY(-50%);
                    bottom: unset;

                    i {
                        font-size: 0.6em;
                        padding: 20px;
                        color: #fff;
                    }
                }

                .owl-dots {
                    display: none;
                }

                .owl-dots {
                    left: 50%;
                    @include transform(translateX(-50%));
                }

                .owl-nav {

                    button {

                        &.owl-prev, &.owl-next {
                            background: rgba($black, 0.4);
                            width: 50px;

                        }


                    }

                }

            }

        }

        /*SLIDER DE PRODUIT*/
        .product-slider-hp {
            position: relative;

            .mp-product-slider-block {
                width: 100%;

                .title-trait {
                    font-size: 25px;
                    padding: 40px 0 0;
                    margin: 50px 0 0;

                    @media (max-width: $screen-max-sm) {
                        padding-top: 32px;
                    }

                    &:before {
                        position: relative;
                        display: block;
                        top: auto;

                        @include transform(none);
                        margin: 0 auto 14px 0;

                        @media (max-width: $screen-max-md) {
                            margin: 0 auto 14px;
                            left: 0;
                        }

                        @media (max-width: $screen-max-sm) {
                            height: 44px;
                        }

                    }

                }

            }

            .block-content {
                width: 100%;

                .owl-stage {
                    display: flex;
                }

                .owl-item {
                    display: grid;
                    @media (max-width: $screen-max-md) {
                        display: block;
                    }

                    .product-item {
                        border: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        height: 100%;
                        @media (max-width: $screen-max-md) {
                            padding: 0;
                        }

                        .product-item-info {
                            align-items: center;
                            justify-content: space-between;
                            margin: auto;
                            border: none;
                            width: 100% !important;
                            flex-direction: column;
                            padding: 25px 15px;

                            &:hover {
                                border: none;
                                background: transparent;
                            }

                            .slider-product-item-details {
                                position: relative;
                                width: 100%;
                                padding-top: 0;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                @media (max-width: $screen-max-sm) {
                                    padding-left: 0;
                                    padding-right: 0;

                                    & > p {
                                        margin-bottom: 12px;
                                    }

                                }

                            }

                            .slider-product-item-details {
                                .product-item-name {
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    @media (max-width: $screen-max-md) {
                                        min-height: 43px;
                                    }
                                    @media (max-width: $screen-max-sm) {
                                        margin-bottom: 2px;
                                        min-height: auto;
                                    }

                                }

                                .description-product, > p {
                                    text-transform: uppercase;
                                    // min-height: 34px;
                                    text-align: center;
                                    @media (max-width: $screen-max-sm) {
                                        min-height: auto;
                                    }
                                }
                            }


                            .slider-product-item-photo {
                                width: 65%;

                                @media (max-width: $screen-max-sm) {
                                    width: 100%;
                                }

                            }

                            .price-box {
                                border: none;
                                margin-bottom: 0;
                                min-height: 47px;

                                @media (max-width: $screen-max-sm) {
                                    font-size: 15px;
                                    min-height: auto;
                                }

                            }

                        }

                    }

                }

                .owl-nav {

                    button {
                        box-shadow: none;
                        position: absolute;
                        top: 50%;
                        background: transparent;
                        border: none;
                        @include transition(all ease 0.4s);


                        i {
                            font-size: 25px;
                        }

                        &.owl-prev {
                            left: 0;

                            @media (max-width: $screen-max-sm) {
                                left: -10px;
                                margin: 0;
                            }

                        }

                        &.owl-next {
                            right: 0;

                            @media (max-width: $screen-max-sm) {
                                right: -10px;
                                margin: 0;
                            }

                        }

                    }

                }

                .owl-dots {
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: 50%;
                    @include transform(translateX(-50%));

                    @media (max-width: $screen-max-md) {
                        margin-top: 0;
                    }


                    button {

                        &.owl-dot {

                            span {
                                display: none;
                            }

                        }

                    }

                }

            }

            .product-image-container {
                display: block;
                margin: 0 auto;
            }

            .mp-product-slider-block,
            .block-content,
            .products-grid,
            .product-items,
            .owl-carousel {
                position: static;
            }

            .owl-carousel {
                padding-bottom: 0;

                @media (max-width: $screen-max-md) {
                    position: relative;
                }

                @media (max-width: $screen-max-sm) {
                    padding-bottom: 20px;
                }

            }

        }

        /*INSTAFEED*/
        .mpinstagramfeed-container {
            margin-top: 70px;
            overflow: hidden;

            @media (max-width: $screen-max-md) {
                max-width: 100%;
            }

            @media (max-width: $screen-max-sm) {
                margin-top: 47px;
            }

            .title-trait {

                @media (max-width: $screen-max-sm) {
                    padding-bottom: 35px;
                }

            }

            .row {
                margin-top: 15px;
                display: flex;
                display: -webkit-flex;
                justify-content: space-around;

                @media (max-width: $screen-max-md) {
                    flex-direction: column;
                }

                @media (max-width: $screen-max-sm) {
                    margin: 0 -15px;
                }

                .owl-item {
                    display: inline-block;
                }

                .mpinstagramfeed-photo {
                    width: calc(260px - 4px);
                    max-width: 100%;

                    @media (max-width: $screen-max-md) {
                        width: 100%;
                        height: auto;
                    }

                    img {
                        max-height: 215px;
                    }

                    video {
                        min-height: 215px;
                        max-height: 215px;
                        display: block;
                        width: 100%;
                        max-width: none;
                        height: 100%;
                        object-fit: cover;
                        padding: 0 1px 1px 0;
                    }

                    a {
                        position: relative;
                        display: block;

                        &:hover {

                            .opacity {
                                opacity: 1;
                            }

                        }

                        .opacity {
                            opacity: 0;
                            @include transition(all ease 0.4s);
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            z-index: 10;
                            background: rgba($black, 0.7);
                            top: 0;
                            display: flex;
                            display: -webkit-flex;
                            justify-content: center;
                            align-items: center;
                            left: 0;
                            color: $white;
                            font-size: 20px;
                        }

                    }

                    &:nth-child(2), &:nth-child(4) {
                        height: 300px;

                        a {

                            .opacity {
                                height: 300px;
                            }

                        }

                    }

                }

            }

        }

    }

}

#banner-slider-carousel {
    margin-top: 5px;
    margin-bottom: 5px;

    .owl-dots {
        position: absolute;
        bottom: 0;
        left: 50%;
        opacity: 0;
    }

    .owl-nav {
        opacity: 0;
        margin: 0;

        button {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            height: auto;
            width: 5%;
            text-align: center;
            font-size: 3.9em;
            color: #FFF;
            background: transparent;
            opacity: .5;
            text-transform: capitalize;
            justify-content: center;
            margin: 0;
        }

        .owl-prev {
            left: 1px;
        }

        .owl-next {
            right: 1px;
        }
    }

    &:hover {
        .owl-nav {
            opacity: 1;
        }

        .owl-dots {
            opacity: 1;
        }
    }

    .owl-item {
        img {
            transform-style: unset;
        }
    }

    .item-video {
        height: 340px;
    }

    .banner-title {
        position: absolute;
        bottom: 0;
        padding: 1em;
        background: rgba(0, 0, 0, 0.5);
        color: #F0F0F0;
        border-radius: 4px 4px 0 0;
        opacity: .5;
    }
}

.banner-item {
    &:hover {
        .banner-title {
            opacity: 1 !important;
        }
    }
}
