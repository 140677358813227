body.account.sales-order-print,
body.account.sales-order-printinvoice {

  .page-title{

  }

  .page-main {
    @media (max-width: $screen-max-md) {
      padding: 0 20px;
    }
    .logo {
      display: block;
      margin: 0;
      text-align: center;
      max-width: 100%;
      font-size: 75px;

      @media (max-width: $screen-max-md){
        font-size: 40px;
      }

    }

    .page-title-wrapper{
      padding: 0 0 25px;

      @media (max-width: $screen-max-md){
        display: block;
      }

      .order-status {
        display: none;
      }

      .order-infos {
        display: block;
        width: 100%;
        font-size: 0;
        margin-top: 50px;
        margin-bottom: 50px;

        @media (max-width: $screen-max-md){
          margin-bottom: 42px;
        }

        date {
          text-transform: uppercase;
        }

      }

      .col{
        display: inline-block;
        vertical-align: top;
        font-family: $trade-gothic-light;
        font-size: 0;
        width: 50%;
        list-style: none;
        padding: 0;
        margin: 0;

        @media (max-width: $screen-max-md){
          width: 100%;
        }

        li{
          margin-bottom: 30px;

          @media (max-width: $screen-max-md){
            margin-bottom: 12px;
          }

          &:last-child{
            margin-bottom: 0;
          }

        }

        span{
          display: inline-block;
          vertical-align: top;
          width: 50%;
          font-size: 14px;

          @media (max-width: $screen-max-md){
            width: 100%;
          }

          &:first-child {
            font-family: $futura-heavy;
            font-size: 16px;
            text-transform: uppercase;
          }

        }


        /*margin: 40px 0 15px;
        width: 50%;


        li{
            list-style: none;
        }
        @media (max-width: $screen-max-sm) {
            width: 100%;
        }
        &-block {
            margin-bottom: 15px;
            .label {
                display: inline-block;
                width: 35%;
                text-transform: uppercase;
                font-family: $futura-heavy;
                margin-right: 15px;

                @media (max-width: $screen-max-md) {
                    width: 50%;
                }

                @media (max-width: $screen-max-sm) {
                    width: 35%;
                }
                @media (max-width: $screen-max-xs) {
                    width: 60%;
                }
            }
        }*/
      }
    }
  }
  .column.main {

    .order-details-items{

      &.ordered{
        border: none;
        padding: 0;
        margin: 0;
      }

      .order-items{
        @media (max-width: $screen-max-md){
          overflow: visible;
          border: none;
          margin-top: 50px;
        }

      }

      .td{
        font-size: 16px;

        @media (max-width: $screen-max-md){

          &.product-name{

            .product-item-name{
              font-family: $futura-light;
font-weight:200;
            }

          }

        }

      }

      .col{
        font-size: 14px;

        &.price, &.subtotal, &.name{
          .price-including-tax{
            color: $black;
            font-size: 14px;
          }
          .product{
            font-size: 14px;
          }

        }
      }

      .thead{

        @media (max-width: $screen-max-md){
          display: none;
        }

        .col{
          min-height: 40px;
        }

      }

      .tfoot{
        margin-top: 20px;
        padding: 18px 10px;
        background-color: rgba(#F4F4F4, .45);

        @media (max-width: $screen-max-md){
          margin-top: 30px;
          margin-left: -25px;
          margin-right: -25px;
          width: auto;
          padding: 28px 35px;
        }

        .tr{
          border: none;
          margin-bottom: 10px;
          padding: 0;

          @media (max-width: $screen-max-md){
            font-size: 0;
          }

          &.totals-tax-summary, &.shipping {
            margin-bottom: 30px;
          }

          .mark, .label{
            width: 82%;
            font-size: 14px;
            letter-spacing: .28px;
            text-align: right;

            @media (max-width: $screen-max-md){
              width: 50%;
              text-align: left;
              padding: 0;
            }

          }

          .amount,
          .subtotal{
            width: 18%;
            font-size: 16px;
            letter-spacing: .32px;
            text-align: right;
            padding-right: 0;

            @media (max-width: $screen-max-md){
              width: 50%;
            }

          }

          .td, .th{

            &.mark,
            &.label{
              width: 82%;
              font-size: 14px;
              letter-spacing: .28px;
              text-align: right;

              @media (max-width: $screen-max-md){
                width: 50%;
                text-align: left;
                padding: 0;
              }

            }

            &.amount,
            &.subtotal{
              width: 18%;
              font-size: 16px;
              letter-spacing: .32px;
              text-align: right;
              padding-right: 0;

              @media (max-width: $screen-max-md){
                width: 50%;
              }

            }

          }

        }

      }

      .twrap{
        padding: 20px;
        border: 1px solid #999999;
        @include border-radius(2px);

        @media (max-width: $screen-max-md){
          padding: 0;
          border: none;
        }

      }

      .tr{
        padding: 8px 0;
        border: none;
        background-color: transparent;
        margin-bottom: 0;

        .detailed, .mark{
          margin-right: 10px;
        }

        &.subtotal{
          margin-bottom: 10px;
        }

        &.grand_total_incl{
          margin-top: 10px;
        }

        .th{
          font-size: 14px;

          &.label{
            margin-right: 10px;
          }

        }

        @media (max-width: $screen-max-md){
          display: block;
        }

      }

      .tbody{

        .tr{
          border: none;
          background-color: transparent;

          @media (max-width: $screen-max-md){
            display: block;
          }

        }

        .col{
          padding: 0;
          min-height: 70px;

          @media (max-width: $screen-max-md){
            min-height: 0;
            margin-bottom: 10px;

            &:before{
              content: attr(data-th);
              display: inline-block;
              font-family: $futura-heavy;
              font-size: 13px;
              text-transform: uppercase;
              padding-right: 5px;
            }

          }

          &.subtotal{
            padding-right: 10px;

            @media (max-width: $screen-max-md){
              padding-right: 0;
            }

          }

        }

      }

      .product-name{
        flex-direction: column;
        justify-content: center;

        @media (max-width: $screen-max-md){

          &:before{
            width: 100%;
          }

        }

        .product-item-name{
          font-family: $futura-heavy;
          font-size: 16px;
          width: 100%;
          margin: 0;
        }

        .item-options{
          display: block;
          width: 100%;
          font-size: 12px;

          dt {
            display: inline-block;
          }
          dd {
            display: inline-block;
          }
        }

      }

    }

    .block.block-order-details-view{
      margin-bottom: 55px;

      @media (max-width: $screen-max-md){
        margin-bottom: 35px;
      }

      &:not(.widget){

        .block-content{

          .box{

            @media (max-width: $screen-max-md){
              margin-bottom: 10px;
            }

          }

        }

      }

      &.block-addresses-default{
        margin-bottom: 25px;

        @media (max-width: $screen-max-md){
          margin-bottom: 48px;
        }

      }

      .block-title{
        position: relative;
        font-family: $futura-heavy;
        font-size: 20px;
        padding: 0;
        margin: 0 0 30px;
        border: none;
        text-transform: uppercase;

        @media (max-width: $screen-max-md){
          text-align: center;
          margin-bottom: 16px;
        }

      }

      .block-content{

        &.nob{
          border: none;

          .box{
            border: 1px solid #979797;
          }

        }

        .box{
          border: 1px solid #979797;

          @media (max-width: $screen-max-md){
            border: none;
          }

          position: relative;
          width: 50%;
          padding-bottom:  18px;

          @media (max-width: $screen-max-md){
            width: 100%;
            min-height: 180px;
            border: 1px solid #979797;
          }

          &-newsletter {

            @media (min-width: $screen-min-md) {

              .box-title {
                border-left: 2px solid #FFF;
              }

            }

          }

          //
          //&:nth-child(2n){
          //
          //  .box-title{
          //    border-left: 2px solid #FFF;
          //  }
          //
          //}

          .box-title{
            display: block;
            font-family: $futura-light;
font-weight:200;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 0;
            background-color: #F6F6F6;
            padding: 15px 40px 10px 60px;

            @media (max-width: $screen-max-md){
              padding: 17px 13px 10px;
            }

          }

          .box-content{
            font-family: $trade-gothic-light;
            font-size: 14px;
            line-height: 1.6;
            padding: 25px 60px 8px;

            @media (max-width: $screen-max-md){
              padding: 25px 13px 6px;
              column-count: unset;

            }

          }

          &-actions{
            margin-top: 0;
            padding: 0 60px;

            @media (max-width: $screen-max-md){
              padding: 0 13px;
            }

          }

          &-address-shipping,
          &-shipping-address,
          &-address-billing,
          &-billing-address{
            width: 100%;
            margin-bottom: 20px;

            @media (max-width: $screen-max-md){
              padding-bottom: 0;
            }

            .box-title{
              padding-left: 90px;

              @media (max-width: $screen-max-md){
                padding-left: 55px;
                padding-right: 30px;
              }

            }

            .box-content{
              font-family: $trade-gothic-light;
              font-size: 14px;
              line-height: 1.6;
              padding: 16px 40px;

              @media (max-width: $screen-max-sm) {
                padding: 15px 5px;
              }

              p {
                padding: 0 10px;
              }

              address {
                display: flex;

                @media (max-width: $screen-max-sm) {
                  flex-direction: column;
                }

                .col {
                  display: block;
                  min-height: initial;
                  padding: 0 5px;
                  width:100%;
                  flex-grow: 1;

                  &.grow {
                    flex-grow: 2;
                  }

                  @media (max-width: $screen-max-sm) {
                    margin-bottom: 10px;
                  }

                  p {
                    line-height: 20px;
                    margin: 0;
                    font-size: 14px;
                  }

                }

              }

            }

            .address-figure{
              position: absolute;
              z-index: 1;
              top: 12px;
              left: 50px;
              font-size: 20px;

              @media (max-width: $screen-max-md){
                top: 15px;
                left: 15px;
              }

            }

          }

          .action{

            &.edit{
              position: absolute;
              z-index: 1;
              top: 15px;
              right: 30px;
              width: 20px;
              height: 20px;
              text-align: center;
              color: #000;
              font-size: 18px;

              i{
                @include transition(color .3s $easeOutQuad);
              }

              &:after{
                content: none;
              }

              @media (max-width: $screen-max-md){
                right: 11px;
              }

            }

            &.change-password{
              font-family: $futura-heavy;
              font-size: 12px;
              color: #000;
              transition: .3s;

            }

          }

        }

      }

    }
  }
}


body.account.sales-order-printinvoice{

  .column{

    &.main{

      .order-details-items{
        display: flex;
        flex-direction: column;
        border: none;
        padding: 0;

        .order-title{
          order: 2;
        }

        .table-order-items{
          order: 3;
          margin-top: 20px;

          .table{
            border: 1px solid $lightgrey;
          }

        }

        .block{

          &.block-order-details-view{
            order: 1;

            .block-content{

              .box{
                width: 100%;

                &.box-order-shipping-method, &.box-order-billing-method{
                  display: none;
                }

              }

            }

          }

        }

      }

    }

  }

}

