.modal-geolocation{
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.active{
    display: block;
    opacity: 1;
  }

  &-layout{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .21;
  }

  &-outer{
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 960px;
    @include transform(translateY(-50%) translateX(-50%));
    background-color: #FFF;
    padding-top: 50px;
    padding-bottom: 50px;



    @media (max-width: $screen-min-md){
      width: 95%;
    }

    @media (max-width: $screen-max-sm){
      height: 100%;
      padding-top: 55px;
      width: 100%;
    }

  }

  &-inner{
    display: block;
    width: 100%;
    max-width: 665px;
    margin: 0 auto;

    @media (max-width: $screen-max-sm){
      padding-left: 15px;
      padding-right: 15px;
    }

  }

  .close{
    position: absolute;
    z-index: 1;
    top: 33px;
    right: 40px;
    width: 20px;
    height: 20px;
    line-height: 1;
    cursor: pointer;
    @include transition(all ease 1s);
    transform-origin: 50% 50%;

    &:hover{
      transform: rotate(360deg);
    }

    @media (max-width: $screen-max-sm){
      top: 35px;
      right: 35px;
    }

    i{
      font-size: 20px;
      color: #000;

      @media (max-width: $screen-max-sm){
        font-size: 18px;
      }

    }

  }

  .modal-title,
  .modal-subtitle{
    font-family: $futura-light;
font-weight:200;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
  }

  .modal-title{
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media (max-width: $screen-max-sm){
      font-size: 25px;
      margin: 30px 0;
    }

  }

  .modal-subtitle{
    font-size: 16px;
    margin-bottom: 35px;

    @media (max-width: $screen-max-sm){
      line-height: 1.4;
      margin-bottom: 18px;
    }

  }

  .modal-ctas{
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    margin-top: 25px;

    @media (max-width: $screen-max-sm){
      display: block;
    }

    .white-button {
      flex: 1 1 auto;
      height: auto;
      text-transform: uppercase;

      @media (max-width: $screen-max-sm){
        display: block;
        width: 100%;
      }

      @media (max-width: $screen-max-xs){
        padding: 15px 10px;
        font-size: 11px;
      }

      &:first-child{
        margin-right: 10px;

        @media (max-width: $screen-max-sm){
          margin: 0 0 6px;
        }
      }

    }

  }

  .input-group{
    position: relative;

    &.active{

      .input-form{

        i{
          opacity: 1;
          pointer-events: auto;
        }

      }

    }

  }

  .form-control{
    padding: 0 30px;
    height: 55px;
    border: 1px solid #c2c2c2;
    @include transition(border-color .5s $easeOutQuad);

    &:focus{
      border-color: #000;
      box-shadow: none;
    }


    &::placeholder{
      color: $lightgrey-darker;
    }


  }

  .input-form{
    position: relative;


    i{
      position: absolute;
      pointer-events: none;
      opacity: 0;
      z-index: 1;
      right: 10px;
      top: 50%;
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-color: rgba(#000, .24);
      @include transform(translateY(-50%));
      @include border-radius(50%);
      @include transition(opacity .5 $easeOutQuad);

      @media (max-width: $screen-max-sm){
        right: 4px;
      }

      &:before{
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        color: #FFF;
        font-size: 12px;
        @include transform(translateY(-50%) translateX(-50%));
      }

    }

  }
}

.pac-results {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  padding: 10px 18px;
  background: white;
  border: 1px solid #979797;
  list-style: none;
  display: none;
  opacity: 0;
  font-size: 16px;

  @media (max-width: $screen-max-sm){
    padding: 10px 12px;
  }

  &.active{
    display: block;
    opacity: 1;
  }

  li{
    position: relative;
    padding: 10px;
    color: rgba(0,0,0,.57);
    margin: 0;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: $screen-max-sm){
      padding: 5px;
      font-size: 13px;
    }

    &:after{
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #979797;
      opacity: .25;
    }

    &:last-child{

      &::after{
        content: none;
      }

    }

    i{
      display: inline-block;
      margin-right: 8px;
    }

  }

}
