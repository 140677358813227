.autopromo-wrapper {
    width: 100%;
    height: auto;
    text-align: center;
    background-color: $black;
    overflow: hidden;
    z-index: 10;
    @media (max-width: $screen-max-md) {
        border-bottom: 1px solid $white;
    }

    .bandeau-autopromo {
        position: relative;
        font-family: $futura-light;
        font-weight:200;
        color: #FFF;
        padding: 0 30px;

        &.swiping {
            .bandeau-autopromo__messages {
                transform: translateY(-100%);
                transition: transform .2s ease-in-out;
                @media (max-width: $screen-max-xs) {
                    transform: translateY(-100%);

                }
            }

            .bandeau-autopromo__message {
                transition: opacity .2s ease-in-out;
            }

            .first-message {
                opacity: 0;
            }

            .second-message {
                opacity: 1;
            }
        }

        .second-message {
            opacity: 0;
        }

        &__messages {
            transition-duration: 0s;
            max-height: 40px;
            &.multiple {
                max-height: 60px;
                .bandeau-autopromo__message {
                    max-height: 60px;
                    height: 60px;
                }
            }

            .bandeau-autopromo__message {
                max-height: 40px;
                height: 40px;
            }
        }

        &__message {
            transition-duration: 0s;
            display: flex;
            padding: 10px 0;
            align-items: center;
            justify-content: center;

            @media (max-width: $screen-max-xs) {
                font-size: 12px;
            }

            p {
                margin: 0;
            }

            a {
                color: $white;
                text-decoration: underline;
                display: inline-block;
            }
        }
    }

    .close {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 30px;
        @include transform(translateY(-50%));

        @media (max-width: $screen-max-md) {
            right: 10px;
        }

        i {
            color: #FFF;
            font-size: 15px;
        }

    }

}
