$black            : #000;
$white            : #fff;
$pink             : #C72C48;
$dark-pink        : #9D0D4C;
$lightgrey        : #e2e2e2;
$lightgrey-2      : #D9D9D9;
$lightgrey-darker : #a7a7a7;
$beige            : #F7F6F5;
$grey             : #676767;
$green            : #60A285;
$valid-color      : #7ED321;
$grey-widget      : #919191;
$green-froggy     : #6aa13a;
$blue             : #003662;
