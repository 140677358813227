//@include font-face(FuturaBook, '#{$path-fonts}futura_bt/FUTURAN', null, null, woff2 woff eot ttf svg);
//@include font-face(FuturaHeavy, '#{$path-fonts}futura_h/futurah', null, null, woff2 woff eot ttf svg);
//@include font-face(FuturaLight, '#{$path-fonts}futura_light/futura-light', null, null, woff2 woff eot ttf svg);
//@include font-face(MuliLight, '#{$path-fonts}muli/Light/Muli-Light', null, null, woff2 woff eot ttf svg);
//@include font-face(TradeGothicLight, '#{$path-fonts}trade_gothic/trade_gothic_ligth', normal, normal, woff2 woff eot ttf svg);

@include font-face(FuturaBook, '#{$path-fonts}jost_regular/Jost-Regular', null, null, woff2 woff eot ttf svg);
@include font-face(FuturaHeavy, '#{$path-fonts}jost_medium/Jost-Medium', null, null, woff2 woff eot ttf svg);
@include font-face(FuturaLight, '#{$path-fonts}jost_light/Jost-Light', null, null, woff2 woff eot ttf svg);
@include font-face(TradeGothicLight, '#{$path-fonts}jost_light/Jost-Light', normal, normal, woff2 woff eot ttf svg);

$futura: 'FuturaBook', 'Helvetica', 'sans-serif';
$futura-heavy : 'FuturaHeavy', 'Helvetica', 'sans-serif';
$futura-light : 'FuturaLight', 'Helvetica', 'sans-serif';
$muli-light : 'MuliLight', 'Helvetica', 'sans-serif';
$trade-gothic-light : 'TradeGothicLight', 'Helvetica', 'sans-serif';

$icomoon-font-family: "icomoon" !default;

$icon-contact: unquote('"\\e941"');
$icon-logo-full: unquote('"\\e940"');
$icon-question-path1: unquote('"\\e93d"');
$icon-question-path2: unquote('"\\e93e"');
$icon-question-path3: unquote('"\\e93f"');
$icon-reset: unquote('"\\e938"');
$icon-plus: unquote('"\\e904"');
$icon-minus: unquote('"\\e937"');
$icon-twitter: unquote('"\\e902"');
$icon-linkedin: unquote('"\\e939"');
$icon-youtube: unquote('"\\e93a"');
$icon-pinterest: unquote('"\\e93b"');
$icon-facebook: unquote('"\\e93c"');
$icon-filters: unquote('"\\e936"');
$icon-service-enterprise: unquote('"\\e935"');
$icon-cc-2h: unquote('"\\e934"');
$icon-pin-filaire: unquote('"\\e930"');
$icon-contact-fond-noir: unquote('"\\e932"');
$icon-email-lettre: unquote('"\\e931"');
$icon-gift-box-with-a-bow: unquote('"\\e92f"');
$icon-logo-empty: unquote('"\\e92d"');
$icon-bus: unquote('"\\e92b"');
$icon-carton: unquote('"\\e92c"');
$icon-cart: unquote('"\\e91c"');
$icon-eye-full: unquote('"\\e923"');
$icon-edit: unquote('"\\e924"');
$icon-invoice: unquote('"\\e928"');
$icon-eye: unquote('"\\e929"');
$icon-arrow-r: unquote('"\\e92a"');
$icon-trash: unquote('"\\e922"');
$icon-pins: unquote('"\\e920"');
$icon-logo: unquote('"\\e921"');
$icon-pin: unquote('"\\e91f"');
$icon-calendar: unquote('"\\e91e"');
$icon-epv: unquote('"\\e91a"');
$icon-cadenas: unquote('"\\e91b"');
$icon-flat-cart: unquote('"\\e91d"');
$icon-cross: unquote('"\\e919"');
$icon-coursier: unquote('"\\e918"');
$icon-home-shipping: unquote('"\\e925"');
$icon-heart: unquote('"\\e926"');
$icon-shop: unquote('"\\e927"');
$icon-arrow-left: unquote('"\\e916"');
$icon-arrow-right: unquote('"\\e917"');
$icon-anhydride: unquote('"\\e907"');
$icon-check: unquote('"\\e910"');
$icon-mollusque: unquote('"\\e915"');
$icon-arachide: unquote('"\\e908"');
$icon-celeri: unquote('"\\e909"');
$icon-crustaces: unquote('"\\e90a"');
$icon-fruits-a-coques: unquote('"\\e90b"');
$icon-gluten: unquote('"\\e90c"');
$icon-graines-de-sesame: unquote('"\\e90d"');
$icon-lait: unquote('"\\e90e"');
$icon-lupin: unquote('"\\e90f"');
$icon-moutarde: unquote('"\\e911"');
$icon-oeufs: unquote('"\\e912"');
$icon-poissons: unquote('"\\e913"');
$icon-soja: unquote('"\\e914"');
$icon-user: unquote('"\\e906"');
$icon-search: unquote('"\\e905"');
$icon-instagram: unquote('"\\e903"');
$icon-phone: unquote('"\\e901"');
$icon-arrow-down: unquote('"\\e900"');
$icon-directions: unquote('"\\e92e"');
$icon-fleche-ph: unquote('"\\e933"');


@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$path-fonts}icomoon/#{$icomoon-font-family}.eot?khgu6m');
    src:  url('#{$path-fonts}icomoon/#{$icomoon-font-family}.eot?khgu6m#iefix') format('embedded-opentype'),
    url('#{$path-fonts}icomoon/#{$icomoon-font-family}.ttf?khgu6m') format('truetype'),
    url('#{$path-fonts}icomoon/#{$icomoon-font-family}.woff?khgu6m') format('woff'),
    url('#{$path-fonts}icomoon/#{$icomoon-font-family}.svg?khgu6m##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-contact {
    &:before {
        content: $icon-contact;
    }
}
.icon-logo-full {
    &:before {
        content: $icon-logo-full;
    }
}
.icon-question .path1 {
    &:before {
        content: $icon-question-path1;
        color: rgb(0, 0, 0);
    }
}
.icon-question .path2 {
    &:before {
        content: $icon-question-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.icon-question .path3 {
    &:before {
        content: $icon-question-path3;
        margin-left: -1em;
        color: rgb(1, 1, 1);
    }
}
.icon-reset {
    &:before {
        content: $icon-reset;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
        color: #fff;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}
.icon-pinterest {
    &:before {
        content: $icon-pinterest;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-filters {
    &:before {
        content: $icon-filters;
    }
}
.icon-service-enterprise {
    &:before {
        content: $icon-service-enterprise;
    }
}
.icon-cc-2h {
    &:before {
        content: $icon-cc-2h;
    }
}
.icon-pin-filaire {
    &:before {
        content: $icon-pin-filaire;
    }
}
.icon-contact-fond-noir {
    &:before {
        content: $icon-contact-fond-noir;
    }
}
.icon-email-lettre {
    &:before {
        content: $icon-email-lettre;
    }
}
.icon-gift-box-with-a-bow {
    &:before {
        content: $icon-gift-box-with-a-bow;
    }
}
.icon-logo-empty {
    &:before {
        content: $icon-logo-empty;
    }
}
.icon-bus {
    &:before {
        content: $icon-bus;
    }
}
.icon-carton {
    &:before {
        content: $icon-carton;
    }
}
.icon-cart {
    &:before {
        content: $icon-cart;
    }
}
.icon-eye-full {
    &:before {
        content: $icon-eye-full;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-invoice {
    &:before {
        content: $icon-invoice;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-arrow-r {
    &:before {
        content: $icon-arrow-r;
    }
}
.icon-trash {
    &:before {
        content: $icon-trash;
    }
}
.icon-pins {
    &:before {
        content: $icon-pins;
        color: #979797;
    }
}
.icon-logo {
    &:before {
        content: $icon-logo;
    }
}
.icon-pin {
    &:before {
        content: $icon-pin;
        color: #c72c48;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-epv {
    &:before {
        content: $icon-epv;
        color: #fff;
    }
}
.icon-cadenas {
    &:before {
        content: $icon-cadenas;
        color: #fff;
    }
}
.icon-flat-cart {
    &:before {
        content: $icon-flat-cart;
        color: #fff;
    }
}
.icon-cross {
    &:before {
        content: $icon-cross;
    }
}
.icon-coursier {
    &:before {
        content: $icon-coursier;
    }
}
.icon-home-shipping {
    &:before {
        content: $icon-home-shipping;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-shop {
    &:before {
        content: $icon-shop;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-anhydride {
    &:before {
        content: $icon-anhydride;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-mollusque {
    &:before {
        content: $icon-mollusque;
    }
}
.icon-arachide {
    &:before {
        content: $icon-arachide;
    }
}
.icon-celeri {
    &:before {
        content: $icon-celeri;
    }
}
.icon-crustaces {
    &:before {
        content: $icon-crustaces;
    }
}
.icon-fruits-a-coques {
    &:before {
        content: $icon-fruits-a-coques;
    }
}
.icon-gluten {
    &:before {
        content: $icon-gluten;
    }
}
.icon-graines-de-sesame {
    &:before {
        content: $icon-graines-de-sesame;
    }
}
.icon-lait {
    &:before {
        content: $icon-lait;
    }
}
.icon-lupin {
    &:before {
        content: $icon-lupin;
    }
}
.icon-moutarde {
    &:before {
        content: $icon-moutarde;
    }
}
.icon-oeufs {
    &:before {
        content: $icon-oeufs;
    }
}
.icon-poissons {
    &:before {
        content: $icon-poissons;
    }
}
.icon-soja {
    &:before {
        content: $icon-soja;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-directions {
    &:before {
        content: $icon-directions;
    }
}
.icon-fleche-ph {
    &:before {
        content: $icon-fleche-ph;
    }
}
