.stores-block {
  //background-color: rgba(0, 0, 0, 0.04);
  padding: 60px 0;
  max-width: 100%;
  margin-top: 40px;
  overflow: hidden;
  @media (max-width: $screen-max-sm) {
    padding: 0 0 30px 0;
    margin-top: 20px;
  }
  @media (min-width: $screen-max-sm) and (max-width: $screen-max-md) {
    margin-top: -50px;
  }

  .title-trait {
    padding: 40px 0 0;
    text-transform: uppercase;
    font-family: $futura-light;
    font-size: 26px;
    @media(max-width: $screen-max-sm) {
      font-size: 22px;
    }
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: $screen-max-sm) {
    .storesblock-buttonblock {
      padding: 12px 24px;
    }
  }

  .descr-block {
    width: 60%;
    margin: auto;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    @media (max-width: $screen-max-sm) {
      padding: 0 30px;
    }
    @media (max-width: $screen-max-xs) {
      padding: 0 15px;

    }

    @media (max-width: $screen-max-md) {
      width: 100%;
    }

    .descr {
      text-align: center;
      margin-bottom: 18px;
      font-size: 18px;
      font-family: $futura-light;
      color: black;

      @media (max-width: $screen-max-sm) {
        margin-bottom: 14px;
        line-height: 1.5;
      }

    }

  }

  //.slick-list {
  //    overflow: visible;
  //    @media (max-width: $screen-max-lg) {
  //        overflow: hidden;
  //    }
  //}

  .stores-items {
    display: flex;
    padding: 0;
    margin-top: 46px;

    &.slick-initialized {
      display: block;
    }

    .slick-dots {
      padding-top: 60px;
    }

    @media (max-width: $screen-max-sm) {
      padding: 0 30px;
      margin-top: 18px;
      .slick-dots {
        padding-top: 50px;
      }

    }

    @media (max-width: $screen-max-xs) {
      padding: 0 15px;
      .slick-dots {
        padding-top: 40px;
      }

    }

    .slick-slide {
      margin: 0;
      // max-width: calc(100vw - 70px);
      // width: 100%;
    }

    .stores-item{
      position: relative;
      border-bottom: 3px solid $black;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $black;
      padding-bottom: 10px;
      flex: 1;
      max-width: 300px;
      width: 300px!important;
      margin: 0 10px;

      &:hover{
        text-decoration: none;
        color: $black;
      }

      .picture-store{
        width: 100%;
        height: 245px;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center;

        @media (max-width: $screen-max-sm){
          height: 196px;
        }

      }

      .title-store{
        font-size: 14px;
        font-family: $futura-heavy;
        font-weight: 300;
        margin-top: 14px;
        margin-bottom: 4px;
        letter-spacing: .62px;
        height: 35px;

        a{

          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

        }

      }

      .type-store{
        font-family: $futura-heavy;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 0;

        @media (max-width: $screen-max-sm){
          margin-bottom: 10px;
        }

      }

      .info-store{
        display: flex;
        display: -webkit-flex;
        margin-bottom: 13px;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        flex-direction: column;

        @media (max-width: $screen-max-sm){
          margin-bottom: 5px;
        }

        .tel-store{
          white-space: nowrap;

          a {
            position: relative;
            z-index: 2;
          }

        }

        p {
          margin: 0;
          letter-spacing: .64px;
        }

      }

    }

  }

}
