// Size for min-width
$screen-min-xs: 576px;
$screen-min-sm: 768px;
$screen-min-md: 992px;
$screen-min-lg: 1200px;
$screen-min-pad: 1024px;

// Size for max-width
$screen-max-xs: 575px;
$screen-max-sm: 767px;
$screen-max-md: 991px;
$screen-max-lg: 1199px;
$screen-max-pad: 1023px;

// CONTAINER
//$container: 1140px;
$container: 100%;
