.sidebar-account {
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100vh;
  width: 676px;
  z-index: 13;
  background-color: $white;
  transition: all .3s ease-in;
  overflow-y: scroll;
  @media (max-width: $screen-max-md) {
    top: 66px;
  }


  &.active {
    right: 0;
    @media (max-width: $screen-max-md) {
      top: 66px;
    }
  }

  @media (max-width: $screen-max-md) {
    width: 100%;
    right: -100%;
  }

  @media (max-width: $screen-max-sm) {
    padding-bottom: 60px;
  }

  .sidebar-header {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;

    @media (max-width: $screen-max-xs) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .sidebar-title {
      font-size: 18px;
      color: $black;
      margin: 0;
    }

    i {
      cursor: pointer;
    }

  }

  .sidebar-body {
    padding: 20px 60px 10px;

    @media (max-width: $screen-max-xs) {
      padding-left: 15px;
      padding-right: 15px;
    }

  }

  .sidebar-footer {
    padding: 10px 60px 50px;

    @media (max-width: $screen-max-xs) {
      padding-left: 15px;
      padding-right: 15px;
    }

  }

  .abs-blocks-2columns, .abs-discount-block-desktop .block, .amazon-addresses .amazon-address, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .form-address-edit > .fieldset, .form-edit-account .fieldset, .storecredit .block, .paypal-review-discount .block, .cart-discount .block {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0, .4);
  z-index:13;
  height: 100%;
  width: 100%;
  right:-100%;
}
