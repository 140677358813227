.customer-account-create-label {
    display: block;
    font-family: $futura-light;
font-weight:200;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 8px;

    @media (max-width: $screen-max-md) {
        margin-bottom: 4px;
    }

}

.logout {
    margin-top: 60px;

    .btn {
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 0;
        max-width: 100%;

        @media (max-width: $screen-max-md) {
            justify-content: flex-start;
            background: $black;
            border-color: $black;
            color: $white;
            padding: 12px 5px;
            &:hover {
                color: $white;
                background: $black;
                border-color: $black;
            }
        }
    }

    @media (max-width: $screen-max-md) {
        background-color: $black;
        margin-top: -7px;
    }
}

.customer-account-createpassword {
    .page-title-wrapper {
        display: flex;
        justify-content: center;
    }

}

.customer-address-form,
.customer-account-edit,
.customer-account-create {

    .field-name-prefix {
        label {
            color: $black;
        }
    }

    select {
        border: none;
        padding-left: 0;
    }

    .date-block {
        position: relative;

        input {
            padding: 0 9px;
        }

        .ui-datepicker-trigger {
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 10px;
            font-size: 18px;
            color: #999;
            @include transform(translateY(-50%));

            &:before {
                content: $icon-calendar;
                font-family: $icomoon-font-family;
                font-size: 18px;
            }

        }

    }

    .fieldset,
    .fieldset-fullname {

        .fields {
            display: block;

            .field {
                display: block;
                width: 100%;

                & + .field {
                    padding: 0;
                }

            }

        }

    }

    .fieldset.info {

        @media (max-width: $screen-max-md) {
            margin-bottom: 0;
        }

    }

    .fieldset,
    .fields {
        display: block;

        .fullname {
            margin-bottom: 40px;

            @media (max-width: $screen-max-md) {
                margin-bottom: 0;
            }

        }

        .field {
            position: relative;
            display: block;
            width: 100%;
            margin-bottom: 35px;

            @media (max-width: $screen-max-md) {
                margin-bottom: 30px;
            }

            &.field-inline {
                display: flex;
                display: -webkit-flex;
                margin-bottom: 42px;

                & > label {
                    width: 36%;
                    color: #000;
                    text-transform: uppercase;
                }

                .control {
                    width: 64%;
                }

            }


            .select2-selection--single {
                padding-left: 0;
                border: none;
                border-bottom: 1px solid $black;
                text-transform: none;
                font-size: 14px;
                min-height: 32px;
                border-radius: 0;

                .select2-selection__rendered {
                    font-family: $trade-gothic-light;
                    display: none;
                    padding-left: 0;
                }
            }

            &.additional {
                margin-top: 35px;

                @media (max-width: $screen-max-sm) {
                    margin-top: 30px;
                }

                .label {
                    width: auto;
                    height: auto;
                    clip: unset;
                }

            }

            &.date {

                label {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    @include transition(transform .2s $easeOutQuad);
                }

            }

            .radio {
                margin-right: 16px;
            }


            .control {
                position: relative;
            }

            input[type="text"],
            input[type=text],
            input[type="email"],
            input[type=email],
            input[type="password"],
            input[type=password],
            input[type="number"],
            input[type=number] {
                @extend .classic-input;
                font-family: $trade-gothic-light;
            }

        }

    }

    .fields {

        &.inline {
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            margin-bottom: 40px;

            @media (max-width: $screen-max-md) {
                display: block;
                margin-bottom: 74px;
            }

            .field {
                margin-bottom: 0;

                @media (max-width: $screen-max-md) {
                    margin-bottom: 35px;
                }

            }

        }

    }

    .actions-toolbar {
        margin-bottom: 30px;

        @media (max-width: $screen-max-md) {
            margin-bottom: 36px;
        }

    }

    .ph-gdpr-newsletter {
        border: none;
        margin-bottom: 15px;

        .field.choice.newsletter {
            margin-bottom: 10px;

        }
    }

}

.container-checkbox {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    vertical-align: top;
    justify-content: flex-end;
    font-size: 16px;
    margin-left: auto;

    @media (max-width: $screen-max-md) {
        width: 100%;
        padding-left: 0;
    }

    div {

        &:first-child {
            margin: 20px 0;
        }

        @media (max-width: $screen-max-md) {
            margin: 20px 0;
        }

    }

}

.form {

    &.create {

        &.account {
            width: 100%;
            max-width: 100%;
            min-width: 0;
            margin: 0 auto;
            font-size: 0;
            padding-top: 0;

            .fieldset {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                font-size: 16px;

                @media (max-width: $screen-max-md) {
                    width: 100%;
                }

                &.info {
                    padding-right: 8.333333%;

                    @media (max-width: $screen-max-md) {
                        padding-right: 0;
                    }

                }

                &.account {
                    padding-left: 8.333333%;

                    @media (max-width: $screen-max-md) {
                        padding-left: 0;
                    }

                }

                & > .legend {
                    font-family: $futura-heavy;
                    font-size: 20px;
                    line-height: 1.2;
                    text-transform: uppercase;
                    color: #000;
                    border: none;
                    margin-bottom: 44px;

                    @media (max-width: $screen-max-md) {
                        width: 100%;
                        text-align: center;
                    }

                    span {
                        margin: 0;
                    }

                }

                &.create {

                    &:after {
                        content: none;
                    }

                    & > .legend {
                        margin-bottom: 30px;
                    }

                    &.account {

                        & > .legend {
                            margin-bottom: 87px;

                            @media (max-width: $screen-max-md) {
                                margin-bottom: 22px;
                            }

                        }

                    }

                }

            }

            .primary {
                width: 100%;
                margin: 0;
            }

            .btn {
                min-width: 360px;
                max-width: 100%;

                @media (max-width: $screen-max-md) {
                    min-width: 0;
                }

            }

        }

    }

    &.password {
        margin: 20px auto;

        @media (max-width: $screen-max-md) {
            margin: 0;
        }

        &.forget {
            min-width: 460px;
            width: 460px;
            max-width: 100%;

            @media (max-width: $screen-max-md) {
                min-width: 100%;
                width: 100%;
            }

            .input-text {
                @extend .classic-input;
            }

            .fieldset {
                margin-bottom: 16px;

                &:after {
                    margin-top: 35px;
                    color: $black;
                }

            }

            .btn {
                &:focus, &:active {
                    background: $white;
                    color: $black;
                    border-color: $black;
                }
            }

        }

        .field {

            &.note {
                margin-bottom: 28px;
            }

        }

        .actions-toolbar {
            max-width: 460px;
            margin: 0 auto;

            .primary,
            .btn {
                width: 100%;
                margin: 0;
            }

        }

    }

}

.customer-account-forgotpassword,
.account,
.customer-account-login,
.customer-account-create {

    .page-main {

        @media (max-width: $screen-max-md) {
            padding-top: 75px;
        }

        .page-title-wrapper {
            @extend .page-title-type;
            padding: 0px 15px 40px;
            @media (max-width: $screen-max-md) {
                display: none;
            }

        }

    }

    main {
        margin-top: 0;
    }

}

.customer-account-forgotpassword,
.customer-account-login,
.customer-account-create {

    .actions-toolbar {
        margin-bottom: 30px;

        @media (max-width: $screen-max-md) {
            margin-top: 10px;
            margin-bottom: 20px;
        }

    }

    .page-main {

        @media (max-width: $screen-max-md) {
            padding-top: 0;
        }

        .page-title-wrapper {

            @media (max-width: $screen-max-md) {
                display: block;
                padding-top: 22px;
                padding-bottom: 50px;

                &:before {
                    margin-bottom: 11px;
                }

            }

        }

        .column {

            &.main {

                @media (max-width: $screen-max-md) {
                    padding: 0 15px;
                }

            }

        }

    }

}

.account {

    .messages {
        margin: 0;
    }

    .message {
        margin: 0 0 15px;
        border: none;
        text-align: left;
        font-size: 15px;
        padding: 0 15px;

        @media (max-width: $screen-max-md) {
            margin-bottom: 55px;
        }

        &.success {

            & > *:first-child {
                position: relative;

                &:before {
                    top: 50%;
                    margin: 0;
                    @include transform(translateY(-50%));
                }

            }

        }

    }

    .wishlist-toolbar {
        margin-bottom: 0;
    }

    .fieldset {
        float: none;
        clear: both;
        margin: 0 0 10px;
    }

    &.page-layout-2columns-left {

        .columns {
            @media (max-width: $screen-max-md) {
                display: flex;
            }
        }

        &.sales-order-view {

            .column {

                &.main {

                    .block {

                        &.block-order-details-view {
                            width: 100%;

                            .block-content {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        @media (max-width: $screen-max-md) {
                            margin-right: 0;
                        }

                        .block-title {
                            margin-bottom: 30px;

                            @media (max-width: $screen-max-md) {
                                margin-bottom: 15px;
                            }

                        }

                        .block-content {
                            border: none;

                            .box {
                                width: 100%;
                                border: 1px solid rgba(#000, .35);

                                .box-content {
                                    address {
                                        .col.name, .col.phone {
                                            max-width: unset;
                                        }
                                    }
                                }
                            }

                        }

                    }

                    .table-wrapper .tbody .tr {
                        padding: 0;

                        @media (max-width: $screen-max-sm) {
                            margin: 35px 0;
                        }

                        .col {
                            padding: 10px 0;

                            &.name {
                                padding-top: 10px;
                                align-items: flex-start;

                                .custom-gift-message {
                                    font-size: 13px;
                                }
                            }
                        }

                    }

                }

            }

        }

        .column {

            &.main {
                width: 75%;
                padding-right: 18px;
                padding-top: 15px;
                padding-left: 0;

                &.wide {
                    width: 100%;
                    padding-left: 75px;
                    padding-right: 75px;

                    @media (max-width: $screen-max-md) {
                        padding: 0 15px;
                    }

                }

                @media (max-width: $screen-max-md) {
                    padding: 28px 20px;
                }

                .block {
                    margin-bottom: 35px;

                    &:not(.widget) {

                        .block-content {

                            .box {

                                @media (max-width: $screen-max-md) {
                                    margin-bottom: 10px;
                                }

                            }

                        }

                    }

                    &.block-addresses-default {
                        margin-bottom: 25px;

                        @media (max-width: $screen-max-md) {
                            margin-bottom: 48px;
                        }

                    }

                    .block-title, .block-title > strong {
                        position: relative;
                        font-family: $futura-heavy;
                        font-size: 18px;
                        padding: 0;
                        margin: 0 0 10px;
                        border: none;
                        text-transform: uppercase;

                        @media (max-width: $screen-max-md) {
                            margin-bottom: 16px;
                        }

                    }

                    .block-content {
                        border: 1px solid #979797;

                        @media (max-width: $screen-max-md) {
                            border: none;
                        }

                        &.nob {
                            border: none;

                            .box {
                                border: 1px solid #979797;
                            }

                        }

                        .box {
                            position: relative;
                            width: 50%;
                            padding-bottom: 18px;

                            @media (max-width: $screen-max-md) {
                                width: 100%;
                                border: 1px solid #979797;
                            }

                            &-newsletter {
                                @media (min-width: $screen-min-md) {
                                    .box-title {
                                        border-left: 2px solid #FFF;
                                    }
                                }
                            }

                            .box-title {
                                display: block;
                                font-family: $futura-light;
font-weight:200;
                                font-size: 14px;
                                text-transform: uppercase;
                                margin-bottom: 0;
                                background-color: #F6F6F6;
                                padding: 15px 40px 10px 50px;

                                @media (max-width: $screen-max-md) {
                                    padding: 17px 13px 10px;
                                }

                            }

                            .box-content {
                                font-family: $trade-gothic-light;
                                font-size: 14px;
                                line-height: 1.6;
                                padding: 16px 40px;

                                p {
                                    padding: 0 10px;
                                }

                                @extend .address-tree-cols;

                            }

                            &-actions {
                                margin-top: 0;
                                padding: 0 50px;

                                @media (max-width: $screen-max-md) {
                                    padding: 0 13px;
                                }

                            }

                            &-address-shipping,
                            &-shipping-address,
                            &-address-billing,
                            &-billing-address {
                                width: 100%;
                                margin-bottom: 20px;

                                @media (max-width: $screen-max-md) {
                                    padding-bottom: 0;
                                }

                                .box-title {
                                    padding-left: 90px;

                                    @media (max-width: $screen-max-md) {
                                        padding-left: 55px;
                                        padding-right: 30px;
                                    }

                                }

                                .box-content {
                                    padding: 16px 40px;

                                    @media (max-width: $screen-max-md) {
                                        padding: 16px 13px 21px;
                                        line-height: 1.45;
                                    }

                                }

                                .address-figure {
                                    position: absolute;
                                    z-index: 1;
                                    top: 12px;
                                    left: 50px;
                                    font-size: 20px;

                                    @media (max-width: $screen-max-md) {
                                        top: 15px;
                                        left: 15px;
                                    }

                                }

                            }

                            .action {

                                &.edit {
                                    position: absolute;
                                    z-index: 1;
                                    top: 15px;
                                    right: 30px;
                                    width: 20px;
                                    height: 20px;
                                    text-align: center;
                                    color: #000;
                                    font-size: 18px;

                                    i {
                                        @include transition(color .3s $easeOutQuad);
                                    }

                                    &:after {
                                        content: none;
                                    }

                                    @media (max-width: $screen-max-md) {
                                        right: 11px;
                                    }

                                    &:hover {

                                        i {
                                            color: $black;
                                        }

                                    }

                                }

                                &.change-password {
                                    font-family: $futura-heavy;
                                    font-size: 12px;
                                    color: #000;
                                    transition: .3s;

                                }

                            }

                        }

                    }

                }

                .form-newsletter-manage {
                    max-width: 750px;

                    .legend {
                        margin-bottom: 27px;
                    }

                    .fieldset {
                        margin-bottom: 25px;
                    }

                    .block {
                        margin-bottom: 0;

                        .block-content {
                            padding: 35px;
                            border-color: #000;

                            @media (max-width: $screen-max-md) {
                                padding: 36px 26px;
                                border: 1px solid #000;
                            }

                            label {

                                &:before {
                                    top: 4px;
                                    margin-right: 20px;
                                    vertical-align: middle;
                                }

                                &:after {
                                    top: 5px;
                                }

                                span {
                                    vertical-align: middle;
                                }

                            }

                            .field {
                                margin-bottom: 40px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                            }

                        }

                    }

                    .actions-toolbar {
                        text-align: left;
                        width: 100%;

                        .btn {
                            margin: 45px 0 0;
                            min-width: 225px;
                            max-width: 100%;
                        }

                    }

                }

            }

        }

        .sidebar-main {
            width: 16.666667%;
            margin-right: 8.333333%;
            padding: 0;
            margin-bottom: 80px;

            @media (max-width: $screen-max-md) {
                position: relative;
                margin: 0 auto;
            }

            @media (max-width: $screen-max-sm) {
                position: absolute;
                z-index: 11;
                top: 0;
                left: 0;
                width: 100%;
                padding: 15px 20px;
                margin: 0;
            }

            .content {
                font-family: $futura-light;
font-weight:200;
                padding: 0;
                background: none;

                @media (max-width: $screen-max-md) {
                    border: none;
                }

                .item {
                    position: relative;
                    font-size: 13px;
                    text-transform: uppercase;
                    margin-bottom: 6px;

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: rgba(#979797, .51);
                        @include transition(height .3s $easeOutQuad, background-color .3s $easeOutQuad);
                    }

                    strong,
                    a {
                        color: #000;
                        border-left: none;
                        padding: 20px 5px 25px;
                        @include transition(color .3s $easeOutQuad);

                        &:hover {
                            background: none;
                        }

                    }

                    &.current {
                        font-family: $futura-heavy;
                    }

                    &:hover,
                    &.current {

                        strong,
                        a {
                            color: $black;
                        }

                        &:after {
                            height: 4px;
                            background-color: $black;
                        }

                        & > strong {
                            border: none;
                        }

                    }

                }

            }

            .block-collapsible-nav {

                @media (max-width: $screen-max-md) {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0;
                    background-color: #FFF;
                    border: 1px solid #cccccc;
                    cursor: pointer;
                }

                .title {

                    @media (max-width: $screen-max-md) {
                        font-size: 13px;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 15px 40px 15px 15px;
                        color: $black;
                        height: auto;
                        width: 100%;
                        position: relative;
                        display: block;
                        border-bottom: 1px solid #cccccc;
                        border-top: 1px solid #cccccc;

                        &::after {
                            font-size: 8px;
                            content: $icon-arrow-down;
                            font-family: $icomoon-font-family;
                            position: absolute;
                            top: 15px;
                            right: 30px;
                        }
                    }


                }

                .content {

                    @media (max-width: $screen-max-md) {
                        display: none;

                        &.active {
                            display: block;
                        }

                    }

                }

            }

        }

    }

    .form-edit-account {
        .field-name-lastname {
            margin-bottom: 40px;
        }
    }

    .form-address-edit {
        .fieldset {
            .field.choice {
                label {
                    cursor: pointer;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .form-newsletter-manage,
    .form-address-edit,
    .form-edit-account {
        width: 100%;
        max-width: 480px;
        margin: 0 auto 0 0;
        @media (max-width: $screen-max-md) {
            max-width: none;
        }

        .fieldset {
            float: none;
            width: 100%;
        }

        .legend {
            position: relative;
            font-family: $futura-heavy;
            font-size: 20px;
            padding: 0;
            border: none;
            margin: 0 0 50px;
            text-transform: uppercase;

            @media (max-width: $screen-max-md) {
                text-align: center;
                line-height: 1;
                margin-bottom: 40px;

                span {
                    margin: 0;
                }

            }

        }

    }

}

.customer-address-form,
.customer-account-edit {

    .actions-toolbar {

        .primary,
        .action {
            width: 100%;
            margin: 0;
        }

    }

}

.whislist-title {
    font-family: $futura-heavy;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.form-wishlist-items {
    max-width: 560px;
    padding-top: 20px;

    @media (max-width: $screen-max-md) {
        padding-top: 0;
        max-width: 100%;
    }

    &.empty {
        max-width: 410px;

        @media (max-width: $screen-max-md) {
            max-width: 100%;
        }

    }

    .message {
        padding: 0 15px 0 0;
        text-align: left;
    }

    .actions-toolbar {
        .secondary {

            .action {

                &.back {
                    display: block;
                    min-width: 350px;
                    max-width: 100%;

                    @media (max-width: $screen-max-md) {
                        min-width: 0;
                    }

                }

            }

        }

    }

}

.customer-account-index {

    &.account {

        &.page-layout-2columns-left {

            .column {

                &.main {
                    padding-left: 0;

                    @media (max-width: $screen-max-md) {
                        padding: 28px 20px;
                        width: 100%;
                    }

                }

            }

        }

    }

    .primary {
        width: 100%;

        .action {

            &.add {
                min-width: 320px;
                max-width: 100%;
                margin: 0;

                @media (max-width: $screen-max-md) {
                    min-width: 0;
                }

            }

        }

    }

}

.customer-address-form,
.newsletter-manage-index,
.customer-account-edit {

    &.account {

        &.page-layout-2columns-left {

            .column {

                &.main {
                    padding-left: 8.33%;

                    @media (max-width: $screen-max-md) {
                        padding: 28px 20px;
                        width: 100%;
                    }


                }

            }

        }

    }

    .primary {
        width: 100%;

        .action {

            &.add {
                min-width: 320px;
                max-width: 100%;
                margin: 0;

                @media (max-width: $screen-max-md) {
                    min-width: 0;
                }

            }

        }

    }

}

.newsletter-manage-index {

    .primary {
        text-align: left;
    }

}

.wishlist-index-index {

    .columns .column.main {
        .products-grid {

            .product-item {
                width: 100%;
                margin-left: 0;
                border: none;
                padding: 10px 0;

                @media (max-width: $screen-max-sm) {
                    padding: 5px 0;
                }

                .product-item-info {
                    @media (max-width: $screen-max-sm) {
                        flex-direction: column;
                        .product-item-description {
                            margin: 10px 0;
                        }
                    }
                }

                .product-item-actions {
                    @media (max-width: $screen-max-sm) {
                        display: flex;
                        width: 100%;
                    }
                }

                .price-container-ph {

                    & > span {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 12px;
                        padding-bottom: 10px;
                        color: rgba(#000, .8);
                        margin-left: 5px;
                    }

                    .type-ph {
                        margin-right: 20px;
                    }

                    .product-item-tooltip {
                        margin-bottom: 10px;
                        font-size: 12px;
                    }

                    .price-box {
                        line-height: 1;

                        @media (max-width: $screen-max-md) {
                            margin: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        p {
                            margin: 0;
                        }

                    }

                }

            }

            .product-item-actions {

                & > * {
                    margin-right: 0;
                }

                .actions-secondary {
                    a {
                        color: $black;
                    }
                }

            }

        }

        .product-item-info {
            width: 100%;

            .box-tocart {
                margin: 10px 0 0;
            }

        }

    }
}

.customer-address-index {
    &.account {
        &.page-layout-2columns-left {
            .column {
                &.main {
                    .block {
                        .block-title {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            @media (max-width: $screen-max-md) {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }

        }
    }

    & > .select2-container {

        .select2-dropdown {

            .select2-results {

                .select2-results__options {
                    padding: 5px;
                    text-align: center;
                }

            }

        }

    }

    .block-addresses-list {
        position: relative;
        padding-bottom: 40px;
    }

    .customer-addresses-toolbar {
        position: static;
        margin: 0;
        @media (max-width: $screen-max-md) {
            position: absolute;
            right: 0;
            top: -5px;
        }

        .pager {
            position: static;

            .pages {
                position: absolute;
                bottom: 0;
                right: 0;

                .items {
                    .item {
                        &:last-child {
                            margin: 0;

                            a {
                                padding: 0;
                            }
                        }
                    }
                }
            }

            .limiter {
                display: block;
                width: 100%;
                text-align: right;

                .limiter-label {
                    font-size: 14px;
                    text-transform: initial;
                }

                .select2-container {
                    width: auto !important;
                    .select2-selection--single{
                        height: auto;
                    }
                }
            }
        }
    }

    .block-content {
        display: flex;
        flex-direction: column-reverse;
    }

    .primary {

        .btn {
            min-width: 320px;
            max-width: 100%;

            &.btn-black {
                &:focus, &:active {
                    background: $white;
                    border-color: $black;
                    color: $black;
                }
            }

            @media (max-width: $screen-max-md) {
                min-width: 0;
            }

        }

    }

}

body .modal-popup.modal-slide._inner-scroll {

    &.popup-authentication {
        top: 0;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        transform: none;
        margin: 0;

        @media (max-width: $screen-max-sm) {

            &._show {
                left: 0;
            }

        }

        .action-close {

            @media (max-width: $screen-max-sm) {
                right: 18px;
            }

        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 40px;

            @media (max-width: $screen-max-xs) {
                padding-left: 25px;
                padding-right: 25px;
            }

            .modal-title {
                border: none;
                font-size: 20px;
                color: $black;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
            }

            .action-close {
                margin: 0;
                padding: 0;
                position: initial;
                line-height: 0;
            }
        }

        .modal-inner-wrap {
            position: absolute;
            top: 0;
            left: auto;
            right: 0;
            margin: 0;
            height: 100%;
            max-height: 100%;
            min-width: 676px;
            width: 676px;
            max-width: 100%;
            @include box-shadow(none);
            @include transform(none);

            @media (max-width: $screen-max-sm) {
                padding: 0;
                min-width: 0;
                background-color: #FFF;
            }

        }

        .modal-content {
            padding: 20px 60px 10px;


            @media (max-width: $screen-max-sm) {
                padding: 0 25px;
            }

        }

        .block-authentication {
            display: block;
            border: none;
        }

        .block {
            width: 100%;
            padding: 0;

            .half {
                width: 50%;
                padding-right: 10px;

                @media (max-width: $screen-max-sm) {
                    width: 100%;
                    padding: 0;
                    margin-top: 35px;
                }

                & + .half {
                    padding-left: 10px;
                    padding-right: 0;
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $screen-max-sm) {
                        padding-left: 0;
                    }

                }

            }

            &.block-new-customer {
                display: flex;
                display: -webkit-flex;
                padding: 50px 0;
                border: none;
                flex-direction: row;

                @media (max-width: $screen-max-sm) {
                    margin-top: 0;
                    padding-top: 15px;
                    display: block;
                }

                &:before {
                    content: none;
                }

                .block-title {
                    margin-bottom: 8px;
                }

                .block-content-inner {
                    min-height: 60px;
                    margin-bottom: 24px;
                    flex: 1 0 auto;

                    @media (max-width: $screen-max-sm) {
                        margin-bottom: 16px;
                        min-height: unset;
                    }

                }

                .primary {
                    text-align: left;
                }

                .actions-toolbar {

                    .btn {
                        display: inline-block;
                        width: auto;
                        letter-spacing: .46px;
                        min-width: 210px;
                        max-width: 100%;

                        @media (max-width: $screen-max-sm) {
                            width: 100%;
                        }

                    }

                }

            }

            .block-title {
                font-family: $futura-heavy;
                font-size: 18px;
                line-height: 1.2;
                text-transform: uppercase;
                border: none;
            }

        }

        .fieldset {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }

        }

        .field {
            position: relative;
            margin-bottom: 35px;

            @media (max-width: $screen-max-sm) {
                margin-bottom: 32px;
            }

            label {
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                margin: 0;
            }

            .input-text {
                @extend .classic-input;
            }

        }

        .actions-toolbar {
            .secondary {
                margin: 0 0 35px;
                padding: 0;

                @media (max-width: $screen-max-sm) {
                    margin-top: 18px;
                }

                a {
                    display: block;
                    width: 100%;
                    font-size: 12px;
                    line-height: 1.2;


                }

            }
        }

        .primary {
            width: 100%;

            .btn {
                width: 100%;
                font-size: 13px;
                letter-spacing: .46px;
            }

        }

    }

}

//
//  RECENT ORDERS
//
.account.page-layout-2columns-left .column.main .block-dashboard-orders .block-content {
    border: none;
}

.block-dashboard-orders {
    margin-bottom: 30px;
}

.account .table-wrapper {
    border-bottom: none;

    &.orders-recent {
        margin-top: 0;
    }

    .tfoot,
    .thead,
    .tbody {
        display: block;
        width: 100%;
        color: #000;
    }

    .thead {
        font-family: $futura-heavy;
        font-size: 13px;
        line-height: 1.4;
        text-transform: uppercase;

        @media (max-width: $screen-max-md) {
            display: none;
        }

        .th {
            font-family: $futura-heavy;
            font-size: 13px;
        }

        .tr {
            border: none;
            background-color: #FFF;
            padding: 0 20px;

            @media (max-width: $screen-max-xs) {
                padding: 0 14px;
            }
        }

    }

    .tr {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        border: 1px solid #999999;
        border-bottom: 0;
        border-collapse: collapse;
    }

    .tbody {

        .thead {
            .tr {
                padding: 0 20px;
            }
        }

        .tr {
            background-color: #FFF;
            border-bottom: 0;
            padding: 0 20px;

            &:last-child {
                border-bottom: 1px solid #999;
            }
        }

    }

    .td, .th {
        display: inline-block;
        font-family: $futura-light;
font-weight:200;
        font-size: 12px;
        padding: 0;

    }

    div.col {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        min-height: 64px;
        height: 100%;

        &.name {
            padding-top: 0;

            &:before {
                padding: 0 !important;
            }
        }

        &.name {
            width: 40%;

            @media (max-width: $screen-max-md) {
                width: 100%;
            }

        }

        &.sku {
            width: 15%;
            justify-content: flex-end;
        }

        &.price,
        &.subtotal {

            .price-incl-tax,
            .price-excl-tax,
            .price-excluding-tax,
            .price-including-tax {
                font-family: $futura-light;
font-weight:200;
                font-size: 16px;
                color: #000;

                .price {
                    font-weight: 300;
                }

            }

        }

        &.price {
            width: 15%;
            justify-content: flex-end;
        }

        &.qty {
            width: 12%;
            justify-content: flex-end;
        }

        &.subtotal {
            width: 18%;
            justify-content: flex-end;
            padding-right: 10px;
        }

        &.sku,
        &.price,
        &.qty,
        &.subtotal {

            @media (max-width: $screen-max-md) {
                justify-content: space-between;
            }

        }

        &.id {
            width: 20%;

            @media (max-width: $screen-max-sm) {
                width: 35%;
            }

        }

        &.date {
            width: 16%;

            @media (max-width: $screen-max-sm) {
                width: 35%;
                justify-content: center;
            }

        }

        &.total {
            width: 16%;

            @media (max-width: $screen-max-sm) {
                width: 20%;
            }

        }

        &.status {
            width: 24%;
            font-family: $futura-heavy;
            text-transform: uppercase;

            &.waitingforshipping, &.processing, &.chronopostIncident {
                color: #E75744
            }

            &.pending {
                color: #2B6897;
            }

            &.complete, &.delivered {
                color: #60A285;
            }

            &.canceled, &.abandoned_monetico {
                color: #9D9D9D;
            }

            &.closed {
                color: $black;
            }

            &.holded {
                color: #BA303D;
            }

            @media (max-width: $screen-max-sm) {
                display: none;
            }
            @media (min-width: $screen-min-lg) {
                width: 29%;
            }

        }

        &.actions {
            width: 19%;
            text-transform: uppercase;

            @media (max-width: $screen-max-sm) {
                width: 10%;
                justify-content: center;
                padding: 20px 0;
            }

            a {
                color: $black;

                i {
                    display: inline-block;
                    font-size: 10px;
                    margin-left: 5px;
                    @include transition(transform .25s $easeInQuad);

                    @media (max-width: $screen-max-xs) {
                        font-size: 8px;
                        margin-left: 0;
                    }

                }

                @media (max-width: $screen-max-sm) {

                    span {
                        display: none;
                    }

                }

            }

        }

    }

}

.order-page-header {
    position: relative;

    h2 {
        position: relative;
        font-family: $futura-light;
font-weight:200;
        font-size: 22px;
        margin-bottom: 50px;
        color: #000;

        @media (max-width: $screen-max-md) {
            line-height: 1.2;
        }

        @media (max-width: $screen-max-sm) {
            margin-left: 30px;
        }
    }

    .back {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: -40px;
        transition: all .25s cubic-bezier(.55, .085, .68, .53);
        padding: 0 5px;

        &:hover {
            transform: translateX(-10px);
            transition: transform .25s cubic-bezier(.55, .085, .68, .53);
        }


        @media (max-width: $screen-max-sm) {
            top: 5px;
            left: 0;
        }

    }

    i {
        display: inline-block;
        color: $black;
        font-size: 16px;
        @include transform(rotate(180deg));
    }

    .order-infos {
        display: block;
        width: 100%;
        font-size: 0;
        margin-bottom: 50px;

        @media (max-width: $screen-max-md) {
            margin-bottom: 42px;
        }

        date {
            text-transform: uppercase;
        }

    }

    .col {
        display: inline-block;
        vertical-align: top;
        font-family: $trade-gothic-light;
        font-size: 0;
        width: 50%;
        list-style: none;
        padding: 0;
        margin: 0;

        @media (max-width: $screen-max-md) {
            width: 100%;
        }

        li {
            margin-bottom: 30px;

            @media (max-width: $screen-max-md) {
                margin-bottom: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }

        }

        span {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            font-size: 14px;

            @media (max-width: $screen-max-md) {
                width: 100%;
            }

            &:first-child {
                font-family: $futura-heavy;
                font-size: 16px;
                text-transform: uppercase;
            }

        }

    }

}

.sales-order-view {

    .actions-toolbar {
        margin: 0 0 60px;

        .cta {
            display: inline-block;
            // min-width: 210px;

            @media (max-width: $screen-max-sm) {
                padding: 8px 14px;
            }

            &:visited {
                color: $white;
            }

        }

    }

    .block-order-details-view {
        width: 80%;

        @media (max-width: $screen-max-sm) {
            width: 100%;
        }

    }

    .order-details-items {

        &.ordered {
            border: none;
            padding: 0;
            margin: 0;
        }

        .order-items {

            @media (max-width: $screen-max-sm) {
                overflow: visible;
                border: none;
            }

        }

        .td {
            font-size: 16px;
            @media (max-width: $screen-max-sm) {
                &.product-name {
                    .product-item-name {
                        font-family: $futura-light;
font-weight:200;
                    }
                }
            }
        }

        .thead {

            @media (max-width: $screen-max-md) {
                display: block;
            }

            @media (max-width: $screen-max-sm) {
                display: none;
            }

            .col {
                min-height: 40px;
            }

        }

        .tfoot {
            margin-top: 20px;
            padding: 18px 10px;
            background-color: rgba(#F4F4F4, .45);

            @media (max-width: $screen-max-sm) {
                margin-top: 30px;
                margin-left: -25px;
                margin-right: -25px;
                width: auto;
                padding: 28px 35px;
            }

            .tr {
                border: none;
                margin-bottom: 10px;

                @media (max-width: $screen-max-sm) {
                    font-size: 0;
                }

                &.totals-tax-summary, &.shipping {
                    margin-bottom: 30px;
                }

                .td, .th {

                    &.mark,
                    &.label {
                        width: 82%;
                        font-size: 14px;
                        letter-spacing: .28px;
                        text-align: right;

                        @media (max-width: $screen-max-sm) {
                            width: 50%;
                            text-align: left;
                            padding: 0;
                        }

                    }

                    &.amount,
                    &.subtotal {
                        width: 18%;
                        font-size: 16px;
                        letter-spacing: .32px;
                        text-align: right;
                        padding-right: 0;

                        @media (max-width: $screen-max-sm) {
                            width: 50%;
                        }

                    }

                }

            }

        }

        .twrap {
            padding: 20px;
            border: 1px solid #999999;
            @include border-radius(2px);

            @media (max-width: $screen-max-sm) {
                padding: 0;
                border: none;
            }

        }

        .tbody {

            .tr {
                border: none;
                background-color: transparent;

                @media (max-width: $screen-max-sm) {
                    display: block;
                }

            }

            .col {
                padding: 0;
                min-height: 70px;

                @media (max-width: $screen-max-sm) {
                    min-height: 0;
                    margin-bottom: 10px;

                    &:before {
                        content: attr(data-th);
                        display: inline-block;
                        font-family: $futura-heavy;
                        font-size: 13px;
                        text-transform: uppercase;
                        padding-right: 5px;
                    }

                }

                &.subtotal {
                    padding-right: 10px;

                    @media (max-width: $screen-max-sm) {
                        padding-right: 0;
                    }

                }

            }

        }

        .product-name {
            flex-direction: column;
            justify-content: center;

            @media (max-width: $screen-max-sm) {

                &:before {
                    width: 100%;
                }

            }

            .product-item-name {
                font-family: $futura-heavy;
                font-size: 16px;
                width: 100%;
                margin: 0;
            }

            .item-options {
                display: block;
                width: 100%;
                font-size: 12px;

                dt {
                    display: inline-block;
                }

                dd {
                    display: inline-block;
                }
            }

        }

    }

    .btn.track {
        margin-left: 10px;
    }

}

.sales-order-history {
    .column {
        &.main {
            display: flex;
            flex-direction: column;
            position: relative;

            .order-products-toolbar {
                position: static;
                order: 1;

                .pager {
                    display: flex;
                    justify-content: space-between;
                    position: static;

                    .limiter {
                        display: block;
                        width: 100%;
                        text-align: right;

                        .select2-container {
                            width: auto !important;
                        }

                        .limiter-text {
                            display: none;
                        }
                    }

                    .pages {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }

                }
            }

            .block-dashboard-orders {
                order: 2;
            }
        }
    }

    .select2-container .select2-dropdown .select2-results .select2-results__options {
        padding: 5px;
        text-align: center;
    }
}

// Additional addresses
.account.page-layout-2columns-left .column.main .block .block-content {
    .additional-addresses {
        .box-address-shipping {
            display: flex;
            padding: 16px 40px;

            @media (max-width: $screen-max-sm) {
                padding: 15px;
            }

            .box-content {
                padding: 0;
                flex-grow: 2;

                address {
                    display: flex;

                    @media (max-width: $screen-max-sm) {
                        flex-direction: column;
                    }

                    .col {
                        display: block;
                        min-height: initial;
                        padding: 0 5px;
                        width: 100%;

                        @media (max-width: $screen-max-sm) {
                            margin-bottom: 10px;
                        }

                        p {
                            line-height: 20px;
                            margin: 0;
                            font-size: 14px;
                        }
                    }
                }
            }

            @extend .address-box-actions;

        }

    }

}

.customer-account-create {

    .fieldset {

        .field {

            .radio {

                &:last-child {
                    margin-right: 0;
                }

            }

            &.field-name-prefix {
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;

                @media (max-width: $screen-max-xs) {
                    display: block;
                }

                .control {
                    display: inline-block;
                    width: auto;
                }

            }

        }

    }

    .required-fields-info {
        color: #000;
    }

    .container-checkbox {

        .field {
            position: relative;
            padding-left: 30px;

            .label {
                display: block;
            }

            input[type=checkbox] {
                position: absolute;
                z-index: 1;
                top: 5px;
                left: 0;
                margin: 0;
            }

        }

    }

    .form-disclaimer {
        font-size: 11px;
        font-style: normal;
        line-height: 1.4;
        color: #000;
        text-transform: none;

        a {
            color: #000;
        }

    }

    .recaptcha-privacy-terms {
        font-size: 11px;
        font-style: normal;
        line-height: 1.4;
        color: #000;
        text-transform: none;
        margin-bottom: 20px;

        a {
            color: #000;
            text-decoration: underline;
            transition: all ease .4s;
        }

    }

}
