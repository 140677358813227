@include keyframes(heart) {
    0% {
        @include  transform(scale(1));
    }
    10% {
        @include  transform(scale(0.8));
    }
    20% {
        @include  transform(scale(1.2));
    }
    30% {
        @include  transform(scale(1));
    }
    40% {
        @include  transform(scale(1.3));
    }
    50% {
        @include  transform(scale(1));
    }
}

@include keyframes(weave-horizontal) {
    0% {
        @include transform(translateX(0));
    }
    5% {
        @include transform(translateX(10px));
    }
    10% {
        @include transform(translateX(0));
    }
}

@include keyframes(breadcrumb) {
    0% {
        width: auto;
        overflow: visible;
        height: auto;
    }
    100% {
        width: 50px;
        overflow: hidden;
        height: 30px;
    }
}


@include keyframes(appear){
    0% {
        opacity: 0;
        transform: translateY(20px);
	}

	35% {
        opacity: 0;
        transform: translateY(10px);
    }  

	45% {
        opacity: 0;
        transform: translateY(8px);
    }

  
    100% {
        opacity: 1;
        transform: translateY(0px);
	}
}