.ph-customtag {
    position: relative;
    display: inline-block;
    color: $black;
    text-transform: uppercase;
    font-family: $futura-light;
font-weight:200;
    font-size: 16px;
    padding: 6px 0px;
}
