.macarons-descr-product{
  padding: 20px 0 0;
  .row{
    display: flex;
    flex-wrap: wrap;
    .owl-dots .owl-dot span{
      display: none;
    }
    .slider-thumb{
      width: calc(100%/4);
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 8px;
      @media (max-width: $screen-max-sm){
        width: 100%;
      }
      .thumb-name{
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        @media (max-width: $screen-max-sm){
          font-size: 14px;
        }
      }
      .thumb-desc{
        text-align: center;
        @media (max-width: $screen-max-sm){
          font-size: 12px;
        }
      }
    }
  }
}
