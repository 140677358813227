.minicart-wrapper .block-minicart li:hover {
    cursor: initial;
}

.minicart-wrapper {

    &.active {
        .block-minicart {
            right: 0;
            .cart-layout-bottom {
                right: 0;
            }
        }
    }

    .minicart-items {
        width: 100%;
        max-height: max-content;
    }

    .block-minicart {
        position: fixed;
        height: 100%;
        //max-height: 100vh;
        top: 0;
        right: -100%;
        transition: right 0.3s ease;
        margin: 0;
        box-shadow: none;
        border-color: rgba(#979797, .26);
        z-index: 800;
        //padding: 0;
        min-width: 60%;
        width: 390px;
        max-width: 100%;

        display: block;
        overflow: scroll;
        padding: 0 0 124px 0;

        @media (max-width: $screen-max-xs) {
            min-width: 100%;
        }
        @media (min-width: $screen-min-lg) {
            min-width: 40%;
        }

        .block-title {
            padding: 50px 15px 5px;

            @media (max-width: $screen-max-xs) {
                padding: 30px 15px 5px;
            }

            display: flex;
            flex-direction: column;
            font-family: $futura-heavy;
            text-transform: uppercase;
            font-size: 2.5rem;

            .wrapper {
                display: flex;
                justify-content: space-between;
            }

            .close-outer {
                padding:14px 0;
                cursor: pointer;
                background: unset;
                border: none;
            }

            .close {
                display: block;
                position: relative;
                background: black;
                height: 1px;
                width: 35px;
                transform: rotate(45deg);
                &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 35px;
                    background: black;
                    transform: rotate(-90deg);
                }
            }
        }

        .block-content {
            height: auto !important;
            //overflow-y: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            i.icon-logo-full {
                font-size: 80px;
            }
            span.empty {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
            .subtitle.empty {
                font-size: 22px;
                @media (min-width: $screen-min-md) {
                    width: 70%;
                    margin: 0 auto;
                }
            }
        }

        .items-total {
            width: 100%;
            color: $black;
            font-size: 1.375rem;
            line-height: 1.2;
            font-family: $futura-light;
            font-weight: inherit;
            margin: 0 0 15px 0;
            padding: 0;
            text-align: left;
            text-transform: initial;

            .count {
                font-weight: inherit;
            }

        }

        &:before,
        &:after {
            content: none;
        }

    }

    .minicart-items-wrapper {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;

    }
    .cross-sell-wrapper {
        background: #f5f5f5;
        padding: 30px 15px;
        margin: 0;
        min-height: inherit;

        &-title {
            padding: 0 0 15px 0;

            h3{
                margin: 0;
                text-align: left;
                text-transform: uppercase;
                font-family: $futura-heavy;
                font-size: 1.375rem;
            }
        }

        #mini-cart-cross-sell {
            padding: 0;
            margin: 0;

            .product-item-cross {
                padding: 20px 0 15px;
                position: relative;

                &:first-of-type{
                    border-top: none;
                }

                &:last-of-type {
                    padding-bottom: 0;
                    border-bottom: 0;
                }

                .product {
                    .product-item-details {
                        padding-left: 105px;

                        .product-item-name, .product-item-subtitle {
                            max-width: 75%;
                            margin-right: 95px;

                            @media (min-width: 1700px) {
                                max-width: 65%;
                            }
                        }
                    }

                    .actions {
                        right: 0;
                        top: 50%;
                        margin: 0;
                        position: absolute;
                        transform: translateY(-50%);

                        .viewcart {
                            width: 100%;
                            height: 100%;
                            padding: 9px 17px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                            @include transition(all ease 0.3s);

                            svg {
                                width: 10px;
                                height: 10px;
                            }

                            &:before,
                            &:after {
                                content: none;
                            }

                            &.disabled {
                                pointer-events: none;
                                opacity: .8;
                            }

                            &:hover {
                                svg {
                                    path {
                                        stroke: $black;
                                    }
                                }
                            }
                        }

                        @media (min-width: 1700px) {
                            right: 20%;
                        }
                    }
                }
            }
        }
    }

    .minicart-items {
        padding: 0 15px;

        .product-item {
            padding: 20px 0 15px;
            border-top: 1px solid rgba(#979797, .26);

            @media (max-width: $screen-max-xs) {
                padding: 15px 0 20px;
            }

            &-details {
                position: relative;
                padding-left: 105px;
                padding-right: 10px;

            }

            &-name {
                text-transform: uppercase;
                font-family: $futura-heavy;
                font-size: 14px;
                line-height: 1.2;
                letter-spacing: .5px;
                margin-bottom: 0;
                max-width: 90%;
                padding: 5px 0;
                @media (min-width: $screen-min-md) {
                    padding-right: 50px;
                    max-width: unset;
                }
                text-align: left;

                a {
                    color: #000000;
                }

            }

            &-subtitle {
                margin-bottom: 0;
                padding-right: 20px;
                text-align: left;
                max-width: 90%;
                @media (min-width: $screen-min-md) {
                    padding-right: 50px;
                }
            }

            &-cart-wrapper {
                display: flex;
                display: -webkit-flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;
                margin-top: 12px;
            }

            &-pricing {
                font-family: $futura-heavy;
                text-align: left;
                .price-container {
                    .price {
                        font-family: $futura;
                        font-size: 1.563rem;
                        line-height: 1.2;
                    }
                }
            }

            &-photo {
                width: 86px;
                height: auto;
                box-sizing: border-box;
                border: 0;
                padding: 0;
                margin-top: 3px;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        .details-qty {
            font-family: $futura-heavy;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: .42px;
            margin-top: 3px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            color: rgba(0, 0, 0, 0.8);

            .wrapper {
                width: 100px;
                height: 33px;
                border: 1px solid black;
                display: flex;
                justify-content: center;
            }

            &:not(.qty){
                flex-direction: column;
            }

            .label-qty, label {
                color: rgba(0, 0, 0, 0.8);
                font-family: $futura-light;

                &:after {
                    margin: 0 5px;
                }
            }

            .ph-product-option {
                width: 100%;
                text-align: left;
            }

            div:not(.wrapper) {
                &:not(.mage-error) {
                    height: 100%;
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    position: relative;
                }
                &.detail-qty{
                    display: flex;
                    width: 100%;
                    height: auto;
                    border: none;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }

                &:hover {
                    @include transition(ease all 0.4s);

                    a {
                        color: $white;
                    }

                    background-color: $black;
                }

                a {
                    color: $black;
                    font-size: 12px;
                    line-height: 1;
                    font-family: $futura-heavy;
                    width: 12px;
                    height: 12px;
                    text-align: center;


                    &.moins-qty, &.plus-qty {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:after {
                            content: '';
                            position: absolute;
                            inset: 0;
                        }
                    }

                    &:hover {
                        text-decoration: none;

                        &.moins-qty {
                            &::before {
                                color: $white;
                            }
                        }
                    }
                }
            }

            .update-cart-item {
                margin: 0 10px;
                padding: 5px 10px;
                font-size: 10px;
                min-height: 30px;
            }
        }

        .qty {

            input[type=text] {
                height: 20px;
            }

            input[type=number] {
                height: 30px;
                border: unset;
                width: 40%;
                margin: 0;
            }

        }

        .actions {
            position: relative;
            top: initial;
            right: initial;
            float: none;
            margin: 2px 0 12px 0;
            display: block;
            background-color: #f5f5f5;

            .delete {
                &:before {
                    vertical-align: top;
                }
            }
            .viewcart {
                font-family: $futura;
            }

            .edit,
            .delete {
                &:before {
                    content: $icon-trash;
                    font-family: $icomoon-font-family;
                    font-size: 15px;
                    width: 33px;
                    height: 33px;
                    line-height: 1.2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:hover {
                    &:before {
                        border-color: $black;
                    }
                }
            }

        }

        .shipping-container {
            .shipping-container-block {
                cursor: initial;
                i {
                    display: none;
                }
                .legend {
                    font-size: 14px;
                }
            }
        }

    }

    .details-qty {
        margin-top: 0;

        .ph-accessory-qty {
            display: flex;
            align-items: center;
        }

    }

    .cart-totals-wrapper {
        padding: 0;

        .cart-totals {
            width: 100%;
            border-top: unset;
            padding: 0;

            .popup-cart-overview {
                margin-bottom: 6px;
                padding: 0;
                text-align: left;
                width: 100%;
                font-size: 10px;
            }

            .progress {
                background-color: #f5f5f5;
                border-radius: 20px;
                position: relative;
                margin: 10px 0;
                height: 8px;
                width: 100%;
            }

            .progress-done {
                background: $black;
                border-radius: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 0;
                opacity: 1;
                transition: 1s ease 0.3s;
            }

            .subtotal {
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                text-transform: uppercase;
                font-family: $futura;
                font-weight: 200;
                font-size: 17px;
                padding: 0;
                color: $black;
                margin-bottom: 0;
                border: none;

                .label {
                    text-align: left;
                    font-size: 17px;
                    margin-bottom: 0;
                    display: none;

                    .little-text {
                        font-size: 9px;
                    }
                }

                .price-container {
                    text-align: left;
                    font-family: $futura-heavy;
                    font-size: 20px;
                    letter-spacing: .4px;
                    color: #000;
                    padding-left: 5px;
                }
            }
        }
    }

    .cart-layout-bottom {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: flex-end;
        height: auto;
        margin: 0;
        padding: 22px 15px;
        font-family: $futura-light;
        font-weight: 200;
        position: fixed;
        bottom: 0;
        right: -100%;
        background-color: #fff;
        z-index: 2;
        transition: right .3s ease-in-out;
        transition-delay: .04s;
        min-width: 60%;
        width: 390px;
        max-width: 100%;
        @media (max-width: $screen-max-xs) {
            min-width: 100%;
        }
        @media (min-width: $screen-min-lg) {
            min-width: 40%;
        }

        .subtotal {
            margin: 0 0 0 5px;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            gap: 5px;

            .label {
                display: none;
            }

            &:before {
                content: '-';
                display: block;
            }
        }

        .actions {
            width: 100%;

            a {
                display: block;
                font-family: $futura;
                font-size: 14px;

                &.viewcart {
                    margin-bottom: 15px;
                    border-radius: 22px !important;
                    padding: 8px 14px;
                    min-height: auto;
                    font-size: 13px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    &:hover {
                        background: $black;
                        color: $white;
                        border-color: $white;
                    }
                }
            }

            button {
                width: 100%;
                font-family: $futura;
                border-radius: 22px !important;
                padding: 8px 14px;
                min-height: auto;
                font-size: 13px;
            }

        }

        .subtotal .price-container .price {
            font-family: $futura;
            font-size: 13px;
        }

    }

    .action {

        &.showcart {

            &.active::before,
            &:before {
                font-family: $icomoon-font-family;
                font-size: 22px;
                content: $icon-cart;
                margin-bottom: 5px;

                @media (max-width: $screen-max-md) {
                    font-size: 21px;
                    margin: 0 0 5px 0;
                }

            }

            .counter-number {
                text-shadow: none;
            }

            .counter {

                &.qty {
                    position: absolute;
                    top: 10px;
                    left: 12px;
                    font-size: 10px;
                    line-height: 16px;
                    height: 16px;
                    min-width: 16px;
                    @include border-radius(50%);
                    background-color: $pink;
                }

                .loading-mask {
                    position: fixed !important;
                }
            }

        }

    }
}
