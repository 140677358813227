.doublecard{
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  @media (max-width: $screen-max-md){
    flex-direction: column;
  }
  .card-ph{
    width: 48%;
    text-align: center;
    @media (max-width: $screen-max-md){
     width: 100%;
      &:first-child{
        margin-bottom: 30px;
      }
    }
    .picture{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 265px;
    }
    .container-card-ph{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      background-color: $white;
      width: 90%;
      margin: -50px auto 0;
      h3{
        font-family: $futura-heavy;
        font-weight: initial;
      }
      .contenu-text{
        margin-top: 20px;
        min-height: 150px;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 25px;
        @media (max-width: $screen-max-md){
          min-height: unset;
          margin-bottom: 30px;
          font-size: 14px;
        }
        a {
          color: $black;
        }
      }
      .btn-container{
        display: flex;
        justify-content: center;
      }
    }
  }
}
