.image-bg-block-wrapper {
    margin: 50px 0;
    @media (max-width: $screen-max-sm){
        margin: 20px 0;
    }

    .image-bg-block {
        color: white;
        margin-top: 0;
        @include aspect-ratio(1131, 400);
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            width: auto;
        }
        @media (max-width: $screen-max-md) {
            width: 100%;
            height: auto;

            &:before {
                display: none;
            }
        }


        .picture-bg-block {
            position: relative;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px 15px;
            @media (max-width: $screen-max-md) {
                min-height: 300px;
                position: initial;
            }

            &.dark {
                background: #0000008c;
                width: 50%;
                height: max-content;

                @media (max-width: $screen-max-sm) {
                    width: 100%;
                }
            }

            & > p {
                margin-bottom: 30px;
                width: 50%;
                @media (max-width: $screen-max-sm) {
                    width: 100%;
                }
            }

            & > h2, & > p {
                text-align: center;
            }

        }
    }
}
