.title-text-block {
    padding-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    min-height: 500px;
    max-width: $maxColWidth;
    @media (max-width: $screen-max-lg){
        min-height: 350px;
        padding-top: 40px;
    }

    @media (max-width: $screen-max-sm) {
        min-height: auto;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        text-transform: uppercase;
        font-family: $futura-light;
        font-size: 26px;
        margin: 0 130px;
        @media(max-width: $screen-max-sm) {
            margin: 0 0 25px 0;
            font-size: 22px;
        }
    }

    &__description {
        font-family: $futura-light;
        font-size: 18px;
        margin: 0 40px;
        @media(max-width: $screen-max-sm) {
            font-size: 16px;
            margin: 0;
        }
    }

    .left, .right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (max-width: $screen-max-sm) {
            width: 100%;
        }
    }

}
