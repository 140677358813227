.amlocator-index-index{

  .column.main {
    @media (max-width: $screen-max-md){
      padding-bottom :0;
    }
  }
  @media (max-width: $screen-max-sm) {
    .page-footer {
      margin-top: 0;
    }
  }

  .page-main{
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .amlocator-arrow{
    margin: 6px 5px 0 10px;
    position: absolute;
    right: -5px;
    top: 0;

    &:after, &:before {
      top: 0;
      width: 6px;
    }

    &:after {
      right: 2px;
    }
    &:before {
      left: 2px;
    }
  }

  .page-title-wrapper{
    display: none;
  }

  .amlocator-main-container{
    position: relative;
    height: auto;

    .title-amlocator{
      font-size: 10px;
      font-family: $futura-heavy;
      letter-spacing: .53px;
      @media (max-width: $screen-max-md){
        font-size: 14px;
      }
    }

    .amlocator-search-container{
      z-index: 2;
      background-color: $black;
      color: $white;
      padding: 20px 35px 22px;
      margin-bottom: 0;
      width: 100%;

      @media (max-width: $screen-max-md){
        order: 1;
        .-separator{
          display: none;
        }
      }

      .amlocator-block{
        margin-bottom: 3px;
      }

      .amlocator-attribute-wrapper{
        padding: 0;

        .amlocator-input{
          width: 100%;

          .select2-container{
            width: 100%;

            .select2-selection--single{
              position: relative;
              min-height: 38px;
              color: $black;
              font-size: 12px;
              text-align: left;
              border: none;
              border-radius: 0;
              text-transform: uppercase;
              height: auto;
              border-bottom: 1px solid $lightgrey;
              width:100%;
              padding: 10px 7px;

              span{
                color: $black;
                line-height: unset;
                padding-left: 0;
              }

              .select2-selection__arrow{

                &:before{
                  content: '\e900';
                  font-family: $icomoon-font-family;
                  font-size: 8px;
                  color: rgba($black, 0.7);
                }

                b{
                  display: none;
                }

              }

            }

            .select2-dropdown{
              border: none;
              border-radius: 0;
              @include box-shadow(3px 3px 13px 1px rgba($black, 0.1));

              .select2-results{

                .select2-results__options{
                  padding: 20px;

                  .select2-results__option{
                    font-family: $futura-heavy;
                    margin-bottom: 0;
                    padding: 5px;

                    &--highlighted, &[aria-selected=true]{
                      background-color: #F7F7F7;
                      color: $black;
                    }

                  }

                }

              }

            }

          }

          select{
            position: relative;
            color: $black;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid $lightgrey;
            font-size: 12px;
            width: 100%;
            padding: 7px;
            height: 40px;

            &:focus{
              box-shadow: none;
            }

          }

        }

      }

      .amlocator-current-location{

        .amlocator-wrapper{
          margin-top: 0;
        }

        .amlocator-text{
          height: 38px;
          text-transform: uppercase;
          color:rgba($black, 0.5);
          font-size: 12px;
          padding: 7px 10px;
          border-radius: 0;
          border:none;

          &::placeholder{
            text-transform: uppercase;
            color:rgba($black, 0.5);
            font-size: 12px;
          }

        }

        span{

          &.amlocator-search{
            background: none;
            transform: rotate(90deg);
            top: 8px;

            @media (max-width: $screen-max-sm){
              top: 10px;
            }

            &::after{
              content: '\e905';
              font-family: $icomoon-font-family;
              font-size: 13px;
              color: $black;
              font-weight: bold;
            }

          }

        }

      }

    }

    .amlocator-block{

      &.-storelist,
      &.-filter{
        z-index: 2;
        width: 100%;
      }

    }

    .-filter{

      @media (max-width: $screen-max-md){
        order: 2;
      }

      .amlocator-filters-container{
        background-color: $black;
        padding: 20px 32px 12px;
        color: $white;
        margin-bottom: 0;

        .amlocator-content{
          padding-bottom: 0;
          transition: none;
        }

        .amlocator-title{
          color: $white;
          padding: 0 5px;
          margin-bottom: 4px;
          position: relative;

          .amlocator-arrow{

            &::before,
            &::after{
              background-color: $white;
            }

          }

        }

        .amlocator-input{
          margin-bottom: 2px;

          label, input {
            cursor: pointer;
          }

          @media (max-width: $screen-max-md){
            margin-bottom: 10px;
            label{
              font-size: 16px;
            }
          }

        }

        .amlocator-attribute-wrapper{
          background-color: $black;
          color: $white;
          padding: 5px;

          input[type=checkbox]{
            border-color: $white;
            width: 12px;
            height: 12px;

            &::-ms-check {
              background-color: black;
            }

            &::after{
              background-color: $white;
              content: '\e910';
              font-family: $icomoon-font-family;
              font-weight: 700;
              color: white;
              font-size: 5px;
              width: auto;
              height: auto;
              background-color: transparent;
            }

          }

        }

      }

    }

    .-storelist{

      @media (max-width: $screen-max-md){
        &.amlocator-store-list{
          order: 4;
          max-height: 400px;
        }
      }

      .amlocator-stores-wrapper{
        margin: 0;
        height: 100%;
        width: 100%;
      }

      .amlocator-block{
        padding: 17px 17px 10px 17px;
        margin: 0;
        display: flex;
        justify-content: space-between;

        .am-marker{
          margin-left: 10px;
        }


      }

    }

    .amlocator-store-list{
      z-index: 2;
      background-color: #FBFBFB;
      .amlocator-wrapper {
        flex-direction: unset;
      }

      .amlocator-store-desc{
        position: relative;
        z-index: 2;
        margin-bottom: 0;
        overflow: initial;

        &:not(:first-of-type){
          margin-top: 0;
        }

        &:after{
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 50%;
          height: 1px;
          width: calc(100% - 30px);
          background-color: rgba(#979797, .09);
          @include transform(translateX(-50%));
        }

        &.-active{
          color: $black;
          background: initial;
        }

        .amlocator-block{

          .icon-pin{
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 40px;
            font-size: 26px;
            @include transform(translateX(-50%));

            &.store{
              color: $black;
            }

            &.cafe{
              color: #000;
            }

          }

          .amlocator-store-information{
            width: 80%;
            font-family: $futura-light;
font-weight:200;
            letter-spacing: .53px;

            .amlocator-title{
              font-size: 14px;
              font-family: $futura-heavy;
              font-weight: initial;
              text-transform: uppercase;
              margin-bottom: 0;
              letter-spacing: 0;
            }

            a{
              color: $black;
              &:hover{
                color: $black;
              }
            }

            .selector-fiche-store{
              color: $black;
              font-size: 12px;
              text-decoration: underline;
            }

            p{
              margin-bottom: 0;
              font-size: 12px;

              &.shop-type{
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 12px;
              }

            }
          }

        }

      }

    }

    .amlocator-map-container{
      margin-top: 0;
      width: 360px;
      height: 800px;
      max-height: 800px;


      @media (max-width: $screen-max-md){
        width: 100%;
        height: 1100px;
        max-height: 1100px;
      }

      @media (max-width: $screen-max-sm) {
        height: auto;
        max-height: none;
        -webkit-overflow-scrolling: touch;
      }
      .amlocator-block{

        &.-map{
          padding-left: 0;
          width: calc(100% - 360px);
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;

          @media (max-width: $screen-max-md){
            order: 3;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            height: auto;
          }

          .gm-style {

            .gm-style-iw-t{

              &::after{
                content: none;
              }

              .gm-style-iw-c{
                border-radius: 0;
                max-height: 190px!important;
                .gm-style-iw-d{
                  max-height: 185px!important;
                }
                .amlocator-info-popup{
                     @media (max-width: $screen-min-sm){
                        max-width: 100%;
                     }
                  .amlocator-title{
                    font-size: 14px;
                    font-family: $futura-heavy;
                    font-weight: initial;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    @media (max-width: $screen-min-sm){
                      font-size: 12px;
                    }
                  }

                  p{
                    font-family: $muli-light;
                    margin-bottom: 5px;
                    font-size: 12px;
                    @media (max-width: $screen-min-sm){
                      font-size: 11px;
                    }

                    &.shop-type{
                      font-family: $futura-heavy;
                      margin-bottom: 15px;
                    }

                  }

                }

                p, a{
                  font-size: 12px;
                  margin-bottom: 5px;
                  color: $black;
                  @media (max-width: $screen-min-sm){
                    font-size: 11px;
                  }
                }
                a{
                  &:hover{
                    color: $black;
                  }
                }

                .selector-links-container {
                  display: flex;
                  align-content: center;
                  justify-content: space-between;

                  margin-top: 10px;

                  a {
                    padding: 10px 0;

                    &:first-child {
                      padding: 10px 20px;
                      border: 1px solid $black;
                      background-color: $black;

                      transition: all 300ms ease;
                    }

                    &:hover:first-child {
                      color: $black;
                      background-color: transparent;
                    }
                  }

                  .selector-fiche-store{
                    color: white;
                    text-decoration: none;
                  }

                  .selector-itinerary-store{
                    color: black;
                    i {margin-left: 10px}
                  }
                }

              }

            }

          }

        }

      }

      .amlocator-map{

        @media (max-width: $screen-max-md){
          height: 300px;
        }

      }

    }

    /*PANNEAU SLIDE STORE*/
    .slide-content-store{
      padding: 0 100px 0 80px;
      display: flex;
      display: -webkit-flex;
      position: absolute;
      flex-direction: column;
      background-color: $white;
      max-height: 800px;
      height: 100%;
      overflow-y: scroll;
      z-index: 1;
      left:-100%;
      opacity: 0;
      @include transition(ease 0.5s all);
      width: 48%;
      border-top: 1px solid $lightgrey;

      @media (min-width: $screen-min-sm) and (max-width: $screen-min-pad){
        width: 55%;
      }

      @media (max-width: $screen-max-md){
        width: 100%;
        padding: 0;
        -webkit-overflow-scrolling: touch;
      }

      &.open{
        left: 360px;
        opacity: 1;

        @media (max-width: $screen-max-md){
          position: fixed;
          z-index: 4;
          left: 0;
          width: 100vw;
          top: 67px;
          height: calc(100% - 67px);
          bottom: 0;
          background-color: #FFF;
          overflow: scroll;
          max-height: 100%;
        }

      }

      .encart-store{
        display: none;

        @media (max-width: $screen-max-md){
          height: 100vh;
        }

        .picture-store{
          background-repeat: no-repeat;
          background-position: center;
          -webkit-background-size: cover;
          background-size: cover;
          width: 100%;
          height: 360px;
          margin-top: 16px;

          @media (max-width: $screen-max-md){
            margin-top: 0;
            height: auto;
            @include aspect-ratio(318, 226);
          }

        }

        .icon-cross{
          position: absolute;
          top: 13px;
          right: 20px;
          cursor: pointer;
          font-size: 20px;
          font-weight: initial;

          @media (max-width: $screen-max-md){
            color: #FFF;
            top: 0;
            background: rgba(0,0,0,.4);
            right: 0;
            padding: 10px;
            border-radius: 3px;
          }

        }

        .amlocator-title{
          font-size: 12px;
          font-family: $futura-heavy;
          margin-top: 0;
        }

        .amlocator-titles-block {
          padding: 20px 0 0;

          @media (max-width: $screen-max-md){
            padding: 16px 22px;
          }
          .amlocator-name{
            font-size: 17px;
            font-family: $futura-heavy;
            font-weight: initial;
            text-transform: uppercase;
            margin-bottom: 4px;
            line-height: 1;

            @media (max-width: $screen-max-md){
              margin-bottom: 2px;
            }
          }
          .shop-type{
            margin-bottom: 10px;

            @media (max-width: $screen-max-sm){
              margin-bottom: 2px;
            }

          }
        }

        .item-store-descr{
          padding: 20px 0;
          border-bottom: 1px solid $lightgrey;

          &:last-child {
            border-bottom: none;
            padding: 20px 0 0 0;
          }

          @media (max-width: $screen-max-md){
            padding: 20px 22px;
              &:last-child {
                  padding: 20px 22px;
              }
          }

          &.short{
            .amlocator-title{
              margin-bottom: 10px;
            }
            p{
              margin-bottom: 0;
            }
          }


          &.infos-store{
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            width: 100%;

            @media (max-width: $screen-max-md){
              padding: 16px 22px;
            }

            @media (max-width: $screen-max-sm){
              display: block;
            }

            .complement-info-store{
              width: 50%;
              line-height: 1;
              display: flex;
              flex-direction: column;

              @media (max-width: $screen-max-sm){
                width: 100%;
              }

              .amlocator-title{
                font-size: 12px;
                font-family: $futura-heavy;
                margin-top: 0;
                margin-bottom: 10px;
              }

              .amlocator-schedule-container{
                font-family: $muli-light;
                .amlocator-today{
                  background-color: $white;
                  padding: 0 20px 0 0;
                  font-size: 12px;
                  margin-bottom: 30px;
                  cursor: pointer;

                  strong{
                    font-weight: initial;
                    margin-right: 5px;
                  }

                  &::before{
                    content: none;
                  }

                }

                .amlocator-week {
                  margin: 0;
                  font-size: 12px;

                  .amlocator-schedule-table{
                      .amlocator-cell{
                        padding:0;

                      }
                  }
                }

              }

              a{
                color: $black;
                text-decoration: underline;
                margin-bottom: 5px;
                font-size: 12px;
                font-family: $muli-light;
              }
            }

            .info-principal{
              width: 50%;

              @media (max-width: $screen-max-sm){
                width: 100%;
                margin-bottom: 15px;
              }
              .amlocator-title{
                font-size: 12px;
                font-family: $futura-heavy;
                margin-top: 0;
                margin-bottom: 10px;
              }

              p{
                font-family: $muli-light;
                margin-bottom: 5px;
                font-size: 12px;
              }

              a{
                color: $black;

                &.selector-itinerary-store {
                  display: inline-block;
                  color: $black;
                  margin-top: 10px;
                  transition: all 100ms ease;

                  &:hover {color: black}
                }
              }
            }

          }

          .shipping-container-block{
            color: $black;
            display: flex;
            display: -webkit-flex;
            align-items: center;

            i{
              font-size: 20px;
            }

            p{
              margin-bottom: 0;
              margin-left: 10px;
              font-size: 13px;
            }

          }

        }

      }

    }

  }

  .select2-dropdown{
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    @include box-shadow(3px 3px 13px 1px rgba($black, 0.1));

    .select2-results{

      .select2-results__options{
        padding: 10px;
        max-height: 260px;
        .select2-results__option{
          margin-bottom: 0;
          padding: 7px;


          &--highlighted, &[aria-selected=true]{
            background-color: #F7F7F7;
            color: $black;
          }

        }

      }

    }

  }

}

.amlocator-main-container{
    .simplebar-track {
        &.simplebar-vertical {
            right: 10px;
            top: 20px;
            bottom: 20px;
            width: 5px;
            background-color: #e3e3e3;
        }
    }
}
.simplebar-track {
    &.simplebar-vertical {
        right: 10px;
        top: 20px;
        bottom: 20px;
        width: 5px;
        background-color: #e3e3e3;

        .simplebar-scrollbar {
            position: absolute;
            right: 2px;
            width: 7px;
            min-height: 10px;

            &.simplebar-visible {
                &:before {
                    opacity: 1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}
