.amgiftcard_info{

  .error{
    color: $pink;
  }

}

.amgiftcard{

  &-form {margin-bottom: 40px}

  .fieldset{
    width: 100%;
  }

  .amgiftcard-info{
    position: relative;
    padding: 20px;
    border: 1px solid #979797;
    margin-bottom: 15px;

    p{
      margin-bottom: 0;
    }

  }

  .actions{
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 10px;
    justify-content: flex-end;
    @include transform(translateY(-50%));
    .amcard-delete{
        align-self: center;
        &:hover{
            text-decoration: none;
        }
    }
    a, i{
      color: $black;
    }
  }
}

.gift-payment{
  padding-right: 15px;

  .payment-option-title{
    border: none;
  }

  .primary{

    .btn {

      @media (max-width: $screen-max-sm) {
        width: 100%;
      }

    }

  }

}

.amcard-field-container.field .label {
  display: flex;
  position: static;
}

.amcard-form-container .amcard-title {
  margin: 0 0 10px;
  font-weight: normal;
}

.amcard-card-info {
  flex-direction: column;

  .amcard-field { display: flex }
}

.amcard-form-container {
    .select2-container{
        .select2-selection--single{
            height: auto;
        }
    }
  .amcard-field-block {
    textarea {
      &.amcard-field {
        @include border-radius(0);
        border-color: $black;

        &::placeholder {
          font-size: 14px;
          color: $lightgrey-darker;
        }

        &:focus {
          @include box-shadow(none);
        }
      }
    }
  }
}
