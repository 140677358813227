* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

}

$maxColWidth: 1440px;
%ph-col {
    max-width: $maxColWidth;
    margin: 0 auto;
}

.select2-dropdown{
    z-index: 10!important;
}

a:visited, .alink:visited {
    color: inherit;
    text-decoration: none;
}

.cms-page-view {

    iframe {
        max-width: 100%;
    }

    .page-main {
        max-width: unset;
        padding: 0;
        margin-top: 0;
    }

    .page-title-wrapper {
        margin-top: 40px;
    }

    .page-title {
        margin-bottom: 0;
        @extend .text-uppercase;
        padding: 0;
        margin-top: 15px;
        text-align: center;
        position: relative;
        width: 100%;
        font-weight: initial;
        font-size: 25px;
        box-sizing: border-box;

        @media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
            margin-top: 35px;
        }

        @media (max-width: $screen-max-sm) {
            margin-top: 35px;
        }

        &.title {
            strong {
                font-size: 28px;
                font-family: $futura;
                font-weight: unset;
            }
        }
    }

}

body {
    font-family: $futura-light;
    font-weight: 200;
    color: $black;
    background-repeat: no-repeat;
    background-position: center 20px;
    background-size: 1440px;

    &._has-modal {
        position: fixed;

        &.checkout-index-index {
            .select2-container {
                z-index: 902 !important;
            }
        }
    }

    &.has-minicart-open {
        overflow: hidden;

        .page-header {
            z-index: 800;
        }

        &:after {
            content: '';
            position: fixed;
            top: 0;
            background-color: rgba(0, 0, 0, .4);
            z-index: 799;
            height: 100%;
            width: 100%;
            right: 0;
        }
    }

    .action-primary {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .action.primary {
        font-family: $futura-light;
        font-weight: 200;
    }

    .modal-popup.confirm {
        .modal-inner-wrap {
            box-shadow: none;
            text-align: center;
            text-transform: uppercase;
            position: relative;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .action-close {
                cursor: pointer;
                @include transition(all ease 1s);
                transform-origin: 50% 45%;
                z-index: 1;

                &::before {
                    content: $icon-cross;
                    font-family: $icomoon-font-family;
                }

                &:hover {
                    transform: rotate(360deg);
                }
            }

            .modal-content {
                padding: 0 15px;
            }

            .modal-header {
                h1 {
                    text-transform: uppercase;
                    font-weight: initial;

                }
            }


            .modal-footer {
                padding: 20px 15px;

                button {
                    width: 100px;
                    @extend .btn-black;
                    @extend .btn;
                    padding: 10px;
                }
            }
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.d-only {

    @media (max-width: $screen-max-md) {
        display: none;
    }

}

.t-only {
    display: none;

    @media (max-width: $screen-max-md) {
        display: block;
    }

}

.m-only {
    display: none;

    @media (max-width: $screen-max-sm) {
        display: block;
    }

}

.page-wrapper {
    @media (max-width: $screen-max-md) {
        padding-top: 67px;
    }
}

.fieldset {
    letter-spacing: 0;
}

.classic-legend {
    clip: unset;
    margin: 0;
    height: auto;
    width: auto;
    position: relative;
}

dt {
    font-weight: 300;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    box-shadow: 0 1px 0 0 #000;
    transition: all 5000s ease-in-out 0s;
}

a,
.alink {
    color: #000;

    &:hover {
        color: #000;
        text-decoration: none;
    }

}

// Refonte - Nouveau container -- 100% + 30px padding --
.container-ph {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: $screen-max-sm) {
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: $screen-min-sm) {
        max-width: 720px;
    }

    @media (min-width: $screen-min-md) {
        max-width: 960px;
    }

    @media (min-width: $screen-min-lg) {
        max-width: 1140px;
    }

}

.container-fluid-ph {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row-ph {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    //margin-right: -15px;
    //margin-left: -15px;

    @media (max-width: $screen-max-md) {
        margin: 0;
    }
}

.d-flex {
    display: flex;
    display: -webkit-flex;
}

.text-uppercase {
    text-transform: uppercase;
}

.fwb {
    font-weight: bold;
}

.select2-change {
    &.select {
        position: relative;
        color: $black;
        text-transform: uppercase;
        border: none;
        border-bottom: 1px solid $lightgrey;
        font-size: 12px;
        width: 100%;
        padding: 7px;
        height: 40px;

        &:focus {
            box-shadow: none;
        }

    }

}

.select2-container, .select2-dropdown, .select2-search, .select2-results {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.select2-container {
    width: 100% !important;
    .select2-selection--single {
        position: relative;
        color: $black;
        font-size: 12px;
        text-align: left;
        text-transform: uppercase;
        height: auto;
        border: 1px solid #979797;
        border-radius: 0 !important;
        width: 100%;
        padding: 7px;

        span {
            color: $black;
        }

        .select2-selection__arrow {
            top: unset !important;
            height: unset !important;
            bottom: 10px;

            &:before {
                content: '\e900';
                font-family: $icomoon-font-family;
                font-size: 8px;
                color: rgba($black, 0.7);
            }

            b {
                display: none;
            }

        }

    }

    .select2-dropdown {
        border: none;
        border-radius: 0;
        @include box-shadow(3px 3px 13px 1px rgba($black, 0.1));

        .select2-results {

            .select2-results__options {
                padding: 20px;

                .select2-results__option {
                    font-family: $futura-heavy;
                    margin-bottom: 0;
                    padding: 5px;

                    &--highlighted, &[aria-selected=true] {
                        background-color: #F7F7F7;
                        color: $black;
                    }

                }

            }

        }

    }

}


.btn {
    border-radius: 22px !important;
    padding: 8px 14px !important;
    min-height: auto !important;
    &.btn-black {
        &:hover {
            background-color: $white;
            border-color: $black;
            color: $black;
        }
    }
}


.cta {
    font-size: 12px;
    color: $black;
    border: 1px solid $black;
    padding: 8px 14px !important;
    text-transform: uppercase;
    background-color: $white;
    text-align: center;
    @include transition(all ease 0.4s);
    border-radius: 22px !important;

    &.black {
        &:visited {
            color: $white;

            &:hover {
                color: $black;
            }
        }

        color: #FFF;
        background-color: #000;

        &:hover {
            color: #000;
            background-color: #FFF;
        }

    }

    &.white {
        &:visited {
            color: $black;

            &:hover {
                color: $white;
            }
        }

        border: 1px solid $white;

        &:hover {
            background-color: transparent;
            color: $white;
        }

    }

    &:hover {
        text-decoration: none;
        background-color: $black;
        color: $white;
    }

}

.page-header {

    @media (max-width: $screen-max-md) {
        margin-bottom: 0;
    }

    @media (max-width: $screen-max-sm) {
        height: 67px;
    }

}

main {
    margin-top: 50px;
    @media (max-width: $screen-max-md) {
        margin-top: 0;
    }
}

/*MAIN FOR PRODUCT*/
.catalog-product-view {
    main {
        margin-top: 0;

        @media (max-width: $screen-max-sm) {
            margin-top: 0;
        }
    }

}

.header {
    &.content {
        max-width: unset;

        @media (min-width: $screen-min-md){
            padding: 15px 20px 0;
        }

        @media (min-width: $screen-min-sm) and (max-width: $screen-max-md){
            padding: 15px;
        }

    }
}

.title-trait {
    padding: 25px 15px 20px;
    margin-top: 15px;
    text-align: center;
    position: relative;
    width: 100%;
    font-weight: initial;
    font-size: 25px;
    box-sizing: border-box;

    @media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
        padding: 40px 0px 0;
    }

    &.title {
        strong {
            font-size: 28px;
            font-family: $futura-light;
            font-weight: 200;
            font-weight: unset;
        }
    }

    &:before {
        content: none !important;
        // TODO : Si valider supprimer cette partie
        // content: '';
        position: absolute;
        width: 1px;
        height: 50px;
        background-color: #C72C48;
        left: 50%;
        top: 0;
        transform: translateY(-50%);

        @media (max-width: $screen-max-sm) {
            height: 30px;
        }

    }

    &-left {
        padding: 40px 20px 20px 0px;
        text-align: left;

        &:before {
            left: 0;
        }

        @media (max-width: $screen-max-md) {
            text-align: center;
            padding: 30px 0;

            &:before {
                left: 50%;
            }

        }

    }

}

button:not(.primary) {
    box-shadow: unset;
    font-weight: 400;
}

.black-button, .black-button:active, .black-button:focus {
    background-color: $black;
    color: $white;
    border: 1px solid $black;
    padding: 15px 40px;
    transition: all .3s;
    border-radius: 0;
    font-size: 13px;
    text-decoration: none;
    text-align: center;
    font-family: $futura-light;
    font-weight: 200;

    &:hover {
        color: $black;
        background-color: $white;
        border: 1px solid $black;
        transition: all .3s;
        text-decoration: none;
    }

    &:visited {
        color: $white;

        &:hover {
            color: $black;
            background-color: $white;
            border: 1px solid $black;
            transition: all .3s;
            text-decoration: none;
        }
    }
}

.white-button, .white-button:active, .white-button:focus {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    padding: 15px 40px;
    transition: all .3s;
    border-radius: 0;
    font-size: 13px;
    text-decoration: none;
    text-align: center;

    &-left {
        padding: 40px 20px 20px 0;
        text-align: left;

        &:before {
            left: 0;
        }
    }

    &:visited {
        color: $black;

        &:hover {
            color: $white;
            background-color: $black;
            border: 1px solid $black;
            transition: all .3s;
            text-decoration: none;
        }
    }

    &:hover {
        color: $white;
        background-color: $black;
        border: 1px solid $black;
        transition: all .3s;
        text-decoration: none;
    }
}

.disabled-button, .disabled-button:active, .disabled-button:focus {
    color: #ccc;
    border: 1px solid $lightgrey;
    background: transparent;
    pointer-events: none;

    &:visited {
        color: inherit;

        &:hover {
            color: #ccc;
            border: 1px solid $lightgrey;
            background: transparent;
        }
    }

    &:hover {
        color: #ccc;
        border: 1px solid $lightgrey;
        background: transparent;
    }
}

.justify-content-between {
    justify-content: space-between;
}

.flex-column {
    flex-direction: column;
}

form {

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid $black;
        padding: 0;
        position: relative;
        border-radius: 0;
        background: transparent;
        cursor: pointer;

        @media (max-width: $screen-max-sm) {
            width: 15px;
            height: 15px;
            vertical-align: top;
        }

        &::after {
            content: '';
            position: absolute;
            width: 9px;
            height: 9px;
            top: 2px;
            left: 2px;
            background-color: $black;
            opacity: 0;
            @include transition(ease 0.4s all);

        }

        &:checked {

            &::after {
                opacity: 1;
            }

        }

    }

}

.owl-stage-outer {
    overflow: hidden;
    width: 100%;
}

.owl-loaded,
.owl-carousel {

    button {

        &.owl-dot {
            position: relative;
            width: 14px;
            height: 14px;
            border: 1px solid $lightgrey;
            padding: 0;
            background: $white;
            box-shadow: none;
            @include border-radius(50%);
            margin-top: 20px;

            &:not(:last-child) {
                margin-right: 5px;
            }

        }

    }

}

.owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
    min-height: 40px;

    button {
        @include transition(all ease 0.4s);

        &.owl-dot {
            border: 1px solid $lightgrey-darker;
        }

        &:hover {
            border-color: rgba($black, 0.4);
        }

        &:before {
            content: '';
            width: 8px;
            height: 8px;
            @include border-radius(50%);
            background-color: $black;
            position: absolute;
            top: 2px;
            left: 2px;
            @include transition(all ease 0.4s);
            opacity: 0;
        }

        &.active {

            &:before {
                opacity: 1;
            }

        }

    }

}

.owl-dots,
.owl-nav {

    &.disabled {
        display: none;
        opacity: 0;
    }

}

.amform-body .ui-datepicker,
.ui-datepicker {
    z-index: 15 !important;
    background: $white;
    padding: 15px;
    margin-top: 10px;
    border: #E1E1E1 1px solid;
    box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.35);
    transition: all .3s;
    border-radius: 0;

    .ui-datepicker-prev {
        left: 2px;
    }

    .ui-datepicker-next {
        right: 2px;
    }

    .ui-datepicker-title {
        font-weight: bold;
        text-align: center;
        text-transform: none;

        .ui-datepicker-month, .ui-datepicker-year {
            width: unset;
            margin: unset;
        }
    }

    .ui-datepicker-next span {
        border-color: transparent transparent transparent #000;
    }

    .ui-datepicker-prev span {
        border-color: transparent #000 transparent transparent;
    }

    .ui-datepicker-title .ui-datepicker-year {
        border-bottom: 1px solid #e2e2e2;
    }

    .ui-datepicker-calendar {
        border: unset;

        th {
            text-align: center;
            border: unset;
            background: unset;
            padding: unset;
            color: #6b6b6b;
            font-size: 11px;
            font-weight: normal;
        }

        td {
            border: none;
            padding: 4px;
            transition: all .3s;

            a {
                text-align: center;
                transition: all .3s;
                width: auto;
                height: auto;
                padding: 8px;
                line-height: 1;
                font-family: $futura-heavy;

                &:hover {
                    color: $black;
                    background: none;
                }
            }

            .ui-state-hover {
                background: none;
                color: #979797;
                transition: all .3s;
            }
        }

        .ui-state-disabled {
            background: none;

            span {
                text-align: center;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 50%;
                    background-color: #959595;
                    position: absolute;
                    left: 30%;
                    top: 50%;
                    transform: skew(-40deg, 0deg) translate(-50%, -50%);
                }
            }
        }

        .ui-state-hover {
            background: none;
        }

        .ui-datepicker-today {
            background: none;
            transition: all .3s;

            a {
                color: $black;
                opacity: 0.7;
                transition: all .3s;

                &.ui-state-highlight {
                    background: none;
                }
            }
        }

        td a.ui-state-active {
            background: $black;
            transition: all .3s;
            color: $white;
            border-radius: 0;

            &:hover {
                background: $black;
                transition: all .3s;
            }
        }

        .ui-state-hover {
            color: $white;
            background: $black;
            transition: all .3s;
        }
    }
}

.message.info {
    background: $white;
    border: 1px solid $black;
    color: black;

    & > *:first-child:before {
        content: none;
    }
}

.page-title-type {
    width: 100%;
    padding: 0;

    .page-title {
        display: block;
        margin: 0 auto;
        font-family: $futura-light;
        font-weight: 200;
        font-size: 22px;
        color: #000;
        text-transform: uppercase;
        text-align: center;
    }

}

.toolbar {
    font-family: $futura-light;
    font-weight: 200;

    .pager {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pages {
            position: initial;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 0;

            .item {
                a {
                    color: $black;

                    &.action {
                        background: none;
                        border: none;
                        box-shadow: none;
                    }
                }

                &.current {
                    strong {
                        color: $black;
                        background: none;
                    }

                }

                &:hover {
                    a {
                        color: $black;
                    }
                }

            }
        }

        .limiter {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .limiter-label {
                margin-right: 20px;
                @media (max-width: $screen-max-md) {
                    margin: 0;
                }
            }

            select {
                border: 1px solid $grey;
                background: none;
                box-shadow: none;
                border-radius: 0;

            }

            .select2-container {
                .select2-selection--single {
                    border-radius: 0;

                    .select2-selection__rendered {
                        padding-left: 0;
                        line-height: 24px;
                    }
                }
            }
        }
    }

}


.toolbar {
    .sorter-action {
        &.sort-desc {
            transform: rotateX(190deg);
        }

        &:before {
            content: $icon-fleche-ph;
            font-family: icomoon;
            font-size: 20px;
            color: rgba(0, 0, 0, .7);
        }

        &:hover {
            &:before {
                color: $black;
            }
        }
    }
}


div[role="alert"] {
    .close-toast {
        display: none;
    }
}

div[data-role="checkout-messages"].toast-notif,
div[role="alert"].toast-notif,
div[role="alert"] .toast-notif-add-wishlist {
    opacity: 0;
    z-index: 20;
    position: relative;
    @include transition(all ease .1s);

    &.show {
        opacity: 1;
    }

    > .message {
        &:not(.notice) {
            background-color: rgba(49, 50, 54, 0.7);
            position: fixed;
            max-width: 520px;
            min-width: 520px;
            bottom: 30px;
            left: 30px;
            padding: 10px;
            z-index: 20;
            font-size: 14px;
            color: $white;

            .close-toast {
                content: $icon-cross;
                display: block;
                font-size: 12px;
                right: 15px;
                position: absolute;
                color: $white;
                font-family: $icomoon-font-family;
                font-weight: bold;
                top: 15px;
                cursor: pointer;

                i {
                    right: 0;
                    top: 0;

                    &:before {
                        font-size: 14px;
                    }
                }
            }

            .state-icon {
                position: relative;
                padding: 10px 40px 10px;

                &::before {
                    left: 0;
                    position: absolute;
                }
            }

            &.error, &.warning {
                .state-icon {
                    &::before {
                        font-size: 12px;
                        content: $icon-cross;
                        color: $black;
                        font-family: $icomoon-font-family;
                    }
                }
            }

            &.success {
                .state-icon {
                    &::before {
                        font-size: 12px;
                        content: $icon-check;
                        color: $valid-color;
                        font-family: $icomoon-font-family;
                    }
                }
            }
        }
    }
}

div.toastErrorNoDeliveryDate {
    background-color: rgba(49, 50, 54, 0.7);
    position: fixed;
    max-width: 520px;
    min-width: 520px;
    bottom: 30px;
    left: 30px;
    padding: 10px;
    z-index: 20;
    font-size: 14px;
    color: $white;

    &::before {
        font-size: 12px;
        content: $icon-cross;
        color: $black;
        font-family: $icomoon-font-family;
        margin-right: 10px;
    }
}

div[data-role="checkout-messages"].toast-notif {
    opacity: 1;
}

div[role="alert"] .toast-notif-add-wishlist {
    padding: 0;

    .close-toast {
        height: 100%;

        i {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;

            &:before {
                font-size: 16px;
            }
        }
    }

    .message {
        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        &.success {
            div {
                &:before {
                    top: 50%;
                    margin: 0;
                    @include transform(translateY(-50%));
                }
            }

        }
    }

}

/*.catalog-product-view{
   div[role="alert"]{
     display: none;
   }
 }*/

.qty-controllers {

    div {

        &:not(.mage-error) {
            @include border-radius(50%);
            width: 16px;
            height: 16px;
            border: 1px solid $black;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $screen-max-sm) {
                width: 21px;
                height: 20px;
            }
        }

        &:hover {
            @include transition(ease all 0.4s);

            a {
                color: $white;
            }

            background-color: $black;
        }

        a {
            color: $black;
            font-size: 12px;
            line-height: 1;
            font-family: $futura-heavy;
            text-align: center;
            width: 12px;
            height: 12px;

            &.moins-qty {
                width: 100%;
                height: 100%;
                position: relative;
                @media (max-width: $screen-max-sm) {
                    width: 15px;
                    height: 15px;
                }

                &::before {
                    position: absolute;
                    top: -5px;
                    left: 4px;
                    content: '_';
                    @media (max-width: $screen-max-sm) {
                        top: -4px;
                    }
                }
            }

            &.plus-qty {
                width: 100%;
                height: 100%;
                position: relative;
                @media (max-width: $screen-max-sm) {
                    width: 15px;
                    height: 15px;
                }

                &::before {
                    position: absolute;
                    top: 1px;
                    left: 2px;
                    content: '+';

                }
            }

            &:hover {
                text-decoration: none;
            }

        }

    }

}

// For extends
.summary-title {

    .title {
        font-family: $futura-heavy;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: .49px;
        line-height: 1;
        margin: 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #979797;
    }

}

// For extends
.summary-sidebar-container {
    width: 30%;
    font-size: 14px;
    padding: 30px 20px 15px;
    background-color: #f5f5f5;

    @media (max-width: $screen-max-sm) {
        width: 100%;
        padding-left: 0;
    }

}

// For extends
.summary-central-column {
    width: 70%;
    font-size: 14px;
    padding-right: 15px;

    @media (max-width: $screen-max-sm) {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

}

// For extends
.summary-table-elements {

    .table-totals,
    .totals {
        border-top: 0;

        tr {

            &:last-child {

                .mark,
                .sub,
                .amount {
                    border-bottom: 0;
                }

            }

        }

        tbody {

            .mark,
            .sub,
            .amount {
                padding: 14px 0;
                border-top: none;
                border-bottom: 1px solid #979797;

                strong {
                    font-weight: 400;
                }
            }

            .mark {
                width: 40%;
            }

            .shipping,
            .sub {
                width: 60%;
            }

            tr {

                &:not(:last-child) {

                    & > td {

                        &:last-child {
                            border-bottom: 1px solid #979797;
                        }

                    }

                }

            }

        }

        &.grand {

            .mark {
                text-transform: uppercase;
            }

            .amount,
            .mark,
            .sub {
                font-family: $futura-heavy;
                font-size: 14px;
                padding: 58px 0 15px;
            }

            .amount {
                font-size: 18px;
                line-height: 1;
            }

        }

    }

}

// For extend
.classic-address-box {
    position: relative;
    width: 100%;
    padding: 14px 35px 8px 50px;
    margin-bottom: 15px;
    border: 1px solid rgba(#000, .25);

    @media (max-width: $screen-max-sm) {
        column-count: 1;
        line-height: 1.45;
    }

    a {
        color: #000;
    }

}

//bandeau cookie
.message {
    &.global {
        &.cookie {
            background-color: $black;
            color: $white;
            font-weight: 700;
            font-size: 14px;
            width: 90%;
            margin: auto;
            padding: 15px;
            z-index: 1000;
            border-top: 1px solid $white;
            @media (max-width: $screen-max-md) {
                font-size: 13px;
                width: 100%;
            }

            p {
                padding: 10px;
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: space-around;
                @media (max-width: $screen-max-md) {
                    flex-direction: column;
                    text-align: center;
                }

                a {
                    color: $white;
                    text-decoration: underline;
                }

                .actions {
                    margin-top: 0;

                    .btn {
                        min-height: auto;
                        padding: 14px;
                    }

                    @media (max-width: $screen-max-md) {
                        margin-top: 15px;
                    }

                }
            }
        }
    }

    //Fix bug menu mobile margin

    @media(max-width: 767px) {
        .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
            margin-left: 0;
        }
    }

    // Hide 5.5% and 20% tax everywhere
    .tax.details.details-1 {
        display: none;
    }
}

//Prevent cross inputs Edge
input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

.button-cta-widget {
    text-align: center;

    .btn-white:hover {
        background-color: $black;
        color: $white;
    }
}
