$max-width: 1185px;
$max-height: 631px;
$nav-height: 100px;

.catalog-product-view.fotorama__fullscreen {
    .fotorama--fullscreen {
        margin: auto !important;
        max-width: $max-width !important;
        max-height: $max-height !important;

        @media (max-width: $screen-max-xs) {
            max-height: 100% !important;
        }

        .fotorama__wrap {
            max-width: $max-width !important;
            max-height: $max-height !important;
            display: flex;
            flex-direction: row-reverse;

            @media (max-width: $screen-max-xs) {
                max-height: 100% !important;
                height: 100%;
            }

            .fotorama__stage {
                max-width: $max-width !important;
                min-height: calc(#{$max-height} - #{$nav-height}) !important;
                margin-left: 0 !important;
                border: none;

                @media (max-width: $screen-max-xs) {
                    max-height: calc(100% - #{$nav-height}) !important;
                    margin: 0;
                }

                .fotorama__fullscreen-icon {
                    opacity: 1 !important;
                    transform: translate3d(0, 0, 0) !important;

                    &:after {
                        box-shadow: none;
                    }
                }

                .fotorama__arr {
                    transform: translate3d(0, 0, 0) !important;
                    opacity: 1 !important;
                    display: block !important;
                    @media (max-width: $screen-max-xs) {
                        display: none !important;
                    }
                }

                .fotorama__zoom-in {
                    display: block !important;
                    bottom: 30px!important;
                    top: unset !important;
                    right: 0;
                    @media (max-width: $screen-max-xs) {
                        display: none !important;
                    }
                }
                .fotorama__zoom-out {
                    display: block !important;
                    bottom: 0 !important;
                    top: unset !important;
                    right: 0;
                    height: 41px;
                    @media (max-width: $screen-max-xs) {
                        display: none !important;
                    }
                }
                .fotorama__stage__shaft {
                    max-height: calc(100% - 40px);
                    margin-top: 40px;

                    @media (max-width: $screen-max-xs) {
                        max-height: 100%;
                        margin-top: 0;
                    }
                }
            }

            .fotorama__nav-wrap {
                max-width: 100px !important;
                max-height: 100% !important;

                @media (max-width: $screen-max-xs) {
                    display: flex !important;
                    align-items: center !important;
                    max-width: 50px !important;
                }

                .fotorama__nav {
                    display: flex !important;
                    align-items: center !important;
                    height: 100% !important;

                    .fotorama__nav__frame {
                        margin: 0 0 20px 20px;
                    }
                    .fotorama__thumb-border {
                        display: none;
                    }

                    .fotorama__nav__shaft {
                        display: flex;
                        flex-direction: column;
                    }

                    @media (max-width: $screen-max-xs) {
                        justify-content: center;

                        .fotorama__nav__frame {
                            margin: 15px;
                            text-align: center;
                            height: auto;
                            aspect-ratio: 1 / 1;

                            .fotorama__dot {
                                left: auto;
                                top: auto;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    .modals-wrapper {
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #B2B2B2;
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
    }
}

html:not(.fotorama__fullscreen) {
    .fotorama__stage__shaft {
        .fotorama__stage__frame {
            aspect-ratio: 1 / 1 !important;
            height: auto !important;
            max-height: 100% !important;
            img{
                object-fit: contain;
            }
        }
    }
}
.fotorama__stage{
    max-height: 550px;
}
.video-thumb-icon:after{
    display : inline-block;
    height : 0;
    width : 0;
    border-top : 9px solid transparent;
    border-bottom : 9px solid transparent;
    border-left : 17px solid #eee8e8;
    position: absolute;
    top: 50%;
    margin: 0;
    left: 53%;
    transform: translate(-50%, -50%);
}
.video-thumb-icon:before{
    content : "";
    border-radius: 50%;
    width : 37px;
    height : 37px;
    border : 1px solid #eee8e8;
    position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);
    background: rgb(76 74 74 / 8%);
    z-index: 2;
}

.fotorama-video-container:after {
    cursor: pointer;
    transition: transform .3s ease;
}

.product-video {
    cursor: initial;
}
