#product_addtocart_form {
    .product-options-wrapper {
        .field:not(.configurable) {
            display: none;
        }
    }

    .product-options-bottom {
        .ph-customizable-macaron-box {
            width: 100%;
            button[type="submit"] {
                display: none;
            }
        }
    }
}

#macaron-modal-open {
    display: block;
    margin-bottom: 50px;
    color: $white;
    text-transform: uppercase;
    opacity: 1;
    @include transition(ease 0.4s all);
    background-color: $black;
    font-size: 13px;
    border-radius: 0;
    font-family: $futura-light;
    font-weight:200;
    width: 100%;
    margin-top: 10px;
    padding: 17px;
    letter-spacing: .46px;
    border: 1px solid transparent;

    &.disable {
        opacity: .5;
        pointer-events: none;
    }

    &:hover {
        background-color: $white;
        border: 1px solid $black;
        color: $black;
        opacity: 1;
    }

    @media (max-width: $screen-max-md) {
        position: fixed;
        border: none;
        z-index: 10;
        bottom: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 60px;
    }
}

.macaron-modal, .macaron-modal-avertissement {
    // Reset
    p, h1, h2, h3, h4 {
        margin: 0;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 13;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s .2s ease-in-out;

    & > .overlay {
        position: absolute;
        z-index: 0;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 90%;
        max-width: 1700px;
        height: auto;
        max-height: 90vh;
        padding: 40px 30px 0 30px;
        background-color: #fff;
        transform: translateX(-100vw);
        transition: transform .2s ease-in-out;
        overflow: hidden;
        @media (max-height: 600px) {
            width: 100%;
            max-height: 100vh;
            min-height: 500px;
            overflow: scroll;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }

        @media (max-width: $screen-max-md) {
            width: 100%;
            max-height: 100vh;
            min-height: 500px;
            overflow: scroll;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }

        @media (max-width: 375px) {
            padding: 40px 15px 0 15px;
        }

        &__top {
            text-align: center;
            width: 100%;
            margin-bottom: 30px;

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;

                .macaron-modal-title {
                    text-transform: uppercase;
                    font-family: $futura-heavy;
                    margin-bottom: 0px;
                    font-size: 30px;
                    position: relative;
                }
            }

            .change-size-btn {
                text-decoration: underline;
                font-size: 22px;
                color: $pink;
                font-family: $futura;
            }

            @media (max-width: $screen-max-sm) {

                .title {
                    margin-bottom: 15px;
                    .macaron-modal-title {
                        font-size: 23px;
                    }
                }

                .change-size-btn {
                    font-size: 15px;
                }
            }

            @media (max-width: $screen-max-xs) {
                .title {
                    .macaron-modal-title {
                        max-width: 300px;

                        .reset-size-btn {
                            right: 0px;
                            font-size: 18px;
                        }
                    }
                }
            }

            @media (max-width: 375px) {
                text-align: left;
            }
        }

        // Modal submit btn
        .macaron-submit {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 380px;
            width: 100%;
            min-height: 65px;
            color: $white;
            pointer-events: none;
            opacity: .3;
            font-size: 15px;
            transition: opacity .2s ease-in-out, color .2s ease-in-out, background .2s ease-in-out;
            margin-bottom: 40px;
            @media (max-width: $screen-max-sm) {
                min-height: 55px;
                font-size: 14px;
            }

            &:hover {
                color: $black;
            }
        }

        &__macarons {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-bottom: 30px;

            // Macaron list
            .macarons-list { // <ul>
                list-style: none;
                display: grid;
                padding: 0;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: auto;
                grid-column-gap: 10px;
                grid-row-gap: 30px;

                @media (max-width: $screen-max-pad) {
                    grid-template-columns: repeat(4, 1fr);
                }

                @media (max-width: $screen-max-sm) {
                    grid-template-columns: repeat(2, 1fr);
                }

                // Single macaron
                &__item { // <li>
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    // Single macaron content
                    &-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 20px;
                        opacity: 1;
                        @media (max-width: $screen-max-sm) {
                            margin-bottom: 20px;
                        }

                        & > img {
                            max-width: 140px;
                            @media (max-width: $screen-max-pad) {
                                max-width: 100px;
                            }
                        }
                    }

                    // Single macaron title & subtitle
                    .macaron-title, .macaron-subtitle {
                        text-align: center;
                    }

                    .macaron-title {
                        font-weight: bold;
                    }

                    // Single macaron actions (minus / plus)
                    .macaron-actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        max-width: 110px;


                        .macaron-count {
                            font-size: 15px;
                            font-weight: bold;
                            flex: 1;
                            text-align: center;
                        }

                        .macaron-action-btn {
                            border: 1px solid $black;
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            opacity: 1;
                            transition: opacity .2s ease-in-out;

                            &.disable {
                                opacity: 0.3;
                                pointer-events: none;
                            }

                            & > span {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                justify-content: center;

                                &.icon-minus {
                                    &:before {
                                        font-size: 7px;
                                    }
                                }

                                &.icon-plus {
                                    &:before {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }

                    &.disabled {
                        .macarons-list__item-content {
                            opacity: .4;
                        }
                        .macaron-actions {
                            justify-content: center;

                            p {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: $lightgrey-darker;
            }

            &::-webkit-scrollbar-track {
                border-radius: 6px;
                background-color: transparent;
            }
        }

        &__bottom {
            display: flex;
            align-items: baseline;
            justify-content: flex-end;

            .macaron-modal-infos {
                text-transform: uppercase;
                font-size: 20px;
                margin-right: 15px;
                margin-bottom: 15px;
                font-family: $futura;
                display: flex;
                align-items: center;

                .suffix {
                    margin-left: 5px;
                }

                .total-macarons, .max-macarons {
                    font-family: $futura-heavy;
                }


                .reset-size-btn {
                    font-size: 20px;
                    margin: 0 15px;

                    @media (max-width: $screen-max-md) {
                        margin: 0 5px;
                        font-size: 18px;
                    }
                }
            }

            @media (max-width: $screen-max-sm) {
                flex-direction: column;
                align-items: center;

                .macaron-modal-infos {
                    font-size: 15px;
                }
            }
        }

        // Modal close btn
        .close-btn {
            position: absolute;
            top: 30px;
            right: 30px;
            display: inline-block;

            @media (max-width: $screen-max-pad) {
                top: 20px;
                right: 20px;
            }
        }
    }

    // Modal complete state
    &.complete {
        .macaron-add {
            @extend .disable;
        }

        .macaron-submit {
            pointer-events: all;
            opacity: 1;
        }
    }

    // Modal active state
    &.active {
        transition: opacity .2s ease-in-out;
        pointer-events: all;
        opacity: 1;

        & > .content {
            transition: transform .2s .2s ease-in-out;
            transform: translateX(0);
        }
    }
}

.macaron-modal-avertissement {
    // Reset
    p, h1, h2, h3, h4 {
        margin: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 13;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s .2s ease-in-out;

    & > .overlay {
        position: absolute;
        z-index: 0;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .content {
        display: flex;
        flex-direction: column;
        max-width: 50rem;
        height: auto;
        max-height: 90vh;
        padding: 40px 30px 40px 30px;
        background-color: #fff;
        transform: translateX(-100vw);
        transition: transform .2s ease-in-out;
        overflow: hidden;

        @media (max-width: 375px) {
            padding: 40px 15px 40px 15px;
        }

        &__top {
            text-align: center;
            width: 100%;
            margin-bottom: 30px;

            p {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 1.3;
            }
        }
        // Modal close btn
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            display: inline-block;
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .action-primary {
                min-width: 280px;
            }
        }
    }

    // Modal complete state
    &.complete {
        .macaron-add {
            @extend .disable;
        }

        .macaron-submit {
            pointer-events: all;
            opacity: 1;
        }
    }

    // Modal active state
    &.active {
        transition: opacity .2s ease-in-out;
        pointer-events: all;
        opacity: 1;

        & > .content {
            transition: transform .2s .2s ease-in-out;
            transform: translateX(0);
        }
    }
}

.macaron-modal-recap, .macaron-modal-recap-minicart {
    // Reset
    p, h1, h2, h3, h4 {
        margin: 0;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 13;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s .2s ease-in-out;

    & > .overlay {
        position: absolute;
        z-index: 0;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .content {
        padding: 40px 30px 40px 30px;
        background-color: #fff;
        transform: translateX(100vw);
        transition: transform .2s ease-in-out;
        overflow: hidden;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        max-height: 100vh;
        width: 40%;
        z-index: 14;

        @media (max-width: $screen-max-sm){
            width: 100%;
        }

        @media (max-width: 375px) {
            padding: 40px 15px 0 15px;
        }

        &__top {
            text-align: center;
            width: 100%;
            margin-bottom: 30px;

            h2 {
                text-transform: uppercase;
                font-family: $futura;
                font-size: 25px;
                line-height: 1.2;

                @media (max-width: $screen-max-sm) {
                    font-size: 18px;
                    line-height: 1.2;
                }
            }
        }

        &__macarons {
            height: 80%;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-bottom: 30px;

            // Macaron list
            .macarons-list {
                list-style: none;
                padding-left: 0;
                margin-right: 20px;

                // Single macaron
                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    // Single macaron content
                    &-content {
                        display: flex;
                        align-items: center;
                        flex: 3;

                        & > img {
                            max-width: 80px;
                            margin-right: 15px;
                            @media (max-width: $screen-max-pad) {
                                max-width: 60px;
                            }
                        }
                    }

                    .macaron-title {
                        font-weight: bold;
                        font-size: 16px;
                        text-align: left;
                    }

                    .macaron-actions {
                        flex: 0.5;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .prefix {
                            display: block;
                            font-size: 18px;
                            margin-right: 5px;
                        }
                        .macaron-count {
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                        }

                    }
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: $lightgrey-darker;
            }

            &::-webkit-scrollbar-track {
                border-radius: 6px;
                background-color: transparent;
            }
        }

        // Modal close btn
        .close-btn {
            position: absolute;
            top: 30px;
            right: 30px;
            display: inline-block;

            @media (max-width: $screen-max-pad) {
                top: 20px;
                right: 20px;
            }
        }
    }

    // Modal complete state
    &.complete {
        .macaron-add {
            @extend .disable;
        }

        .macaron-submit {
            pointer-events: all;
            opacity: 1;
        }
    }

    // Modal active state
    &.active {
        transition: opacity .2s ease-in-out;
        pointer-events: all;
        opacity: 1;

        & > .content {
            transition: transform .2s .2s ease-in-out;
            transform: translateX(0);
        }
    }
}

.link-compo {
    color: $pink;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
    text-align: left;

    &:hover, &:focus, &:visited {
        color: $pink;
        text-decoration: underline;
    }
}
