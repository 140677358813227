/* ============================= FOOTER CONTACT ============================= */
:root {
    --blockWrapperWidth: 0px;
}

body {
    .footercontact-container {
        display: block !important;
    }
    // Display prefooter contact on all pages only page products
    &.catalog-product-view {
        .footercontact-container {
            display: none !important;
        }
    }
}

%underline-effect {
    text-decoration: none;
    background-image: linear-gradient(black, black);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 0% 1px;
    transition: background-size .2s ease-in-out;

    &:hover {
        background-size: 100% 1px;
        color: inherit;
    }
}

.page-footer {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $white;
    padding-bottom: 0;

    .footercontact-container {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        padding: 0;
        justify-content: center;

        .row-ph {
            flex-wrap: nowrap;
            width: auto;

            @media (max-width: $screen-max-sm) {
                width: unset;
                flex-wrap: wrap;
            }
        }

        .footersocial-container-content {
            margin: 0 auto;
            width: 100%;
            position: relative;

            .block.newsletter {
                display: flex;
                align-items: stretch;
                width: 100%;
                max-width: 100% !important;
                margin-bottom: 0;

                .newsletter-img {
                    width: 45%;
                    height: auto;
                    background-repeat: no-repeat;
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-position: center;
                    @include aspect-ratio(684, 342);

                    @media (max-width: $screen-max-md) {
                        display: none;
                    }
                }

                .newsletter-content {
                    background-color: rgba(0, 0, 0, .04);
                    width: 55%;
                    padding: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    textarea:-webkit-autofill,
                    textarea:-webkit-autofill:hover,
                    textarea:-webkit-autofill:focus,
                    select:-webkit-autofill,
                    select:-webkit-autofill:hover,
                    select:-webkit-autofill:focus {
                        border: 1px solid black !important;
                        box-shadow: 0 1px 0 0 #000 !important;
                        transition: all 5000s ease-in-out 0s !important;
                    }

                    input[type="checkbox"] {
                        padding: 0;
                        border: 1px solid #858585;
                        float: left;
                        margin-right: 0;

                        &:after {
                            background-color: #858585;
                        }
                    }

                    .title {
                        display: block;

                        h3, strong {
                            text-align: left;
                            line-height: inherit;
                            font-family: $futura-light;
                            font-weight: initial;
                            font-size: 22px;
                            margin: 0 0 35px 0;
                            text-transform: uppercase;
                            letter-spacing: 0.9px;
                        }

                        strong {
                            font-family: $futura-heavy;
                        }
                    }

                    .features {
                        ul {
                            list-style: none;
                            padding-left: 0;
                            margin-bottom: 35px;

                            li {
                                margin-bottom: 12px;
                                font-size: 16px;
                                font-family: $futura-light;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.9px;

                                .picto {
                                    margin-right: 8px;
                                    max-width: 22px;
                                }

                                strong {
                                    font-family: $futura;
                                }
                            }
                        }
                    }

                    .content {

                        .field .control:before {
                            display: none;
                        }

                        .field.newsletter {
                            width: 70%;
                            max-width: 100%;

                            @media (max-width: $screen-max-xs) {
                                width: 50%;
                            }
                        }

                        .form {
                            display: flex;
                            flex-direction: column;

                            .input-block {
                                display: flex;
                                display: -webkit-flex;
                                margin-bottom: 10px;
                                width: 100%;

                                input {
                                    height: 50px;
                                    transition: all .3s;
                                    padding: 10px 0 10px 15px;
                                    border: 1px solid $black;
                                    border-right: 0;
                                    box-shadow: none;
                                    border-radius: 8px 0 0 8px;
                                    font-family: $futura;
                                    background-color: rgba(0, 0, 0, .04);
                                    background-clip: text;

                                    &::placeholder {
                                        color: rgba($black, .2);
                                        font-family: $futura;
                                    }

                                    &:focus, &:active {
                                        outline: 0;
                                        box-shadow: none;
                                        border: 1px solid $black;
                                        border-right: none;
                                        transition: all .3s;
                                    }

                                }

                                .actions {
                                    width: 30%;

                                    button {
                                        padding: 12px;
                                        width: 100%;
                                        background-color: $black;
                                        border: 1px solid $black;
                                        @include m-border-radius(0, 8px, 8px, 0);
                                        transition: all .3s;
                                        margin-left: 0;
                                        color: $white;
                                        font-family: $futura-heavy;
                                        min-height: 50px;
                                        text-transform: uppercase;

                                        &:hover {
                                            border: 1px solid $black;
                                            color: $white;
                                            background: $black;
                                            transition: all .3s;
                                        }
                                    }
                                }

                                @media (max-width: $screen-max-xs) {
                                    .actions {
                                        width: 40%;
                                    }
                                }

                            }

                            .custom-checkbox {

                                label {
                                    color: #858585;
                                    font-style: italic;
                                    font-size: 11px;
                                    display: block;
                                    line-height: 14px;
                                    margin-left: 25px;
                                    cursor: pointer;

                                    a {
                                        color: #858585;
                                        text-decoration: underline;
                                        @include transition(all ease 0.4s);
                                    }

                                }

                            }

                            .recaptcha-privacy-terms {
                                color: $black;
                                font-style: italic;
                                font-size: 11px;
                                display: block;
                                line-height: 14px;
                                cursor: pointer;
                                margin-bottom: 0;
                                text-align: left;
                                letter-spacing: 0.9px;

                                a {
                                    color: #858585;
                                    text-decoration: underline;
                                    @include transition(all ease 0.4s);
                                    text-align: center;

                                    &:hover {
                                        color: $pink;
                                    }
                                }
                            }

                        }

                    }

                    @media (max-width: $screen-max-md) {
                        width: 80%;
                        margin: 0 auto;
                    }

                    @media (max-width: $screen-max-sm) {
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }

            .d-flex {

                @media (max-width: $screen-max-md) {
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                }

                @media (max-width: $screen-max-sm) {
                    font-size: 12px;
                }


                a {
                    display: inline-block;
                    color: $black;
                    @include transition(all ease 0.4s);
                }

            }

            .icons-socials-block {
                margin: 0 10px;

                @media (max-width: $screen-max-md) {
                    margin: 10px 0 0;
                }

                @media (max-width: $screen-max-sm) {
                    margin: 15px 0;
                    font-size: 12px;
                }

                .icon-socials {
                    font-size: 20px;
                    margin: 0 5px;

                    @media (max-width: 1024px) {

                        &:first-child {
                            margin-left: 0;
                        }

                    }

                    @media (max-width: $screen-max-sm) {
                        margin: 0 10px;
                    }

                }

            }

        }

        .footersocial-container-right {
            display: flex;
            padding: 40px 0 40px 40px;
            justify-content: center;
            align-items: center;

            @media (max-width: $screen-max-md) {
                flex-direction: column;
            }
            @media (min-width: $screen-min-xs) and (max-width: $screen-min-sm) {
                flex-wrap: wrap;
                flex-direction: row;
                margin: 20px 0;
            }

            @media (max-width: $screen-max-sm) {
                padding: 4px 20px 0;
            }


            .footersocial-block {
                width: 50%;
                text-align: center;
                height: 100%;
                padding-top: 20px;

                @media (max-width: $screen-max-md) {
                    width: 100%;
                    text-align: left;
                    line-height: 1.2;
                    margin: 5px 0 17px;
                    padding-top: 0;
                }

                @media (max-width: $screen-max-sm) {
                    width: 50%;
                    padding-top: 0;
                }

                @media (max-width: $screen-max-xs) {
                    width: 100%;
                    padding-top: 0;
                }


                i {
                    display: block;
                    font-size: 26px;
                    margin-bottom: 15px;

                    @media (max-width: $screen-max-sm) {
                        font-size: 25px;
                        margin-bottom: 3px;
                    }

                }

                p {
                    margin-bottom: 0;
                }


                .footersocial-url {
                    display: block;
                    margin: 15px 10px 0 10px;
                    font-size: 13px;
                    text-transform: inherit;
                    padding: 10px 35px;

                    @media (max-width: $screen-max-md) {
                        margin: 15px 10px 0 0;
                    }
                }

                .footersocial-url-link {
                    text-decoration: underline;
                    font-weight: 600;
                }
            }

        }
    }

    .prefooter-container {
        padding: 40px 0;
        background-color: $black;
        color: $white;
        width: 100%;

        @media (max-width: $screen-max-sm) {
            padding:0;
        }

        a{
            color: #FFF;
        }

        .prefooter-content {
            display: flex;
            display: -webkit-flex;
            padding: 0;

            @media (max-width: $screen-max-sm) {
                flex-direction: column;
                padding: 5px 0 0;
            }

            .row-ph {
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                align-items: flex-start;
                margin: 0;



            }


            .prefooter-block {
                text-align: center;
                width: calc(100% / 5);
                position: relative;
                &:not(:last-child){
                    &::after{
                        position: absolute;
                        content: '';
                        height: 30%;
                        background: white;
                        width: 1px;
                        right: 0;
                        top: 0;
                        opacity: 0.2;
                    }

                    @media (max-width:$screen-max-md) {
                        &::after{
                            content: none;
                        }
                    }
                }





                @media (min-width: $screen-max-sm) and (max-width:$screen-max-md) {
                    width: 140px;
                }

                @media (max-width: $screen-max-sm) {
                    position: relative;
                    padding: 20px 0 20px 68px;
                    margin: 0 20px;
                    width: 100%;
                    text-align: left;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(#F6F6F6, 0.3);
                    }

                }

                .icon-footer{
                    height: 50px;
                    @media (max-width:$screen-max-sm) {
                        height: auto;
                    }

                    i{
                        font-size: 35px;

                        @media (max-width: $screen-max-sm){
                            position: absolute;
                            z-index: 1;
                            top: 15px;
                            left: 0;
                        }

                        &:before{
                            color: $white;
                            font-size: 35px;
                        }

                        &.icon-bus{
                            &:before{
                                font-size: 30px;
                            }
                        }
                        &.icon-carton{
                            &:before{
                                font-size: 40px;
                            }
                        }

                    }
                }



                .prefooter-block-title {
                    font-size: 14px;
                    margin-bottom:0;
                    margin-top: 10px;

                    @media (max-width: $screen-max-md){
                        line-height: 1.4;
                        font-size: 12px;
                    }
                    @media (max-width: $screen-max-sm){
                        margin-top: 0;
                    }

                }

                .prefooter-block-subtitle {
                    font-size: 12px;
                    margin-bottom:0;

                    @media (max-width: $screen-max-md){
                        font-size: 10px;
                    }
                }

            }

        }

    }

    .footer {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        display: -webkit-flex;
        @extend %ph-col;
        @media (max-width: $screen-max-xs) {
            padding-top: 10px;
            padding-bottom: 60px;
        }

        .row-ph {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin: 0;
        }

        .footer-block {
            font-size: 12px;
            width: 170px;
            @media (max-width: $screen-max-sm) {
                width: 100%;
            }

            @media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
                width: 140px;
            }

            .footer-block-content {
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                flex-wrap: wrap;
                -webkit-tap-highlight-color: transparent;

                @media (max-width: $screen-max-sm) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    cursor: pointer;
                }

                .footer-block-title {
                    position: relative;
                    padding-bottom: 15px;
                    font-size: 14px;

                    @media (max-width: $screen-max-sm) {
                        padding: 25px 0;
                        font-size: 14px;
                    }

                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(calc(-50% - 2px));
                        right: 20px;
                        transition: all .3s;
                        @media (min-width: $screen-min-sm) {
                            display: none;
                        }

                        &:before {
                            font-size: 8px;
                        }
                    }
                }

                .footer-block-icons {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: 0px;
                    grid-row-gap: 15px;
                    width: 130px;
                    @media (max-width: $screen-max-sm) {
                        display: flex;
                        width: auto;
                        & > a {
                            &:not(:last-child) {
                                margin-right: 17px;
                            }
                        }
                    }

                    & > a {
                        display: inline-block;
                        transition: all .3s ease;

                        & > i {
                            display: flex;

                            &:before {
                                font-size: 20px;
                            }

                            &.icon-twitter:before {
                                color: $black;
                                transition: all .3s ease;
                            }
                        }

                        &:hover, &:focus {
                            color: $pink;

                            & > i {
                                &.icon-twitter:before {
                                    color: $pink;
                                }
                            }
                        }
                    }

                }

                .footer-block-links {
                    display: flex;
                    display: -webkit-flex;
                    flex-direction: column;

                    @media (max-width: $screen-max-sm) {
                        margin-bottom: 20px;
                        display: none;
                    }

                    & > p > a, & > a {
                        font-family: $futura-light;
                        font-weight: 200;
                        color: $black;
                        text-decoration: none;
                        transition: all .3s;
                        margin-bottom: 5px;
                        font-size: 14px;
                        //@extend %underline-effect;
                        //background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
                        width: fit-content;
                        width: -moz-fit-content;

                        &:hover, &:focus {
                            color: $pink;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }

                &.active {
                    i.icon-arrow-down {
                        transform: rotate(180deg) translateY(8px);
                        transition: all .3s;
                    }
                }
            }
        }

        .footer-copyright {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;

            @media (max-width: $screen-max-sm) {
                padding: 20px;
            }

            img {
                max-width: 140px;
                margin-bottom: 5px;
            }
        }
    }

}

@-webkit-keyframes webkitCarouselEffect {
    from {
        -webkit-transform: translate(0);
    }
    to {
        -webkit-transform: translate(var(--blockWrapperWidth));
    }
}

@keyframes carouselEffect {
    from {
        transform: translate(0);
    }
    to {
        transform: translate(var(--blockWrapperWidth));
    }
}
