.product-image-photo {
    width: 100% !important;
}

.page-products {
    .breadcrumbs {
        margin-bottom: 0;
    }

    .columns {

        @media (max-width: $screen-max-md) {
            padding-top: 0;
        }

    }

}

.breadcrumbs {
    background: white;
}

.custom-header-category, .breadcrumbs {
    @media (min-width: $screen-min-sm) {
        z-index: 11;
    }
}

.catalog-category-view, .catalogsearch-result-index {

    .custom-header-category {
        .empty-header {
            height: 30px;
            @media (max-width: $screen-max-md) {
                height: 70px;
            }
        }

        .category-image {
            max-height: 320px;
            min-height: 320px;
            overflow: hidden;
            @include keep-ratio(1440, 600);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-bottom: 0;

            @media (max-width: $screen-max-md) {
                max-height: 220px;
                min-height: 1px;
                margin-bottom: 0;
            }

        }

    }

    .page-main {
        background-color: $white;
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        max-width: 100%; // override container-ph

        @media (max-width: $screen-max-md) {
            width: 100%;
        }

        .page-title-wrapper {
            text-align: center;
            text-transform: uppercase;

            @media (max-width: $screen-max-md) {
                margin-bottom: 20px;
            }

            .page-title {
                margin-bottom: 0;
                font-weight: inherit;

                span {
                    font-family: $futura-light;
                    font-weight: 200;
                    font-size: 25px;
                }
            }
        }

        .category-view {

            .category-description {
                text-align: center;
                color: $black;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 1.16px;
                width: 60%;
                margin: 10px auto;
                @media (max-width: $screen-max-lg) {
                    width: 80%;
                }
                @media (max-width: $screen-max-sm) {
                    width: 100%;
                }

                a {
                    color: $black;
                }

                @media (max-width: $screen-max-md) {
                    padding: 0;
                }
            }

        }

        .container-filter-category {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: $screen-max-md) {
                margin-bottom: 0;
            }
            @media (min-width: $screen-min-md) {
                padding-left: 21px;
            }

            .form-sidebar {
                @media (min-width: $screen-min-sm) {
                    max-height: 400px;
                    overflow-y: scroll;
                }
            }

            .open-sidebar-filter {
                margin: 0;
                background: transparent;
                padding: 0;
                border: 0;
                font-size: 16px;
                display: flex;
                align-items: center;

                .icon {
                    padding-right: 5px;
                }
            }

            .filter-search-sidebar {
                position: absolute;
                left: -100vw;
                top: 30px;
                right: auto;
                width: 390px;
                z-index: 14;
                background-color: $white;
                transition: all .3s ease-in;
                overflow: hidden;

                @media (max-width: $screen-max-sm) {
                    left: -120vw;
                }

                &-bg {
                    position: fixed;
                    top: 0;
                    background-color: rgba(0, 0, 0, .4);
                    z-index: 13;
                    height: 100%;
                    width: 100%;
                    right: -100%;
                }

                &.active {
                    left: 0;
                }

                @media (max-width: $screen-max-sm) {
                    &.active {
                        width: 100vw;
                        position: fixed;
                        top: 0;
                        height: 100vh;
                    }
                }

                .sidebar-header {
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 30px;

                    .sidebar-title {
                        font-size: 20px;
                        color: $black;
                        margin: 0;
                    }

                    .close-sidebar-filter {
                        cursor: pointer;
                        @include transition(all ease 1s);
                        transform-origin: 50% 50%;
                        z-index: 1;

                        &:hover {
                            transform: rotate(360deg);
                        }

                    }


                }

                .sidebar-body {

                    .ask-block {
                        border-top: 0;
                        padding: 0 30px;
                        display: block;
                        margin-bottom: -10px;

                        .filter-current {
                            .items {
                                padding: 0;

                                .item {
                                    .text-uppercase {
                                        margin-bottom: 0;
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        justify-content: space-between;
                                        font-family: $futura-light;
                                        font-weight: 200;
                                        font-size: 16px;
                                    }

                                    &:not(.filter-hide) {
                                        display: block;
                                        margin-bottom: 0;
                                        padding: 15px 0 0 0;
                                        margin-right: 0;
                                    }
                                }

                                .format {
                                    margin: 5px 10px 5px 0;
                                    flex: 1 1 auto;
                                    width: calc((100% - 20px) / 3);
                                    display: inline-block;
                                    vertical-align: top;
                                    font-size: 14px;
                                    @media (max-width: $screen-max-xs) {
                                        width: 100%;
                                        max-width: 100%;
                                        margin-right: 0;
                                    }

                                    a {
                                        display: block;
                                        line-height: 1.42857143;
                                        padding: 10px 0;
                                        text-decoration: none;
                                        text-align: center;
                                        width: 100%;
                                        height: 100%;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }

                        .block-subtitle.filter-current-subtitle {
                            display: none;
                        }

                        .filter-hide {
                            display: none;
                        }
                    }

                    .form-block {
                        padding: 0;

                        label {
                            display: block;
                            margin-bottom: 10px;
                        }

                        input {
                            height: 35px;
                            width: 100%;
                            border: 1px solid #E1E1E1;
                            transition: all .3s;
                            font-family: $futura-light;
                            font-weight: 200;

                            &:focus {
                                border: 1px solid $black;
                                transition: all .3s;
                                box-shadow: none;
                            }
                        }

                        .filter-actions {
                            display: flex;
                            padding: 0;
                            margin: 40px 0 25px 0;
                            justify-content: flex-start;

                            @media (max-width: $screen-max-sm) {
                                flex-direction: column;
                            }

                            @media (max-width: $screen-max-xs) {
                                margin: 15px 0;
                            }

                            a, button {
                                display: block;
                                width: 240px;

                                &:last-child {
                                    margin-left: 10px;
                                }

                                @media (max-width: $screen-max-sm) {
                                    order: 2;
                                    width: 100%;
                                    &:last-child {
                                        order: 1;
                                        margin-left: 0;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }

                        .form-custom-line {
                            display: flex;
                            display: -webkit-flex;
                            padding: 15px 0;
                            border-bottom: 1px solid #E1E1E1;

                            @media (max-width: $screen-max-lg) {
                                flex-direction: column;
                            }

                            @media (max-width: $screen-max-sm) {
                                border: none;
                            }

                            &:last-child {
                                border: 0;
                            }

                            .form-group {
                                width: 100%;
                                padding: 10px 0;

                                &.form-address {
                                    width: 100%;
                                }

                                &-format {
                                    padding: 10px 0 0;
                                    width: 100%;
                                }
                            }

                        }

                    }

                    .filter.block {
                        border-top: 0;
                        padding: 0 30px 20px 30px;

                        .title {
                            margin-bottom: 0px;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            justify-content: space-between;
                            font-family: $futura-light;
                            font-weight: 200;
                            font-size: 16px;
                            padding: 20px 20px 20px 0;

                            .icon {
                                transform: rotate(90deg);
                                font-weight: 100;
                                font-size: 12px;
                                transition: all .3s ease;
                            }

                            &[aria-expanded="true"] {
                                .icon {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .content {
                            .subtitle {
                                display: none;
                            }

                            .options {
                                dt {
                                    display: none;
                                }

                                .items {
                                    .item {
                                        vertical-align: top;
                                        font-size: 14px;

                                        a {
                                            display: block;
                                            color: $black;
                                            padding: 10px 0;
                                            border: 0;
                                            text-decoration: none;
                                            text-align: left;
                                            @include transition(all ease 0.7s);

                                            &:hover {
                                                background-color: inherit;
                                                color: $black;
                                            }
                                        }

                                        .count {
                                            display: none;
                                        }

                                        @media (max-width: $screen-max-xs) {
                                            width: 100%;
                                            max-width: 100%;
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #layered-filter-block {
                        .filter-options {
                            display: block;

                            .filter-options-item {
                                width: 100%;
                                padding: 0;
                                cursor: pointer;

                                &.notransition {
                                    div[data-role="content"] {
                                        height: auto !important;
                                    }

                                    .icon {
                                        transition: none !important;
                                    }
                                }

                                .items {
                                    display: block;
                                    font-size: 0;

                                    .item {
                                        display: inline-block;
                                        vertical-align: top;
                                        font-size: 14px;
                                        width: 100%;
                                        margin: 0;
                                        padding: 10px 0;

                                        @media (max-width: $screen-max-xs) {
                                            width: 100%;
                                            padding: 5px 0;
                                        }

                                        &:nth-child(3n) {
                                            margin-right: 0;
                                        }

                                        a {
                                            display: block;
                                            color: $black;
                                            font-family: $futura-light;
                                            font-weight: 200;
                                            padding: 0;
                                            border: 0;
                                            text-decoration: none;
                                            text-align: left;
                                            width: 100%;
                                            height: 100%;
                                            @include transition(all ease 0.7s);
                                        }

                                    }

                                    .item.wp-ln-selected {
                                        a {
                                            border-left: 0;
                                            padding-left: 0;
                                            font-weight: bold;
                                            font-style: normal;
                                        }
                                    }

                                }

                                border-top: 1px solid rgba(0, 0, 0, 0.2);

                                &:first-child {
                                    padding-top: 0;
                                }

                                &:last-child {
                                    border-bottom: 0;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                }
                            }
                        }
                    }
                }

            }
        }

        .column.main {
            width: 100%;
            float: unset;
            padding-top: 0;

            .products {

                &.list {
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    @media (max-width: $screen-max-md) {
                        margin: 0;
                    }

                }

                .product-item {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 33.333%;
                    max-width: 3333.333%;
                    @media (min-width: $screen-min-sm) and (max-width: $screen-max-md) {
                        width: 100%;
                    }

                    @media (max-width: $screen-min-sm) {
                        width: 100%;
                        margin: 0 0 13px;
                        padding: 0;
                    }

                    .product-image-wrapper {
                        border: 1px solid #d9d9d9;
                    }

                    .product-image-photo {
                        margin: 0 auto;

                        @media (max-width: $screen-max-sm) {
                            max-width: 200px;
                        }

                    }

                    .product-item-info {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        @media (max-width: $screen-max-sm) {
                            flex-direction: column;
                            align-items: center;
                            margin: 0 15px 15px;
                            border: 1px solid #e2e2e2;

                            &:hover {
                                margin: 0 15px 15px;
                                border: 1px solid #e2e2e2;
                            }

                        }

                        .product-item-photo {
                            box-sizing: content-box;
                            width: 50%;
                            position: relative;

                            @media (min-width: $screen-max-md) and (max-width: $screen-max-lg) {
                                width: 40%;
                            }

                            @media (max-width: $screen-max-sm) {
                                width: 100%;
                                margin: 0;
                            }

                            .product-image-container {

                                @media (max-width: $screen-max-sm) {
                                    display: block;
                                    margin: 0 auto;
                                }

                            }

                        }

                        .ph-over-image {
                            position: absolute;
                            z-index: 12;
                            width: 100%;
                            padding: 0;
                            border: 0;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            transition: opacity .5s;

                            &.active {
                                opacity: 1;
                            }
                        }

                        .product-item-details {
                            width: 50%;

                            @media (min-width: $screen-max-md) and (max-width: $screen-max-lg) {
                                width: 60%;
                            }

                            @media (max-width: $screen-max-sm) {
                                margin: 0;
                                width: 100%;

                                .product-item-inner {
                                    margin-top: auto;

                                    &:before {
                                        content: none;
                                    }

                                }

                            }

                            .product-item-description {
                                margin: 0;
                                text-transform: uppercase;

                                p {
                                    margin: 0;
                                }
                            }

                            .product-item-actions {

                                .actions-primary {

                                    @media (max-width: $screen-max-sm) {
                                        width: 100%;
                                    }

                                }
                            }

                            .price-container-ph {
                                .price-box {

                                    @media (max-width: $screen-max-sm) {
                                        padding: 0;
                                        margin-bottom: 10px;
                                    }

                                }

                            }

                        }

                    }

                }

                .promo-item {
                    overflow: hidden;
                    display: inline-block;
                    width: calc(100% / 3);
                    min-width: 150px;
                    margin: 0 0 50px;
                    @media (min-width: $screen-min-md) {
                        padding: 4px 15px 0;
                    }

                    @media (max-width: $screen-max-md) {
                        width: 100%;
                        padding: 0;
                        margin-bottom: 30px;
                    }

                    .product-item-info {
                        width: 100%;
                        padding: 0;
                        box-shadow: none;
                        margin: 0;
                        height: 100%;

                        #player-container, iframe {
                            height: 100%;
                            width: 100%;
                            border: 0;
                            pointer-events: none;
                            min-height: 560px;
                            background-color: $black;
                        }

                        @media (max-width: $screen-max-md) {
                            box-shadow: none;
                            width: 100%;
                            #player-container, iframe {
                                min-height: 400px;
                            }
                        }

                        @media (max-width: $screen-max-sm) {
                            flex-direction: column;
                            border: 0;
                        }

                        .picture-promo {
                            width: calc(100% + 1.8px);
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            display: block;
                            height: 100%;
                            min-height: 600px;
                            @media (max-width: $screen-max-md) {
                                min-height: 400px;
                            }
                            // fallback
                            box-sizing: border-box;
                            @media (max-width: $screen-max-md) {
                                aspect-ratio: unset;
                                height: 50vw;
                            }
                        }

                        .product-item-details {
                            width: 100%;
                            padding: 10px 25px;

                            .product-item-name,
                            .product.name a {
                                margin-bottom: 20px;
                                font-weight: initial;
                                font-size: 18px;
                                font-family: $futura-heavy;
                                color: $black;
                                hyphens: none;
                                line-height: 26px;
                                max-width: 85%;
                                min-height: 50px;
                                @include transition(ease 0.4s all);
                                text-transform: uppercase;

                                &:hover {
                                    color: $black;
                                    text-decoration: none;
                                }
                            }

                            .product-item-description {
                                color: $grey;
                            }

                            .cta {
                                // width: 90%;
                                text-align: center;
                            }

                        }

                        &:hover, &:focus {
                            z-index: initial;
                            width: 100%;
                            padding: 0;
                            box-shadow: none;
                            margin: 0;
                            border: 0;
                        }

                    }

                }

            }

            @media (max-width: $screen-max-md) {
                flex-direction: column;
                align-items: center;
            }

        }

    }

}

.wishlist-index-index,
.catalog-category-view, .catalogsearch-result-index {

    .page-main {

        .column.main {

            .products {
                &.list {
                    overflow-y: visible;
                    margin: 30px -30px;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: flex-start;
                    overflow-x: hidden;

                    @media (max-width: $screen-max-sm) {
                        margin: 30px -15px 0 -15px;
                    }
                }

                .product-item {
                    width: calc(100% / 3);
                    max-width: calc(100% / 3);
                    padding: 0 15px;
                    position: relative;
                    margin: 0 0 50px;

                    @media (max-width: $screen-max-md) {
                        width: 50%;
                        margin-bottom: 30px;
                        max-width: 50%;
                        padding: 0 5px;
                    }

                    .product-image-photo {
                        margin: 0 auto;
                        top: 50%;
                        transform: translateY(-50%);

                        @media (max-width: $screen-max-sm) {
                            max-width: 200px;
                        }

                    }

                    .product-item-info {

                        @media (max-width: $screen-max-sm) {
                            flex-direction: column;
                            align-items: center;
                            margin: 0;
                            border: 0;

                            &:hover {
                                margin: 0;
                                border: 0;
                            }

                        }

                        .product-item-photo {
                            width: 100%;

                            @media (max-width: $screen-max-sm) {
                                width: 100%;
                                margin: 0;
                            }

                            .product-image-container {

                                @media (max-width: $screen-max-sm) {
                                    display: block;
                                    margin: 0 auto;
                                }

                            }

                        }

                        .product-item-details {
                            width: 100%;

                            @media (max-width: $screen-max-sm) {
                                margin: 0;
                                width: 100%;

                                .product-item-inner {

                                    &:before {
                                        content: none;
                                    }

                                }

                            }
                            @media (max-width: $screen-max-md) {
                                padding: 10px 0 0;
                            }

                            .product-item-description {
                                margin: 0;
                                @media (max-width: $screen-max-xs) {
                                    font-size: 13px;
                                }

                                p {
                                    margin: 0;
                                }
                            }

                            .product-item-actions {

                                .actions-primary {

                                    @media (max-width: $screen-max-sm) {
                                        width: 100%;
                                    }

                                }
                            }

                            .shipping-container {

                                @media (max-width: $screen-max-sm) {
                                    padding: 0 20px;
                                }

                            }

                            .price-container-ph {

                                .price-box {

                                    .price-container {
                                        display: flex;
                                        align-items: center;
                                        @media (max-width: $screen-max-xs) {
                                            flex-direction: row;
                                            align-items: center;
                                        }
                                    }

                                    .price-label {
                                        font-size: 12px;
                                        letter-spacing: .42px;
                                        line-height: 1.2;
                                        margin-bottom: 0;
                                        color: $black;
                                    }

                                    .price {
                                        font-family: $futura-heavy;
                                        font-size: 18px;
                                        line-height: 1;
                                        letter-spacing: .4px;
                                        margin: 0;
                                        width: 100%;
                                        text-align: right;
                                        display: block;
                                        @media (max-width: $screen-max-md) {
                                            font-size: 16px;
                                        }
                                    }

                                }

                            }

                        }

                        &:hover {
                            .product-item-photo {
                                opacity: 1;
                            }
                        }

                    }

                    &:hover {
                        .product-item-info {
                            .product-item-photo {
                                opacity: 1;

                                .product-image-container {
                                    opacity: 1;
                                }
                            }

                            .mpquickview-button {
                                display: flex;
                            }
                        }
                    }
                }
            }

            @media (max-width: $screen-max-md) {
                flex-direction: column;
                align-items: center;
            }
            @media (max-width: $screen-max-sm) {
                padding-bottom: 0;
            }

        }

        .product-item-description {
            margin: 0;

            p {
                margin: 0;
            }

        }

        .product-item-details {

            @media (max-width: $screen-max-md) {
                margin: 0;
            }

        }

        .page-products .products-grid .product-items {
            flex-wrap: wrap;
            margin: 0;
            justify-content: space-around;
            display: flex;
            display: -webkit-flex;
            align-items: center;
        }

        .price-container-ph {
            display: flex;
            display: -webkit-flex;
            align-items: flex-end;
            border-bottom: 0;

            .type-ph {
                // TODO Refonte : à valider si on garde ou non
                display: none;
                padding: 0 0 0 5px;
                margin: 0 0 10px 10px;
                font-size: 12px;
                position: relative;
                color: $grey;

                &:before {
                    content: '';
                    background-color: #000;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 50%;
                    background-color: $grey;
                }

            }

        }

    }

}

.products {

    @media (max-width: $screen-max-md) {
        margin: 0;
    }

}

#pac-sidebar-input-group {
    display: block;

    .pac-results {
        left: initial;
        width: calc(100% - 80px);
    }
}
