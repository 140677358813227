.catalog-product-view,
.catalog-category-view,
#mpquickview-popup {

    .main-wrapper-product {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $screen-max-md) {
            flex-direction: column;
        }
    }

    .product-info-main {
        width: 47%;

        //@media (min-width: $screen-min-md) {
        //    position: sticky;
        //    top: 190px;
        //    left: 0;
        //    padding: 20px 0;
        //    z-index: 10;
        //}

        @media (max-width: $screen-max-md) {
            width: 100%;
            position: relative;
            padding: 0;

            .price-box {
                &.price-final_price {
                    text-align: right;

                    span {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (max-width: $screen-max-sm) {
            .price-box {
                &.price-final_price {
                    margin-top: 0;
                    padding: 0;
                    width: 100%;
                }
            }
        }

        @media (max-width: $screen-max-xs) {
            .price-box {
                &.price-final_price {
                    margin-top: 30px;
                }
            }
        }

        .page-title-wrapper {

            @media (max-width: $screen-max-md) {
                text-align: left;
            }

            h1 {
                color: $black;
                font-family: $futura-heavy;
                font-size: 27px;
                width: 100%;
                margin: 0 0 5px 0;
                line-height: 32px;
                font-weight: bold;
                letter-spacing: 0.95px;

                span {
                    font-family: inherit;
                    font-size: inherit;
                    text-transform: uppercase;
                }

                @media (max-width: $screen-max-md) {
                    width: 100%;
                    margin-top: 0;
                }

                @media (max-width: $screen-max-sm) {
                    font-size: 25px;
                }

            }

            &.product {
                display: flex;
                display: -webkit-flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .base {
                    @media (max-width: $screen-max-md) {
                        display: block;
                        margin-top: 24px;
                    }
                    @media (max-width: $screen-max-sm) {
                        margin-top: 0;
                    }
                }

                // TODO : remove block and after style
                .product-social-links {
                    width: 10%;
                    display: none; // temporaire refonte test
                    justify-content: flex-end;
                    margin-top: -4px;

                    .product-addto-links {
                        a {
                            &::before {
                                @include transition(ease 0.4s all);
                                color: $black;
                                @media (max-width: $screen-max-md) {
                                    color: $white;
                                    margin: 0;
                                }
                            }

                            &:hover {
                                &::before {
                                    color: $black;
                                }
                            }

                            &.updated {

                                &::before {
                                    display: none;
                                }

                                span {
                                    background-color: $black;
                                    color: #FFF;
                                    font-size: 10px;
                                    padding: 3px 5px;
                                    white-space: nowrap;
                                }

                            }
                        }
                    }

                }

                .product {

                    &.attribute {

                        &.overview {
                            margin: 0;
                            font-size: 16px;
                            letter-spacing: 0;
                            font-family: $futura-light;
                            font-weight:200;
                            font-style: italic;

                            @media (max-width: $screen-max-md) {
                                width: 100%;
                            }

                            @media (max-width: $screen-max-sm) {
                                width: 100%;
                            }

                        }

                    }

                }

            }

        }

        .product-info-price {
            border: none;
            width: 100% !important;
            margin: 0 0 15px 0;

            .price-box {
                display: flex;
                display: -webkit-flex;
                width: auto;
                justify-content: flex-end;
                text-align: right;

                .amgiftcard-price-container {
                    max-height: 36px !important;
                }


                .price-container {

                    & > span {
                        margin-bottom: 0;
                    }

                }

                @media (max-width: $screen-max-md) {
                    justify-content: center;
                    text-align: center;
                }

                .price-label {
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-right: 6px;
                }

                .price {
                    color: $black;
                    font-size: 20px;
                    margin-left: 0;
                }

            }

        }

        // TODO : delete after remove block in product page
        .available-date {
            display: none;
            font-size: 14px;
            line-height: 1.25;
            letter-spacing: .9px;
            color: $black;
            margin-top: 25px;
            margin-bottom: 30px;

            @media (max-width: $screen-max-md) {
                text-align: center;
            }

            @media (max-width: $screen-max-sm) {
                margin-top: 14px;
                margin-bottom: 30px;
            }

        }

        .product-add-form {
            padding-top: 20px;
            padding-bottom: 30px;

            @media (max-width: $screen-max-sm) {
                padding-top: 0;
            }

            label {
                font-family: $futura-light;
                font-weight:200;
                color: $black;
            }

            .product-options-wrapper {
                font-size: 0;
                margin-bottom: 20px;

                @media (max-width: $screen-max-sm) {
                    display: flex;
                    display: -webkit-flex;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                }

                .configurable {
                    display: flex;
                    display: -webkit-flex;
                    align-items: flex-end;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    gap: 50px;

                    @media (max-width: $screen-max-sm) {
                        margin-bottom: 0;
                    }

                    @media (max-width: $screen-max-xs) {
                        width: 100%;
                    }
                    @media (max-width: $screen-max-md) {
                        margin-bottom: 15px;
                    }

                    label {
                        margin-bottom: 0;
                        border-bottom: 0;
                        padding-bottom: 10px;
                        white-space: nowrap;
                        padding-right: 0;
                        color: $black;
                        opacity: 1;
                        min-width: 50px;
                    }

                    .control {
                        border: 1px solid $black;
                        width: auto;
                        min-width: 200px;
                    }

                }

                .select2-container {

                    .select2-selection--single {
                        position: relative;
                        color: $black;
                        font-size: 14px;
                        font-family: $futura-light;
                        font-weight:200;
                        border: none;
                        padding: 7px 7px 7px 80px;
                        height: auto;
                        border-radius: 0;

                        @media (max-width: $screen-max-xs) {
                            text-align-last: start;
                        }

                        span {
                            color: $black;
                            font-size: 12px;
                            left: 40px;
                            @media (max-width: $screen-max-sm) {
                                padding-left: 0;
                            }
                        }

                        .select2-selection__rendered {
                            line-height: 24px;
                            text-align: left;
                            text-transform: initial;
                        }

                        .select2-selection__arrow {
                            @media (max-width: $screen-max-xs) {
                                top: 5px;
                            }


                            &:before {
                                content: '\e900';
                                font-family: $icomoon-font-family;
                                font-size: 8px;
                                color: rgba($black, 0.7);
                            }

                            b {
                                display: none;
                            }

                        }

                    }


                }

                select {
                    position: relative;
                    text-align-last: end;
                    color: $black;
                    font-size: 14px;
                    font-family: $futura-heavy;
                    border: none;
                    padding-right: 40px;

                    &:focus {

                        box-shadow: none;
                    }
                }

                .message-notice {
                    padding: 10px 0 0;
                }

            }

            .fieldset {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                width: 100%;

                @media (max-width: $screen-max-md) {
                    width: 100%;
                    order: 3;
                }

                &:last-child {
                    margin-bottom: 0;
                }

            }

            .field {

                &.focused {
                    .label {
                        @include transform(translateY(0));
                    }
                }

                .label {
                    opacity: 1;
                }

                &.qty {
                    display: flex;
                    display: -webkit-flex;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: flex-end;
                    margin: 0;
                    gap: 50px;

                    .control {
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;
                        width: auto;
                        border: 1px solid $black;

                        div {

                            &:not(.mage-error) {
                                display: flex;
                                display: -webkit-flex;
                                align-items: center;
                                justify-content: center;
                                width: 32px;
                                height: 36px;
                                border: 0;
                            }

                            &:hover {
                                @include transition(ease all 0.4s);
                                background-color: $black;

                                a {
                                    color: $white;
                                }
                            }

                            a {
                                color: $black;
                                font-size: 12px;
                                line-height: 1;
                                font-family: $futura-heavy;
                                text-align: center;
                                width: 12px;
                                height: 12px;

                                &.moins-qty {
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    @media (max-width: $screen-max-sm) {
                                        width: 15px;
                                        height: 15px;
                                    }

                                    &::before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: "-";
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                &.plus-qty {
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    @media (max-width: $screen-max-sm) {
                                        width: 15px;
                                        height: 15px;
                                    }

                                    &::before {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        content: "+";
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                &:hover {
                                    text-decoration: none;
                                }

                            }

                        }

                        input {
                            width: 50px;
                            margin: 0;
                            padding: 0 10px;
                            text-align: center;
                            font-size: 14px;
                            border: 0;
                            box-shadow: none;
                            height: 36px;
                            font-family: $futura-heavy;
                        }

                    }
                }
            }

            .fluide-field {
                .field {
                    &.focused {
                        .label {
                            @include transform(translateY(-100%));
                        }
                    }
                }
            }

            .actions {
                margin-bottom: 0px;

                #product-addtocart-button,
                #product-updatecart-button {
                    background-color: $black;
                    font-family: $futura-light;
                    font-weight: 200;
                    width: max-content;
                    margin-top: 0px;
                    letter-spacing: .46px;
                    border: 1px solid transparent;
                    @include transition(ease 0.4s all);
                    border-radius: 22px !important;
                    padding: 8px 14px;

                    &:before {
                        content: none;
                    }

                    &:hover {
                        background-color: $white;
                        border: 1px solid $black;
                        color: $black;
                        opacity: 1;
                    }

                    @media (max-width: $screen-max-md) {
                        position: fixed;
                        border: none;
                        z-index: 10;
                        bottom: 0;
                        left: 0;
                        margin: 0;
                        width: 100%;
                        height: 60px;
                        border-radius: 0 !important;
                    }

                }

                @media (max-width: $screen-max-sm) {
                    margin-top: 15px;
                }
            }

            .product-options-bottom-action {
                @media (min-width: $screen-min-md) {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }

        .box-tocart {
            margin: 0;
        }

        .price-box {
            float: left;
            width: 100%;
            text-align: left;
            padding-left: 0px;
            padding-bottom: 0;
            box-sizing: border-box;
            color: $black;

            .price-container {
                display: flex;
                align-items: center;
            }

            .price-label {
                font-size: 12px;
                letter-spacing: .42px;
                line-height: 1.2;
                margin-bottom: 0;
                color: $black;

                @media (max-width: $screen-max-sm) {
                    display: block;
                    font-size: 14px;
                    margin-bottom: 5px;
                }

            }

        }

        // TODO : Move to collapse detail
        .reassurance {

            @media (max-width: $screen-max-sm) {
                display: inline-block;
                margin-top: 10px;
            }

            ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                @media (max-width: $screen-max-sm) {
                    margin-bottom: 0;
                    margin-top: 35px;
                }

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    flex-direction: row;
                    justify-content: center;
                }
            }

            li {
                list-style: none;
                margin-bottom: 25px;
                color: #ACACAC;
                padding-left: 60px;
                position: relative;


                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    padding-left: 0;
                }

                @media (max-width: $screen-max-sm) {
                    margin-bottom: 35px;
                }

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    width: 30%;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin: 0 10px;
                }

                &.active {
                    color: $green-froggy;
                }

            }

            i {
                font-size: 26px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    margin-bottom: 15px;
                }

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    position: initial;
                }
            }

            .descr-rea {
                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    margin-top: 10px;
                }
            }

            .label {
                font-family: $futura-light;
                font-weight:200;
                font-size: 13px;
                letter-spacing: .46px;
                line-height: 1.2;
                margin-bottom: 2px;

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    min-height: 30px;
                    text-align: center;
                }
            }

            .desc {
                font-family: $futura-heavy;
                font-size: 12px;
                letter-spacing: .77px;

                @media (min-width: $screen-min-xs) and (max-width: $screen-max-md) {
                    text-align: center;
                }
            }

        }
    }

    .product {
        &.stock {
            font-size: 16px;
        }

        &.media {
            width: 50%;
            position: relative;

            @media (min-width: $screen-min-md) {
                padding: 20px 0;
            }

            @media (max-width: $screen-max-md) {
                width: 100%;
                order: -1;
            }

        }

        &.info {

            &.detailed {
                .items {
                    .item { // new collapse informations detailled product
                        border-top: 1px solid $lightgrey-2;

                        .title {
                            display: flex;
                            align-items: center;
                            font-family: $futura-heavy;
                            font-size: 16px;
                            justify-content: flex-start;
                            padding: 16px 0;
                            text-transform: uppercase;
                            position: relative;
                            cursor: pointer;
                            font-weight: bold;
                            letter-spacing: 0.95px;

                            .icon {
                                font-size: 12px;
                                position: absolute;
                                right: 20px;
                                transform: rotate(90deg);
                                transition: all .3s ease;
                            }

                            &[aria-expanded="true"] {
                                .icon {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .content {
                            > div {
                                padding: 20px 0;

                                &.ph-disponibilites {
                                    padding-top: 0;
                                }

                                .ph-available_dates {
                                    padding: 10px 0 10px 0;
                                    text-transform: uppercase;
                                }

                                .ph-shipping_methods {
                                    padding-top: 10px;

                                    .method {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        margin-bottom: 15px;

                                        .icon {
                                            font-size: 45px;
                                            min-width: 65px;
                                            padding-right: 8px;
                                            opacity: .3;
                                        }

                                        .legend {
                                            text-transform: uppercase;
                                            font-family: $futura-heavy;
                                            font-size: 14px;
                                            margin-bottom: 5px;
                                            border-bottom: 0;
                                            opacity: .3;
                                            font-weight: bold;
                                            letter-spacing: 0.95px;
                                        }

                                        .detail {
                                            margin-bottom: 0;
                                            opacity: .3;
                                        }

                                        &.active {
                                            .icon {
                                                opacity: 1;
                                            }
                                            .legend, .detail {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.fix {
                            .title {
                                padding-bottom: 0;
                                cursor: default;
                            }
                        }
                    }

                    @media (max-width: $screen-max-md) {
                        margin: 0;
                        padding: 0;
                        border-bottom: 0;
                    }

                    @media (max-width: $screen-max-sm) {
                        padding: 0;
                        border-bottom: none;
                    }

                }

                @media (max-width: $screen-max-sm) {
                    margin-bottom: 0;
                }
            }

        }

        &.media {

            // Slider
            .owl-carousel-gallery__image {
                .owl-dots {
                    span {
                        display: none;
                    }
                }
            }

            @media (max-width: $screen-max-sm) {
                margin-bottom: 28px;
            }

        }

        /* ALLERGENES */
        .tab-allergene {
            font-size: 0;
            margin: 30px auto;
            text-align: center;

            @media (max-width: $screen-max-md) {
                flex-direction: column;
            }

            @media (max-width: $screen-max-sm) {
                width: 100%;
                margin-bottom: 50px;
            }

            .tab-part {
                border: 1px solid $lightgrey;
                display: inline-block;
                width: calc(50% - 15px);
                margin: 0 15px;
                font-size: 14px;
                max-width: 460px;

                @media (max-width: $screen-max-sm) {
                    width: 100%;
                    margin: auto;
                }

                &:first-child {
                    margin-left: 0;

                    @media (max-width: $screen-max-md) {
                        margin-left: auto;
                        margin-bottom: 15px;
                    }

                }

                &:last-child {
                    margin-right: 0;

                    @media (max-width: $screen-max-md) {
                        margin-right: auto;
                    }

                }

                .tab-item {
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    justify-content: space-between;

                    p, i:before {
                        color: #9a9a9a;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid $lightgrey;
                    }

                    i {
                        font-size: 28px;
                        margin-right: 20px;

                        &.icon-check {
                            font-size: 15px;
                        }
                    }

                    &.active {
                        p, i:before {
                            color: $black;
                        }
                    }

                    .tab-item-label {
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;

                        i {
                            width: 50px;
                            margin: 0 20px;
                            display: flex;
                            display: -webkit-flex;
                            justify-content: center;
                        }

                        p {
                            padding: 20px;
                            margin: 0;

                            @media (max-width: $screen-max-sm) {
                                margin-bottom: 0;
                            }

                        }

                    }

                }

            }

        }


        .allergene-disclaimer-description {
            font-size: 13px;
        }

        .allergene-disclaimer {
            font-family: $futura-light;
            font-weight:200;
            margin-bottom: 10px;
        }

        .ph-ingredients,
        .ph-allergens {
            font-family: $futura-light;
            font-weight:200;
        }

    }

    .select2-dropdown {
        border: none;
        border-radius: 0;
        @include box-shadow(3px 3px 13px 1px rgba($black, 0.1));

        .select2-results {

            .select2-results__options {
                padding: 15px;

                .select2-results__option {
                    font-family: $futura-heavy;
                    margin-bottom: 0;
                    padding: 15px;
                    text-align: right;

                    &--highlighted, &[aria-selected=true] {
                        background-color: #F7F7F7;
                        color: $black;
                    }

                }

            }

        }

    }

    .column {

        &.main {

            @media (max-width: $screen-max-md) {
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
            }

            @media (max-width: $screen-max-sm) {
                padding-bottom: 0;
            }

        }

    }

}

#mpquickview-popup {
    .product-info-main .product-add-form .actions #product-addtocart-button,
    .product-info-main .product-add-form .actions #product-updatecart-button {
        width: 100% !important;
    }
}

.page-product-amgiftcard {

    .product-info-main {

        .product-add-form {

            .fieldset-amgiftcard {
                display: block;
            }

            .field {

                &.field-card {

                    .label {
                        display: none;
                    }

                }

                &.focused {
                    .label {
                        @include transform(translateY(-100%));
                    }
                }

            }

        }

    }

    .amgiftcard-preview {
        display: none;
    }

}

.checkout-index-index,
.checkout-cart-index,
.catalog-product-view {

    /* UPSELL && CROSS SELL */
    .crosssell,
    .upsell {
        float: left;
        width: 100%;
        display: block;
        margin-top: 70px;

        & > .block-content {
            @media (min-width: $screen-min-lg) {
                margin: 0 -30px;
            }
        }

        .block-title {
            padding-bottom: 0px;
        }

        @media (max-width: $screen-max-sm) {

            .block-title {
                margin: 0;
                padding-bottom: 20px;
            }

            .products {
                margin-top: 0;
            }

        }

        .product-item-actions {
            margin-top: 20px;
        }

        .upsell-carousel {

            .product-item {
                &-info {
                    padding: 0;
                    .product-image-container{
                        border: 1px solid $lightgrey-2
                    }
                    .product-item-details .wrapper .price-container-ph {
                        .price {
                            display: block;
                            text-align: right;
                        }
                    }
                }
            }

            &.no-slider {
                display: flex;
                display: -webkit-flex;
                justify-content: space-around;

                @media (max-width: $screen-max-md) {
                    flex-direction: column;
                    align-items: center;
                }

                @media (max-width: $screen-max-sm) {
                    flex-direction: column;
                }

                .product-item {
                    margin: unset;

                    .shipping-container {
                        margin: 20px 0 0;
                    }

                    .shipping-container-block {
                        margin: 10px 0;
                    }

                    @media (max-width: $screen-max-md) {
                        margin: 0 auto 20px;

                        &-details {
                            margin-left: 0;
                        }

                    }

                    @media (max-width: $screen-max-sm) {

                        &-info {
                            flex-direction: column;
                        }

                    }

                }

            }

            .owl-stage-outer {
                overflow: hidden;

                .owl-stage, .owl-item {
                    height: 100%;
                }

                .owl-item {
                    float: left;

                    .product-item {
                        height: 100%;
                        width: 100%;
                        padding-right: 0;

                        @media (max-width: $screen-max-md) {

                            &-info {
                                flex-direction: column;
                                align-items: center;
                            }

                        }
                        @media (max-width: $screen-max-sm) {
                            &-info {
                                width: 90%;
                                margin: auto;
                            }
                        }


                        .product-item-name {
                            height: auto;
                        }

                    }
                }

            }

            .owl-nav {
                position: absolute;
                width: 100%;
                top: 30%;

                button {
                    position: absolute;
                    top: 0;
                    box-shadow: none;
                    background: transparent;
                    border: none;
                    font-size: 22px;
                }

                .owl-prev,
                .owl-next {
                    @include transition(all ease 0.4s);

                    &:hover {
                        color: $black;
                    }

                }

                .owl-prev {
                    left: -25px;
                    padding: 0;
                }

                .owl-next {
                    right: -25px;
                    padding: 0;
                }

            }

            .owl-dots {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;

                button {
                    margin-top: 20px;
                }

            }

            &.product-items {
                position: relative;

                .product-item {

                    .price-box {
                        border-bottom: 0;

                        .price-container {
                            display: flex;
                            align-items: center;
                        }

                        .price-label {
                            font-size: 12px;
                            letter-spacing: .42px;
                            line-height: 1.2;
                            margin-bottom: 0;
                            color: $black;

                            @media (max-width: $screen-max-sm) {
                                display: block;
                                font-size: 14px;
                                margin-bottom: 5px;
                            }

                        }

                        .price {
                            font-family: $futura-heavy;
                            font-size: 20px;
                            line-height: 1;
                            letter-spacing: .4px;
                            margin: 0;

                            @media (max-width: $screen-max-sm) {
                                display: block;
                            }
                        }
                    }

                }

            }

        }

        .ph-upsell {
            display: none;
        }

    }

}

.amgiftcard-image {
    width: 120px
}

.amgiftcard-image.-selected,
.amgiftcard-image:hover {
    border: none;
}


//Fix for image loading
.catalog-product-view {

    ._block-content-loading {
        img {
            padding-left: 0px;
            width: 100%;
        }
    }

}
