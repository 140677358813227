.catalogsearch-result-index {
    .page-main {
        position: relative;
        background-color: $white;
        padding-top: 25px;
        padding-bottom: 25px;
        max-width: 100%; // override container-ph

        @media (max-width: $screen-max-md){
            padding: 32px 15px;
            width: 100%;
            margin-top: 45px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 50px;
            background-color: #C72C48;
            left: 50%;
            top: 0;
            transform: translateY(-50%);

            @media (max-width: $screen-max-md) {
                top: -20px;
            }
        }

        .page-title-wrapper {
            text-align: center;
            text-transform: uppercase;

            @media (max-width: $screen-max-md){
                margin-bottom: 20px;
            }

            .page-title {
                margin-bottom: 0;

                span {
                    font-size: 25px;
                }
            }
        }

        .column.main {
            width: 100%;
            float: initial;
            padding-top: 0!important;

            .search.results {
                .block {
                    display: none;
                }
            }
        }
    }

}
