.toolbar-products {
    position: relative !important;
    min-height: 40px;
    margin: 0;
    padding: 20px 30px;
    width: auto !important;
    display: flex;

    .modes {
        margin: 0;
    }

    .toolbar-amount {
        display: none;
    }

    .toolbar-sorter {
        display: flex;
        align-items: center;

        .sorter-label {
            width: 100%;
            margin-right: 15px;
        }
    }

    @media (max-width: $screen-max-sm) {
        margin: 20px 15px 25px 15px;
    }

    @media (max-width: $screen-max-xs) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .toolbar-sorter {
            position: relative !important;
        }
    }
}

.toolbar-products,
.order-products-toolbar {
    .select2-container {
        min-width: 150px;
        margin-right: 15px;

        .select2-selection--single {
            border-radius: 0;
            height: auto;

            .select2-selection__arrow {
                bottom: 13px;
            }
        }
    }
}

.order-products-toolbar {
    .select2-container {
        min-width: auto;

        .select2-selection--single {
            .select2-selection__arrow {
                bottom: 10px;
            }
        }
    }
}
