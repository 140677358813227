.faq-page{

  #maincontent{
    width: 100%;
    margin: 0 auto;
  }

  .page-title-wrapper{
    margin-top: 30px;
  }

  // TODO: Voir pourquoi il y a des balises p qui apparaissent dans la page
  p {
    margin-bottom: 0;
  }

  .faq-title {
    max-width: $container;
    margin-left: auto;
    margin-right: auto;
  }
  .faq-content {
    max-width: $container;
    margin-left: auto;
    margin-right: auto;
  }

}

.faq-title{
  position: relative;
  font-family: $futura-heavy;
  font-size: 20px;
  margin: 25px 0 0;
  border-top: 1px solid #D3D3D3;
  border-left: 1px solid #D3D3D3;
  border-right: 1px solid #D3D3D3;
  padding-top: 34px;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 25px;
  text-align: center;
  text-transform: uppercase;

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 84%;
    height: 3px;
    background-color: #000;
    @include transform(translateX(-50%));
  }

  &+br{
    display: none;
  }

}

.faq-content{
  border-left: 1px solid #D3D3D3;
  border-right: 1px solid #D3D3D3;

  a{
    color: $black;
  }

  &:not(.last)+br{
    display: none;
  }

  &.last{
    border-bottom: 0;

    .faq-content-inner{
      border-bottom: none;
    }

    .answer{
      margin-bottom: 0;
    }

  }

  .faq-content-inner{
    width: 84%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(#979797, .37);

    &.open{

      h3{
        color: $black;
      }

    }

  }

  h3{
    cursor: pointer;
    position: relative;
    font-family: $futura-light;
font-weight:200;
    font-size: 20px;
    line-height: 2;
    margin: 0;
    padding: 25px 25px 25px 0;

    @media (max-width: $screen-max-sm){
      font-size: 16px;
    }

    i{
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 0;
      font-size: 11px;
      color: #000;
      @include transform(translateY(-50%));
      @include transition(transform .4s $easeOutQuad);

      &.add-multiwishlist {
        font-style: inherit;

        &:before {
          color: #000;
          font-size: 15px;
        }
      }
    }

    &.open{

      i{
        @include transform(translateY(-50%) rotate(180deg));

        &.add-multiwishlist:before {
          content: '\e60f';
        }
      }

    }

  }

  .answer{
    display: none;
    height: 0;
    overflow: hidden;
    font-family: $futura-light;
font-weight:200;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 25px;

    &.open{
      height: auto;
      display: block;
    }

  }

}
