.modalite-page{

  .page-main{
    max-width: 1140px;

  }
  .delivery-page{
    display: block;
    font-size: 0;

    @media (max-width: $screen-max-md){
      flex-direction: column;
    }

    .sidebar-main{
      position: absolute;
      width: 285px;
      display: inline-block;
      vertical-align: top;
      flex-grow: 0;
      flex-basis: auto;
      padding: 0;
      padding-right: 25px;
      margin-bottom: 80px;

      @media (max-width: $screen-max-md){
        position: relative;
        margin: 0 auto;
        display: none;
      }

      &.js-fixed {
        bottom: 0;
        mask-image: gradient(linear,left 70%, left bottom,from($black),to(transparent));
        -webkit-mask-image: -webkit-gradient(linear,left 70%, left bottom,from($black),to(transparent));
        @include transition(all .5s ease);

        &.js-mouseenter {
          mask-image: initial;
          -webkit-mask-image: initial;
        }
      }

      // simple bar lib
      .simplebar-track {
        &.simplebar-horizontal {
          display: none;
        }
      }

      .simplebar-content-wrapper {
        .simplebar-content {
          overflow-x: hidden;
        }
      }

      .content{
        font-family: $futura-light;
font-weight:200;
        padding: 0;
        background: none;
        position: sticky;
        top: 20px;

        @media (max-width: $screen-max-md){
          border: none;
          position: initial;
        }

        .item{
          position: relative;
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 6px;

          &::after{
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: rgba(#979797, .51);
            @include transition(height .3s $easeOutQuad, background-color .3s $easeOutQuad);
          }

          &:last-child{

            &::after{
              content: none;
            }

          }

          strong,
          a{
            padding: 20px 5px 25px;
            display: block;
            color: $black;
            border-left: none;
            @include transition(color .3s $easeOutQuad);

            &:hover{
              background: none;
            }

          }

          &.current{
            font-family: $futura-heavy;
          }

          &:hover,
          &.current{

            strong,
            a{
              color: $black;
            }

            &:after{
              height: 4px;
              background-color: $black;
            }

            &>strong{
              border: none;
            }

          }

        }

      }

      .block-collapsible-nav{

        @media (max-width: $screen-max-md){
          position: relative;
          top: auto;
          left: auto;
          margin: 0;
          background-color: #FFF;
          border: 1px solid #cccccc;
          cursor: pointer;
        }

        .title{

          @media (max-width: $screen-max-md){
            font-size: 13px;
            text-align: center;
            text-transform: uppercase;
            padding: 15px 40px 15px 15px;
            color: $black;
            height: auto;
            width: 100%;
            position: relative;
            display: block;
            border-bottom: 1px solid #cccccc;
            border-top: 1px solid #cccccc;

            &::after{
              font-size: 8px;
              content: $icon-arrow-down;
              font-family: $icomoon-font-family;
              position: absolute;
              top: 15px;
              right: 30px;
            }
          }


        }

        .content{

          @media (max-width: $screen-max-md){
            display: none;

            &.active{
              display: block;
            }

          }

        }

      }

    }

  }

  .column{
    padding: 30px;

    &.main{
      min-height: auto;
      padding: 10px;

      &.content{
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 285px);
        margin-left: 25%;
        padding-right: 18px;
        padding-top: 0;
        font-size: 16px;

        @media (max-width: $screen-max-md){
          width: 100%;
          margin: 0;
        }

        .p1{

          a{
            text-decoration: underline;
          }
        }
        h2{
          font-family: $futura-heavy;
          font-size: 20px;
          text-transform: uppercase;
          margin-top: 0;
          padding-top: 20px;
        }
        h3{
          margin: 30px 0;
        }

        h4{
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #585858;
        }


        p, ul>li, ol>li{
          color: #585858;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
        }

        ul>li{
          list-style-type: "- ";
        }

        a{
          color: $black;
        }

        table{
          margin: 20px 0;
          border: 1px solid $grey-widget;
          max-width: 840px;

          @media (max-width: $screen-max-sm) {
            display: block;
            tr, td, tbody {
              display: block;
              width: 100%;
            }
          }

          tr{
            border-bottom: 1px solid rgba($grey-widget, 0.3);

            &:first-child {
              @media (max-width: $screen-max-sm) {
                display: none;
              }
            }
          }

          &:not(:first-child){
            td{
              border-right:  1px solid rgba($grey-widget, 0.3);
              @media (max-width: $screen-max-sm) {
                border: none;
              }
                .s1{
                color: $black;
                font-family: $futura;
              }

              .p1{
                .s1{
                  font-family: $futura-light;
font-weight:200;
                  font-size: 14px;
                }

              }

            }

          }
        }
      }
    }


  }

}
