.checkout-index-index {
  .columns .column.main {
    @media (max-width: $screen-max-sm) {
      padding-bottom: 0;
    }
  }

  //.toast-notif {
  //    div[role="alert"] {
  //        display: none;
  //    }
  //}

  .field[name='shippingAddress.prefix'],
  .field[name='billingAddresscashondelivery.prefix'],
  .field[name='billingAddress.prefix'],
  .field[name='billingAddressmonetico_onetime.prefix'],
  .field[name='shippingAddress.custom_attributes.ph_btob_btoc'],
  .field[name='billingAddresscashondelivery.custom_attributes.ph_btob_btoc'],
  .field[name='billingAddress.custom_attributes.ph_btob_btoc'] {

    label {
      position: relative;
    }

  }

  .group-radio {

    .radio {
      margin-right: 18px;
    }

  }

  .return-back {
    width: 100%;

    @media (max-width: $screen-max-md) {
      padding: 20px;
    }

    .back-step-before {
      font-size: 26px;
      transition: all .3s;
      @media (max-width: $screen-max-md) {
        font-size: 20px;
      }
    }
  }

  .totals-tax-summary {
    th, td {
      border: none !important;
    }
  }

  .header {

    &.content {

      .logo {

        @media (max-width: $screen-max-sm) {
          font-size: 40px;
        }

      }

    }

  }

  .checkout-container {
    @media (max-width: $screen-min-sm) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: $screen-min-sm) {
      margin: 0;
    }

    .modal-custom {
      .action-close {
        display: none;
      }
    }
  }

  .page-main {
    margin-top: 0;
  }

  .cart-title {
    @extend .page-title-type;
    padding: 40px 15px 35px;

    @media (max-width: $screen-max-sm) {
      display: none;
    }

  }

}

.opc-estimated-wrapper {

  @media (max-width: $screen-max-sm) {
    display: none;
  }

}

.opc-progress-bar {
  display: flex;
  display: -webkit-flex;
  margin-bottom: 46px;

  @media (max-width: $screen-max-sm) {
    margin-bottom: 0;
  }

  .opc-progress-bar-item {
    flex: 1 1 auto;
    width: auto;
    cursor: default;

    @media (max-width: $screen-max-sm) {
      text-align: center;
    }

    &:before {
      top: 100%;
      height: 1px;
      background-color: rgba(#979797, .51);
      border: none;
      @include border-radius(0);
      @include transform(background-color .3s $easeOutQuad);
    }


    &._active {

      &:hover {
        cursor: pointer;
      }

      &:before {
        border: none;
      }

      & > span {
        font-family: $futura-heavy;
        font-weight: 300;
        opacity: 1;

        &:after {
          opacity: 1;
          @include transform(translateX(-50%));
        }

      }

    }

    & > span {
      position: relative;
      display: inline-block;
      width: auto;
      font-size: 13px;
      color: #000;
      opacity: 0.4;
      padding: 8px 30px 10px;
      text-transform: uppercase;
      @include transition(color .4s $easeOutQuad);

      @media (max-width: $screen-max-sm) {
        padding-left: 5px;
        padding-right: 5px;
      }

      &:before {
        content: none;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: auto;
        bottom: 0;
        left: 50%;
        width: 100%;
        margin: 0;
        @include transform(translateX(-50%) scale(0));
        opacity: 0;
        height: 4px;
        background-color: $black;
        @include border-radius(0);
        border: none;
        @include transition(opacity .3s $easeOutQuad, transform .3s $easeOutQuad)
      }

    }

  }

  @media (max-width: $screen-max-sm) {
    padding-top: 30px;
  }

}

#checkout {

  .opc-wrapper {
    @extend .summary-central-column;
    @media (max-width: $screen-max-sm) {
      order: 3;
    }
  }

}

#opc-shipping_method {
  border-top: 3px solid #000;

  .message.notice {
    padding: 0;
  }
}

#checkoutSteps {
  .select2-container {
    width: 100% !important;

    .select2-selection--single {
      padding: 8px;
      border-radius: 0;
      height: auto;
    }

    .select2-selection__arrow {
      right: 10px;
      bottom: 13px;
    }
  }

  #shipping {
    border-top: 3px solid #000;
    padding-top: 30px;

    @media (max-width: $screen-max-sm) {
      padding-top: 30px;
      margin-top: 20px;
    }

    .actions-toolbar {
      margin-top: 40px;
    }

    .checkout-shipping-address-sentence {
      padding-bottom: 10px;
    }

  }

  .addresses {
    margin-bottom: 25px;

    @media (max-width: $screen-max-sm) {
      margin-bottom: 18px;
    }

  }

  .step-content {
    margin: 0 0 54px;

    hr {
      display: none;
    }

    @media (max-width: $screen-max-sm) {
      margin-bottom: 26px;
    }

    .form.payments {
      & > fieldset {
        min-inline-size: unset;
      }
    }

    .form-login {
        border-bottom: 0;
        width: 100%;
        max-width: 100%;
        padding-bottom: 0;
        margin-bottom: 0;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background-color: $black;
        }

        #customer-email-fieldset {
            max-width: 500px;
        }

      @media (max-width: $screen-max-sm) {
        background-color: $white;
      }

      & + .form-shipping-address {
        @media (max-width: $screen-max-sm) {
          background-color: $white;
        }

        .street {
          .control {
            label {
              border: 0;
              clip: unset;
              height: unset;
              margin: unset;
              overflow: auto;
              padding: unset;
              width: unset;
            }
          }
        }
      }

      .field-tooltip-content {
        background-color: $white;

        &::after {
          border-right-color: $white;
        }
      }

      .actions-toolbar {
        display: flex;
        align-items: center;

        .primary {
          font-size: 12px;
          min-height: 50px;
          margin-right: 15px;
        }
      }

      .hidden-fields {
        margin-top: 35px;
      }
    }

    .delivery-information {

      .error-delivery {
        .date-block {
          input {
            &._has-datepicker {
              &.datepicker {
                border-color: $black;
              }

            }
          }
        }

        input {
          border: 1px solid $pink;
        }

        .select2 {

          .select2-selection--single {
            border-color: $pink;
          }

        }

        .error-message {
          margin-top: 20px;
          color: $pink;
          font-size: 12px;
        }

      }

      .delivery-time-date {
        padding: 40px 0;
        border-bottom: 4px solid $black;

        @media (max-width: $screen-max-sm) {
          padding: 10px 0;
        }

        .store-infos {
          margin-bottom: 20px;

          .adresse-retrait {
            font-family: $futura-heavy;
            margin: 25px 0 20px;
          }

          p {
            font-family: $futura-light;
font-weight:200;
            line-height: .5;
          }
        }

        @media (max-width: $screen-max-sm) {
          .d-flex {
            flex-direction: column;
          }
        }

        .delivery-date {
          vertical-align: top;
          @media (max-width: $screen-max-sm) {
            width: 100%;
          }

          .date-block {
            position: relative;
            @media (max-width: $screen-max-sm) {
              width: 100%;
            }

            #ph-delivery-date-coursier.disabled, #ph-delivery-date-coursier.disabled + button {
              pointer-events: none;
              color: gray;
            }

            .ui-datepicker-trigger {
              position: absolute;
              cursor: pointer;
              top: 50%;
              right: 10px;
              font-size: 18px;
              color: #999;
              @include transform(translateY(-50%));
              margin-top: -1px;
              border: 0;
              background: transparent;
              padding: 0;

              &:before {
                content: $icon-calendar;
                font-family: $icomoon-font-family;
                font-size: 18px;
              }

            }

            input {
              padding: 22px;
              width: 100%;
              font-family: $futura-heavy;
              font-size: 12px;
              border: 1px solid #9c9c9c;
              transition: all .3s;
              cursor: pointer;
              vertical-align: middle;

              &:focus {
                box-shadow: none;
                border: 1px solid #000;
                transition: all .3s;
              }
            }

            i {
              position: absolute;
              right: 20px;
              top: 13px;
              cursor: pointer;
            }
          }
        }

        .delivery-time {
          vertical-align: top;
          @media (max-width: $screen-max-sm) {
            width: 100%;
          }
        }


        .delivery-date-cc {
          margin-bottom: 0;
          @media (max-width: $screen-max-sm) {
            margin-bottom: 20px;
          }
        }

        .delivery-storeslist {
          display: inline-block;
          width: 48%;
          margin-bottom: 0;
          @media (max-width: $screen-max-sm) {
            width: 100%;
          }

          .field {
            margin-bottom: 0;
          }
        }
      }

      .delivery-comment {
        margin: 40px 0 20px;
        width: 100%;

        .switch {
          margin-bottom: 20px;
        }

        input, textarea {
          font-size: 14px;
          color: rgba($black, 0.4);
          font-family: $trade-gothic-light;
          resize: none;

          &::placeholder {
            color: rgba($black, 0.4);
            font-size: 14px;
          }
        }

        textarea {
          box-shadow: 0 1px 0 0 #000;
          transition: box-shadow 150ms ease-out;
          padding: 12px 0 0;

          &.mage-error {
            box-shadow: none;
            border-bottom: 1px solid $pink;
          }
        }

        .delivery-comment-infos {
          display: flex;
          justify-content: space-between;
          @media (max-width: $screen-max-sm) {
            flex-direction: column;
          }

          div {
            width: 48%;
            @media (max-width: $screen-max-sm) {
              width: 100%;
            }
          }
        }

        .delivery-comment-message {
          textarea::placeholder {
            font-size: 12px;
            padding-top: 7px;
          }

          .special-characters {
            font-size: 12px;
            font-weight: bold;
            color: #999;
            font-style: italic;
          }
        }

        .delivery-comment-buttons {
          display: flex;
          justify-content: flex-end;
          margin: 30px 0;
          @media (max-width: $screen-max-sm) {
            flex-direction: column;
          }

          .cta {
            cursor: pointer;

            &:first-child {
              @media (max-width: $screen-max-sm) {
                margin-right: 0;
                margin-bottom: 20px;
              }
            }
          }
        }

        .delivery-comment-add {
          span {
            margin-right: 5px;
          }

          .delivery-comment-person {
            display: flex;
            margin-bottom: 30px;

            div {
              display: flex;

              &:last-child {
                margin-left: 100px;
              }
            }
          }

          .delivery-comment-content {
            word-break: break-all;
          }

        }

      }
    }

    .select2-selection {
      border: 0;
      min-height: 41px;
      border-bottom: 1px solid $black;
      padding-left: 0;
      border-radius: 0;

      .select2-selection__rendered {
        padding-left: 0;
        color: $black;
      }

    }

  }

  .step-title {
    font-family: $futura-heavy;
    font-size: 15px;
    border: none;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media (max-width: $screen-max-sm) {
      margin-bottom: 17px;
    }

  }

  .shipping-address-item {
    cursor: pointer;
    font-family: $trade-gothic-light;
    @extend .classic-address-box;
    @include transition(border .3s $easeOutQuad);

    @media (max-width: $screen-max-sm) {
      padding: 50px 15px 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: none;
    }

    address {
      display: flex;

      @media (max-width: $screen-max-sm) {
        flex-direction: column;
      }

      .col {
        display: block;
        min-height: initial;
        padding: 0 5px;
        width: 100%;
        flex-grow: 1;

        &.grow {
          flex-grow: 2;
        }

        &.phone {
          max-width: 170px;
        }

        &.name {
          max-width: 215px;
        }

        @media (max-width: $screen-max-sm) {
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          line-height: 20px;
          font-size: 14px;
        }
      }
    }


    &.selected-item {
      border: 1px solid #000;

      &:after {
        content: none;
      }

      .action {

        &.action-select-shipping-item {
          visibility: visible;
          border-color: $black;
          @media (max-width: $screen-max-sm) {
            display: block;
          }

          &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            @include transition(all ease 0.4s);
            @include transform(translateY(-50%) translateX(-50%));
            width: 12px;
            height: 12px;
            background-color: $black;
            border-radius: 50%;
            top: 11px;
            left: 11px;


            @media (max-width: $screen-max-sm) {
              top: 5px;
              left: 5px;
              @include transform(none);
            }

          }

        }

      }

    }

    a {
      color: #000;
    }

    .action {

      &.edit-address-link {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 20px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.4);

        &:before {
          display: none;
        }

        i {
          transition: all .3s;
        }


        @media (max-width: $screen-max-sm) {
          top: 15px;
          margin: 0;
        }
      }

      &.action-select-shipping-item {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 10px;
        bottom: auto;
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        background-color: $white;
        border: 1px solid #ccc;
        @include border-radius(50%);
        @include transform(translateY(-50%));

        @media (max-width: $screen-max-sm) {
          left: 25px;
          top: 16px;
          @include transform(none);
        }

        span {
          display: none;
        }

      }

    }

  }

  .action-show-popup {
    margin-bottom: 0;
    padding: 0 30px;
    @media (max-width: $screen-max-sm) {
      width: 100%;
    }

    span {
      &:before {
        content: none;
      }
    }
  }

  .payment-group {

    @media (max-width: $screen-max-sm) {
      //padding: 0 20px;
    }

    .step-title {
      font-size: 15px;
      margin-bottom: 0;
      padding-bottom: 0;

      @media (max-width: $screen-max-sm) {
        padding: 0;
      }

    }

  }

}

#opc-shipping_method {
  padding-top: 20px;

  @media (max-width: $screen-max-sm) {
    padding-top: 10px;
  }

  .methods-shipping {

    @media (max-width: $screen-max-sm) {
      margin-top: 20px;
      background-color: #FFF;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    .select2-selection {
      min-height: 42px;
      border: 1px solid #9c9c9c;
      padding-left: 10px;

      .select2-selection__rendered {
        padding-left: 0;
        font-family: $futura-heavy;
        color: $black;
      }
    }

    .delivery-time {

      .select2-selection {
        border: 1px solid #9c9c9c;
      }

    }

    .ph-shipping-message a {
      text-decoration: underline;
    }

  }

}

#shipping-method-buttons-container {

  .btn {
    min-width: 300px;

    @media (max-width: $screen-max-sm) {
      width: 100%;
      min-width: 0;
    }

  }

}
.message-checkout-shipping-method {
    padding-bottom: 30px;
    border-bottom: 4px solid $black;
}
.table-checkout-shipping-method {
  width: 100%;
  padding: 20px 0;

  @media (max-width: $screen-max-sm) {
    margin-bottom: 28px;
  }

  .step-content {
    margin-bottom: 40px;

  }

  tbody {

    tr {

      @media (max-width: $screen-max-sm) {
        position: relative;
      }


      td {
        border-top: none;
        vertical-align: middle;
        @media (max-width: $screen-max-sm) {

          &:first-child {
            padding-top: 40px;
            width: 35px;
          }

        }

        .radio {
          position: relative;

          label {
            position: absolute;
            top: 50%;
            transform: translateY(-35%);
            height: 20px;

            &:before {
              width: 22px;
              height: 22px;
              background-color: #FFF;
            }

            &:after {
              top: 5px;
              left: 5px;
              width: 12px;
              height: 12px;
            }

          }

        }

        &:nth-child(2) {
          position: relative;
          padding-left: 60px;
          /*padding-top: 6px;
          padding-bottom: 40px;*/

          @media (max-width: $screen-max-sm) {
            padding-left: 5px;
            padding-bottom: 10px;
          }

          i {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;

            @media (max-width: $screen-max-sm) {
              position: relative;
              top: auto;
              left: auto;
              display: block;
              transform: translateY(-35%);
              margin: 10px auto 0 0;
            }

          }

        }

        &.col-price {
          width: 170px;
          text-align: right;

          .price {
            font-family: $futura-heavy;
            font-size: 16px;
            font-weight: 400;
          }

          @media (max-width: $screen-max-sm) {
            width: 80px;
            padding-right: 0;
          }

        }

        &.col-method {
          cursor: pointer;

          label {
            cursor: pointer;
          }
        }

        & > .title {
          font-size: 18px;
          margin-bottom: 4px;

          .field-tooltip {
            display: inline-block;
            right: unset;
            top: unset;
            margin-bottom: 10px;

            &.shipping {
              display: block;
              position: relative;
            }

            .field-tooltip-label {
              font-size: 13px;
              font-style: italic;
              text-decoration: underline;
            }

            .field-tooltip-action:before {
              color: #000;
              content: '\e60c';
              font-size: 16px;
              //vertical-align: sub;
            }

            .icon-gift-box-with-a-bow:before {
              font-size: 16px;
            }

            @media (max-width: $screen-max-sm) {
              .field-tooltip-content {
                right: auto;
                left: 10px;

                &:before {
                  left: 10px;
                  right: auto;
                }

                &:after {
                  left: 10px;
                  right: auto;
                }
              }
            }
          }
        }

        & > p {
          font-size: 14px;
          font-style: italic;
          line-height: 1.6;
        }

        & > i {
          font-size: 38px;

          &.icon-home-shipping {
            font-size: 32px;
          }
        }

      }


    }

  }

}

.opc-sidebar {
  width: 30%;
  margin-top: 0;
  z-index: 10;

  @media (max-width: $screen-max-sm) {
    position: relative;
    display: block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    visibility: visible;
    opacity: 1;
    order: 2;
  }

  .modal-inner-wrap {

    @media (max-width: $screen-max-sm) {
      @include transform(none);
      @include box-shadow(none);
      background-color: transparent;
    }

  }

}

.opc-block-summary {
  @extend .summary-sidebar-container;
  @extend .summary-title;
  width: 100%;
  margin-bottom: 26px;
  @extend .summary-table-elements;

  @media (max-width: $screen-max-sm) {
    padding: 30px 20px 15px;
    margin-bottom: 0;
  }

  .totals {
    &.discount {
      .title {
        border-bottom: none;
      }
    }

    &.grand {

      .amount,
      .mark {
        padding-top: 58px;
      }

    }

  }

}

#checkoutSteps {
  .checkout-payment-method {

    .payment-methods {

      @media (max-width: $screen-max-sm) {
        margin: 0;
      }

    }

    .payment-method {

      .payment-method-title {
        padding: 10px 0 0;
        margin-bottom: 30px;
        position: relative;

        @media (max-width: $screen-max-sm) {
          padding: 0;
          margin-bottom: 20px;
        }

        .radio {
          box-shadow: none;
          width: 0;
          height: 0;
          visibility: hidden;

          + label {
            font-family: $futura-light;
font-weight:200;
            color: $black;
            text-transform: uppercase;
            position: relative;
            display: flex;
            align-items: center;

            &:before {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              border: 1px solid $lightgrey;
              border-radius: 50%;
              margin-right: 20px;
              transition: all .3s ease;
            }

            &:after {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              background-color: transparent;
              border-radius: 50%;
              position: absolute;
              left: 6px;
              top: 50%;
              transform: translateY(-50%);
              transition: all .3s ease;
            }
          }

          &:checked {
            + label {
              &:before {
                border-color: $black;
              }

              &:after {
                background-color: $black;
              }
            }
          }
        }

        .allowed-payment-icon {
          order: 3;
          position: absolute;
          right: 0;
        }

        @media (max-width: $screen-max-xs) {
          margin-bottom: 50px;
          .allowed-payment-icon {
            bottom: -42px;
            left: 0;
          }
        }
      }

      .payment-method-content {
        padding: 0;
        padding-right: 15px;
        margin-bottom: 40px;

        @media (max-width: $screen-max-sm) {
          padding: 0;
        }

        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin: 0 10px 0 0;

          &:after {
            width: 12px;
            height: 12px;
            top: 3px;
            left: 3px;
          }

          & + label {
            font-size: 13px;
            vertical-align: middle;
            cursor: pointer;
          }

        }

        .primary {
          width: 100%;
          text-align: left;

          .btn {
            min-width: 310px;
            max-width: 100%;

            &.action-cancel {
              margin-top: 0;
            }

            @media (max-width: $screen-max-sm) {
              min-width: 0;
              width: 100%;
            }

          }

        }

        .form-integrated {
            width: 100%;

            .input-container {
                width: 100%;
            }
            .exp-cvv-container > div {
                width: 50%;

                .input-container {
                    width: 100%;
                }
            }
            @media (max-width: $screen-max-sm) {
                width: 100%;

                .input-container {
                    width: 100%;
                }
                .exp-cvv-container > div {
                    width: 50%;

                    .input-container {
                        width: 100%;
                    }
                }
            }
        }
      }

      .billing-address-same-as-shipping-block {
        margin-bottom: 22px;
      }

      .checkout-billing-address {

        .actions-toolbar {
          .btn {
            min-width: 200px;
          }

          .action-cancel {
            @extend .btn;
            @extend .btn-black;
          }
        }

        .billing-address-details {
          @extend .classic-address-box;
          padding: 18px 35px 18px 35px;
          @extend .address-box-actions;

          .box-actions {
            right: 10px;
          }

          .action {
            background: none;
            border: none;
          }

        }

        @extend .address-tree-cols;

      }

      .field-select-billing {
        max-width: 100%;
        margin-bottom: 50px;

        .select2-selection {
          border: 1px solid #ccc;
          padding: 7px;

          .select2-selection__rendered {
            color: $black;
          }
        }
      }

      .billing-address-form {
        max-width: 100%;

        .fieldset:first-child {
          > .field:first-child {
            label {
              position: relative;
            }
          }
            >.field:nth-child(2) {
                label {
                    position: relative;
                }
            }
        }
      }

      // Agreements
      .checkout-agreements-block {
        .checkout-agreement {
          display: flex;
          flex-wrap: wrap;

          label.label {
            display: flex;
            align-items: center;
            width: 90%;
            font-family: FuturaHeavy, Arial, sans-serif;
            font-size: 14px;
            color: $black;

            p {
              margin-bottom: 0;
            }

            a {
              text-decoration: underline;
            }
          }

          .mage-error {
            font-family: FuturaHeavy, Arial, sans-serif;
            font-size: 1.4rem;
          }
        }
      }

    }

    .form-discount {
      .actions-toolbar .primary {
        .action.action-cancel {
          border-radius: 0;
          color: $white;
          border-color: $black;
          background: $black;
          margin: 0;

          &:not(:focus) {
            box-shadow: none;
          }
        }
      }
    }

    .cart-container {

      .gift-payment,
      .cart-discount {
        width: 100%;

        @media (max-width: $screen-max-sm) {
          margin: 0;
          padding: 0;
        }

      }

      .form-discount {
        width: 100%;
        max-width: 565px;
      }

    }

    .payment-option {
      .payment-option-title {
        border: none;
      }
    }
  }
}


.opc-block-shipping-information {
  padding: 0;

  @media (max-width: $screen-max-sm) {
    padding: 30px 20px 0;
  }

  .ship-to,
  .ship-via {
    margin: 0 0 24px;

    .shipping-information-title {
      font-family: FuturaHeavy, Arial, sans-serif;
      font-size: 15px;
    }

    &.opened {
      .icon-arrow-down {
        transform: rotate(180deg);
        transition: all .4s;
      }
    }

    &:not(.opened) {
      .shipping-information-content {
        display: none;
      }
    }
  }

  .ship-via {
    padding-top: 30px;
    border-top: 3px solid #000;

    .shipping-information-title {
      margin-bottom: 15px;
      position: relative;
    }

    .shipping-information-content {
      align-items: center;
      display: flex;
    }
  }


  .shipping-information-title {
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    margin-bottom: 0;
    padding-bottom: 6px;

    i {
      position: absolute;
      right: 10px;
      top: 5px;
      font-size: 10px;
      transition: all .4s;
    }

    .action-edit {
      display: none;
    }
  }

  .shipping-information-content {
    font-size: 14px;
    font-family: $trade-gothic-light;

    a {
      color: #000;
    }

    i {
      font-size: 36px;
      margin-right: 20px;
    }

  }

}

//
//  CHECKOUT PAGE (SUCCESS)
//
.checkout-onepage-success {

  .page-main {
    margin-top: 0;
  }

  .page-title-wrapper {
    position: relative;
    @extend .page-title-type;

    @media (max-width: $screen-max-sm) {
      padding-top: 22px;
      padding-bottom: 10px;
    }

    .page-title {

      &:before {

        @media (max-width: $screen-max-sm) {
          margin-bottom: 10px;
        }

      }

    }

  }

  .checkout-success {
    width: 83.333333%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 45px;

    @media (max-width: $screen-max-sm) {
      width: 100%;
      padding: 0 15px;
      border: none;
      margin-bottom: 6px;
    }

    & > p {

      @media (max-width: $screen-max-sm) {
        line-height: 1.25;
      }

    }

    .intro {
      max-width: 450px;
      font-family: $futura-heavy;
      font-size: 20px;
      text-transform: uppercase;
      margin: 0 auto 26px;

      @media (max-width: $screen-max-sm) {
        line-height: 1.2;
        margin-bottom: 21px;
      }

      a {
        color: #000;
      }

    }

    .confirmation-icon {
      position: relative;
      display: block;
      width: 66px;
      height: 66px;
      margin: 0 auto 20px;
      @include border-radius(50%);
      background-color: #000;

      @media (max-width: $screen-max-sm) {
        margin-bottom: 28px;
      }

      i {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        font-size: 20px;
        @include transform(translateY(-50%) translateX(-50%));

        &:before {
          color: #FFF;
        }

      }

    }

    .actions-toolbar {
      text-align: center;
      margin-top: 25px;

      @media (max-width: $screen-max-sm) {
        margin-top: 18px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(#979797, .43);
      }

    }

    .primary {
      width: 100%;
      text-align: center;
      padding: 0 20px;

      .btn {
        float: none;
        display: inline-block;

        @media (max-width: $screen-max-sm) {
          width: 100%;
        }

      }

    }

  }

  .print {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    margin: 0;
    color: #000;
    text-transform: uppercase;
  }

  #registration {
    padding-top: 20px;
    text-align: center;
    border-top: 1px solid $lightgrey;

    @media (max-width: $screen-max-sm) {
      padding: 0 15px;
    }

    div {

      p {

        @media (max-width: $screen-max-sm) {
          line-height: 1.25;
        }

        &:first-child {
          font-family: $futura;
          font-size: 14px;
          margin-bottom: 4px;

          @media (max-width: $screen-max-sm) {
            font-family: $futura-light;
font-weight:200;
          }

        }

      }

      .btn {
        margin-top: 12px;

        @media (max-width: $screen-max-sm) {
          margin-top: 3px;
        }

      }

    }

  }

}

#checkout-step-payment {

  @media (max-width: $screen-max-sm) {
    margin-top: 0;
    padding-top: 30px;
  }

}

.table-totals, table.totals {
  tr {
    &:nth-last-child(2) {
      th, td {
        border: none !important;
      }
    }
  }

  .grand.totals {
    th, td {
      border: none !important;
    }
  }

  .custom-shipping {
    font-style: italic;
  }
}

#checkout {
  .amgiftcard.amcard-codes-list {
    margin-right: 20px;
    border-bottom: 3px solid #000;

    .amgiftcard-info {
      margin-bottom: 38px
    }

    @media (max-width: $screen-max-sm) {
      margin-right: 0;
    }
  }
}
