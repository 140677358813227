.navigation{

    li{

        @media (max-width: $screen-max-sm){
            font-size: 12px;
        }

        &.level0 {

            @media (max-width: $screen-max-md){
                position: relative;
                font-size: 12px;
                border-bottom: 0;
                margin: 0;
            }

            .all-category{

                .ui-state-focus{
                    text-transform: uppercase;
                    padding: 20px 50px 23px 30px;
                }
            }

            &.active {



                @media only screen and (max-width: 767px) {

                    & > a:not(.ui-state-active) span:not(.ui-menu-icon) {
                        margin-left: 0;
                    }

                    .all-category{
                        background-color: $white;
                        .ui-state-focus{
                            border: none;
                            padding: 20px 50px 23px 30px;
                        }
                    }

                }

            }

            &.has-active{

                &> a{

                    @media (max-width: $screen-max-sm) {

                        &:not(.ui-state-active) {
                            color: $pink;
                            border: none;
                        }

                    }

                }

            }

            @media (max-width: $screen-max-md) {

                &.parent .level-top:after{
                    position: absolute;
                    z-index: 1;
                    right: 6%;
                    top: 50%;
                    @include transform(translateY(-50%));
                    font-family: $icomoon-font-family;
                    font-weight: 900;
                    font-size: 14px;
                    content: $icon-arrow-right;
                }
            }

            &> .level-top{
                line-height: 48px;

                @media (max-width: $screen-max-md){
                    line-height: 1;
                    border: none;
                    width: 100%;
                    padding: 10px 0 !important;

                    span {
                        font-family: $futura-light;
                    }

                    &.ui-state-active{
                        background-color: rgba(#D8D8D8, .13);

                        &+ul{
                            //display: block!important;
                            pointer-events: auto;
                            @include transform(translateX(100%));
                        }

                    }

                }

            }

            &.level-top{

                .submenu{

                    @media (max-width: $screen-max-md){
                        position: fixed;
                        z-index: 2;
                        top: 0!important;
                        right: 100%;
                        background-color: #FFF;
                        pointer-events: none;
                        width: 100vw;
                        height: 100vh;
                        padding: 0;
                        padding-top: 67px;
                        min-width: 100% !important;
                        border-top: none;
                        background-color: #FFF;
                        @include transition(transform .3s $easeOutQuad);

                        &:before{
                            content: $icon-arrow-left;
                            display: block;
                            width: 100%;
                            font-family: $icomoon-font-family;
                            font-size: 14px;
                            padding: 20px 50px 23px 30px;
                            position: initial;
                            display: flex;
                            align-items: center;
                            color: $pink;
                            font-weight: bold;
                        }

                        &>li{
                            border-bottom: 1px solid rgba(#979797, .37);
                            width: 100%;

                            &:after{
                                content: none;
                            }

                            a{
                                padding: 20px 50px 23px 30px;
                                &:visited{
                                    color: initial;
                                }
                            }

                        }

                    }

                }

            }

        }

    }

    .submenu{

        .level1{

            //&:nth-child(7),
            //&:nth-child(8),
            //&:nth-child(9),
            //&:nth-child(10),
            //&:nth-child(11),
            //&:nth-child(12){
            //  display: none;
            //}

            &.parent{

                ul{
                    display: none;
                    align-items: flex-start;
                    justify-content: flex-start;
                    //min-width: 100%!important;
                }

            }

        }

        &:not(:first-child){

            @media (max-width: $screen-max-md) {
                font-weight: 400;
                line-height: 1.3;
                left: auto !important;
                overflow-x: hidden;
                padding: 0;
                position: relative;
                top: auto !important;
                transition: left 0.3s ease-out;
            }
            .active{

                a{
                    color: $pink;
                    border: none;
                }

            }

            .level1{

                &.active{

                    & > a{

                        @media (max-width: $screen-max-sm){
                            padding-left: 30px;
                        }

                    }

                }

            }

        }

    }

}

.nav-sections{

    @media (max-width: $screen-max-md){
        overflow: auto;
        position: fixed;
        top: 0;
        transition: left 0.3s;
        height: 100%;
        width: 100%;
        left: -100%;
        background-color: transparent;
    }



    .nav-sections-item-title{

        @media (min-width: $screen-min-sm), print {
            display: block;
        }

        @media (min-width: $screen-min-md), print {
            display: none;
        }

        @media (max-width: $screen-max-md){
            display: none;
        }

    }

    .nav-sections-items{

        @media (max-width: $screen-max-md) {
            position: relative;
            z-index: 1;
            padding-top: 66px;
            float: none;

            .block-search{
                position: relative;
                display: block;
                width: 100%;
                max-width: unset;
                background: rgba(#D8D8D8, 0.2);
                padding: 15px;
                float: none;
                margin: 0;
                z-index: unset;

                .field {
                    margin-bottom:0;
                }
                .label{
                    display: none;
                }

                .control{
                    border: none;
                }
                input{
                    background: transparent;
                    color: $black;
                    font-size: 12px;
                    left: 0;
                    margin: 0 0 0 15px;
                    position: relative;
                    padding: 10px 10px 10px 25px;
                    box-shadow: none;

                    &::placeholder{
                        color: $black;
                        font-size: 12px;
                    }
                }

                .actions{
                    .search{
                        top: 11px;
                        border: none;
                        &:before{
                            content: $icon-search;
                            font-family: $icomoon-font-family;
                            font-weight: 900;
                            font-size: 17px;
                            color: $black;
                            @media (max-width: $screen-max-sm) {
                                content: $icon-search;
                                display: block;
                                font-size: 15px;
                            }
                        }
                        @media (max-width: $screen-max-sm) {
                            position: absolute;
                            display: block;
                            background: transparent;
                            top: 21px;
                            span{
                                display: none;
                            }
                        }



                        &[disabled]{
                            color: $black;
                            opacity: 1;
                        }
                    }


                }
            }
        }

    }



    .nav-sections-item-content{
        @media (max-width: $screen-max-md) {
            box-sizing: border-box;
            float: none;
            width: 100%;
            margin: 0;
            padding: 0;
            display: block!important;
            padding: 16px 44px 9px 44px;
            border-top: 1px solid rgba(0, 0, 0, .14);
        }

        .switcher-language{

            @media (max-width: $screen-max-md){
                display: flex;
                padding-top: 0;
            }
        }

        @media (max-width: $screen-max-md){
            padding: 0;
            border-top: 0;

            .navigation{

                ul{
                    display: block;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 18px 50px;

                    & > li{
                        position: relative;
                        border-top: none;
                        width: 100%;
                        border-top: none;
                        font-size: 14px;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &.all-category{
                            border-top: 0;
                        }

                    }

                }

            }

        }

    }

}



.nav-open {

    .nav-toggle{

        &:after{

            @media (max-width: $screen-max-md){
                background-color: #FFF;
                z-index: 0;
            }

        }

    }

    @media (max-width: $screen-max-md){

        .nav-sections{
            z-index: 12;
        }

        .page-wrapper{
            left: 0;

            .sidebar-account {
                position: fixed;
                top: 0;
                right: -100vw;
                height: 100vh;
                width: 676px;
                z-index: 13;
                background-color: $white;
                transition: all .3s ease-in;
                overflow-y: scroll;

                &.active {
                    right: 0;
                }

                @media (max-width: $screen-max-md) {
                    width: 100%;
                    right: -100%;
                }

                @media (max-width: $screen-max-sm) {
                    padding-bottom: 60px;
                }

                .sidebar-header {
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 40px;

                    .sidebar-title {
                        font-size: 20px;
                        color: $pink;
                        margin: 0;
                    }

                    i {
                        cursor: pointer;
                    }

                }

                .sidebar-body {
                }
            }
        }

        /*.logo,
        .header-container-settings{
          opacity: 1;
        }*/

    }

    .burger{

        @media (max-width: $screen-max-md){

            span{

                &:first-child{
                    top: 50%;
                    @include transform(rotate(-45deg));
                }

                &:nth-child(2){
                    opacity: 0;
                }

                &:last-child{
                    top: 50%;
                    @include transform(rotate(45deg));
                }

            }

            &:after{
                content: none;
            }

        }

    }

    .nav-sections{

        @media (max-width: $screen-max-md){
            left: 0;
        }

    }

}
/*
  switcher
*/
.nav-sections{

    @media (max-width: $screen-max-md){
        background-color: #FFF;
        z-index:12;
    }

    .switcher{

        @media (max-width: $screen-max-md){
            display: flex;
            padding: 12px 0 0;
            font-family: $futura-light;
            font-size: 14px;
            font-weight: 300;
            border: none;
            background: $white;

            &:last-child{
                border: none;
            }

        }

        .switcher-label{

            @media (max-width: $screen-max-md){
                position: relative;
                font-weight: 300;
                text-transform: uppercase;
                margin: 0;
                background-color: $black;
                color: $white;
                display: none;

                a{
                    color: $white;
                }
                span{
                    opacity: 0.6;

                }
            }

        }

        .switcher-label,
        .switcher-options{

            @media (max-width: $screen-max-md){
                background-color: $white;
                color: $black;
                a{
                    color: $black;
                }
                position: relative;
                padding: 0 44px;
                width: 100%;
                border-bottom: 0;

                &.dropdown{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                }
            }

        }

        .switcher-trigger{

            @media (max-width: $screen-max-md){

                strong{
                    font-weight: 300;
                    position: relative;
                    display: block;
                    opacity: 1;
                    &:after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        line-height: inherit;
                        color: inherit;
                        content: $icon-arrow-down;
                        font-family: $icomoon-font-family;
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                        font-size: 5px;
                        right: -15px;
                        top: 5px;
                        position: absolute;
                        @include transition(all ease 0.5s);
                    }
                }

                &.active{
                    strong{
                        &::after{
                            @include transform(rotate(180deg));
                        }
                    }
                }

            }

        }




        .switcher-dropdown{

            @media (max-width: $screen-max-md){
                margin-top: 12px;
                margin-bottom: 0;
                padding: 0;
                list-style: none none;
                display: none;



                li{
                    padding: 10px 0;
                    line-height: 1;
                    margin: 0;
                    @include transition(all ease 0.4s);

                }

                a{
                    color: $black;
                    opacity: 0.6;

                    &:hover,
                    &:visited,
                    &:link{
                        color: $black;
                    }

                }

            }

        }

    }

}

.burger{

    @media (max-width: $screen-max-md){
        display: block;
        position: fixed;
        z-index: 14;
        top: 20px;
        width: 17px;
        height: 20px;
        left: 20px;

        &:before{
            content: none;
        }

    }

    span{
        clip: auto;
        position: absolute;
        z-index: 1;
        width: 15px;
        height: 1px;
        left: 2px;
        backface-visibility: hidden;
        background-color: #000;
        @include transition(transform .25s $easeOutQuad, top .25s $easeOutQuad, width .2s $easeOutQuint, opacity .25s $easeOutQuad);

        &:first-child{
            top: 5px;
        }

        &:nth-child(2){
            top: 10px;
        }

        &:last-child{
            top: 15px;
        }

    }

}

.menu-sup-mob{
    display: none;
}
@media (max-width: $screen-max-md) {
    .nav-sections .switcher-options.active .switcher-dropdown {
        display: block;
    }

    .menu-sup-mob{
        display: block;
        .title-cat-menu{
            padding: 20px 50px 23px 30px;
            text-transform: uppercase;
            z-index: 1;
            width: 100%;
            display: flex;
            background-color: $white;
            color: $black;
            position: relative;
            border-bottom: 0;
            font-size: 12px;
            letter-spacing: .63px;
            font-family: $futura;
            &::after{
                position: absolute;
                z-index: 1;
                right: 6%;
                top: 50%;
                @include transform(translateY(-50%));
                font-family: $icomoon-font-family;
                font-weight: 900;
                font-size: 14px;
                content: $icon-arrow-right;
                color: $black;
            }
        }
        li{
            position: relative;
            font-size: 14px;
            margin: 0;
            font-family: $futura-light;
            letter-spacing: .63px;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            list-style: none;
            padding: 8px 0;
            display: block;

            a{
                display: block;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;

                text-decoration: none;
                background-image: linear-gradient(rgba(0, 0, 0, 0.59), rgba(0, 0, 0, 0.59));
                background-repeat: no-repeat;
                background-position: bottom left;
                background-size: 0% 1px;
                transition: background-size .2s ease-in-out;

                &.go-back{
                    &:before{
                        content: $icon-arrow-left;
                        display: block;
                        width: 100%;
                        font-family: $icomoon-font-family;
                        font-size: 14px;
                        padding: 0;
                        color: $pink;
                        font-weight: bold;
                    }
                }
            }
            &.open{
                @include transform(translateX(100%));
                li{
                    a{
                        display: flex;
                    }
                }
            }
        }

        &.nav-sections-item-content {
            padding: 16px 44px 9px 44px;
            border-top: 1px solid rgba(0, 0, 0, .14);
        }
    }
}
