.login-container{

  .btn-black {
    width: 100%;
    display: block;
  }

  .block, .customer-review .product-details, .block-banners, .block-banners-inline {
    margin: 0;
  }
  .block{

    .block-title{
      font-family: $futura-heavy;
      font-size: 18px;
      line-height: 1.2;
      text-transform: uppercase;
      border: none;

      @media (max-width: $screen-max-sm){
        width: 100%;
        text-align: center;
      }

    }

    p{
      font-family: $futura-light;
font-weight:200;
      font-size: 15px;
    }

  }

  .fieldset {

    &:last-child {
      @media (max-width: $screen-max-sm) {
        border-bottom: 1px solid $lightgrey;
      }
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &:after{
      content: none;
    }

    &> .field {
      margin-bottom: 35px;
      position: relative;

      &> .control{
        width: 100%;
      }
    }

  }


  input[type="text"],
  input[type="email"],
  input[type="password"]{
    @extend .classic-input;
  }

  .actions-toolbar {
    margin: 30px 0;
    .forgot-pwd {
      display: block;
      width: 100%;
      font-size: 12px;
      line-height: 1.2;
      margin-bottom: 30px;

      a {
        color: #000;
        transition: all .3s;
      }

    }
  }
}
