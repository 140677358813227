.edito-block {
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    @media (max-width: $screen-max-sm){
        margin-bottom: 40px;
    }

    &__title {
        font-family: $futura-light;
        text-transform: uppercase;
        font-size: 26px;
        @media(max-width: $screen-max-sm) {
            font-size: 22px;
        }
        text-align: center;
        margin-bottom: 30px;
    }

    &__subtitle {
        width: 85%;
        font-family: $futura-light;
        font-size: 18px;
        @media(max-width: $screen-max-sm) {
            width: 100%;
            font-size: 16px;
        }
        text-align: center;
        margin-bottom: 20px;
    }
}
