.catalog-product-view,
.catalog-category-view, .catalogsearch-result-index {

    .mgk-adcpopup-modal {
        @include transition(all ease 0.5s);
        right: -100%;

        &._show {
            right: 0;
        }

        @media (max-width: $screen-max-sm) {
            left: 0;
        }

        .product-image-photo {
            max-width: 100% !important;
            height: auto !important;
        }

        .modal-inner-wrap {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            margin: 0;
            height: 100%;
            max-width: 676px;
            box-sizing: border-box;
            max-height: 100% !important;

            @media (max-width: $screen-max-sm) {
                max-height: 100%;
                width: 100%;
                .modal-content {
                    background-color: $white;
                }
            }

        }

        .modal-header {
            font-family: $futura-light;
font-weight:200;
            color: $black;
            padding-top: 22px;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(#979797, .26);
            background-color: #FFF;

            @media (max-width: $screen-max-sm) {
                padding: 25px 25px 5px;
            }

        }

        .modal-title {
            font-size: 20px;
            line-height: 1.2;
            text-transform: uppercase;
            border: none;

            @media (max-width: $screen-max-sm) {
                padding-right: 25px;
            }

        }

        .action-close {
            top: 12px;
            right: 34px;
            @include transition(all ease 1s);
            transform-origin: 50% 30%;

            &:hover {
                transform: rotate(360deg);
            }

            @media (max-width: $screen-max-sm) {
                right: 5px;
            }

        }

        .modal-content {
            padding: 0;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            @media (max-width: $screen-max-sm) {
                padding-bottom: 20px;
            }

        }

        .mgk-adcpopup-block {

            .adcpopup-summary {
                display: block;
            }

            .shopping-actions,
            .adcpopup-summary,
            .adcpopup-content {
                width: 100%;
                box-sizing: border-box;
            }

            .adcpopup-summary {
                padding-top: 22px;
                padding-bottom: 32px;
                padding-left: 40px;
                padding-right: 40px;
                background-color: $beige;

                @media (max-width: $screen-max-sm) {
                    display: block;
                    padding: 30px;
                }

            }

            .adcpopup-content {
                padding: 30px 40px;
                border: none;
                background-color: #FFF;

                @media (max-width: $screen-max-sm) {
                    padding: 15px 25px 30px;
                }

                .content-inner {
                    padding: 0;
                    border: none;
                }

            }

            .bottom-modal {
                bottom: 0;
                position: absolute;
                width: 100%;

                @media (max-width: $screen-max-sm) {
                    position: initial;
                    padding-bottom: 200px;
                }

                .shopping-actions {
                    padding: 20px 30px;
                    background-color: #000;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                    z-index: 100;

                    @media (max-width: $screen-max-sm) {
                        display: block;
                        padding: 30px 18px;
                        position: absolute;
                        bottom: 0;
                    }

                    .btn {
                        width: 48%;
                        @extend .btn;

                        &.btn-black {
                            border: 1px solid $white;
                        }

                        &:hover {
                            background: $black;
                            color: $white;
                            border: 1px solid $white;
                        }

                        @media (max-width: $screen-max-sm) {
                            display: block;
                            width: 100%;

                            &.secondary {
                                margin-bottom: 12px;
                            }

                        }

                    }

                    .action {
                        &:last-child {
                            display: none;
                        }
                    }

                }
            }
        }

        .content-cart {

            .product-item {
                width: 100%;
                margin: 0;
                padding: 0;
                border: none;

                &-photo {
                    box-sizing: border-box;
                    width: 145px;
                    border: 1px solid rgba(#979797, .22);
                    padding: 0 15px;

                    @media (max-width: $screen-max-xs) {
                        float: left;
                        width: 100px;
                        padding: 0;
                    }

                }

                &-details {
                    width: calc(100% - 145px);
                    padding-left: 34px;
                    padding-top: 15px;
                    box-sizing: border-box;


                    @media (max-width: $screen-max-xs) {
                        float: left;
                        width: calc(100% - 100px);
                        padding-left: 10px;
                        padding-top: 0;
                    }

                }

                &-name {
                    font-family: $futura-heavy;
                    font-size: 20px;
                    line-height: 1;
                    letter-spacing: .95px;
                    margin: 0 0 8px;

                    @media (max-width: $screen-max-sm) {
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                }

                &-subtitle {
                    font-family: $futura-light;
font-weight:200;
                    font-size: 14px;
                    letter-spacing: .56px;
                    line-height: 1.2;
                    margin-bottom: 22px;

                    @media (max-width: $screen-max-sm) {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                }

                &-cart {

                    &-wrapper {
                        display: flex;
                        display: -webkit-flex;
                        align-items: flex-end;
                        font-family: $futura-heavy;
                        font-size: 14px;
                        line-height: 1.2;
                        letter-spacing: .49px;

                        @media (max-width: $screen-max-sm) {
                            display: block;
                            font-size: 13px;
                        }

                    }

                    &-left {
                        width: 50%;

                        @media (max-width: $screen-max-sm) {
                            width: 100%;
                        }

                    }

                }

                &-pricing {
                    margin: 0;
                    padding: 0;
                    width: 50%;
                    text-align: right;
                    border: none;

                    @media (max-width: $screen-max-sm) {
                        width: 100%;
                        padding-right: 10px;
                        margin-top: 10px;
                        box-sizing: border-box;
                    }


                    .price-container {
                        float: none;
                    }

                    .price {
                        font-size: 14px;
                        font-family: $futura-heavy;
                        letter-spacing: .28px;
                        line-height: 1;
                    }

                }

                .details-qty {
                    margin-top: 0;
                    font-weight: initial;
                    font-family: $futura;

                    .qty-text {
                        padding: 0;
                        margin-left: 6px;
                        border: none;
                    }

                    @media (max-width: $screen-max-sm) {
                        margin-top: 20px;
                    }

                }

                .options {

                    @media (max-width: $screen-max-sm) {
                        margin-top: 4px;
                    }

                    .list {

                        .option-item {
                            font-weight: initial;

                            .label,
                            .values {
                                display: inline-block;
                                border: none;
                                padding: 0;
                                font-weight: initial;
                                font-family: $futura-light;
font-weight:200;

                                span {
                                    margin: 0 10px;
                                }
                            }

                            .value {
                                margin-left: 6px;
                            }

                        }

                    }

                }

            }

        }

        .shopping-cart {
            margin: 0;

            .summary {

                &-title {
                    padding: 0;
                    margin: 0;
                    color: $black;
                    margin-bottom: 30px;

                    @media (max-width: $screen-max-sm) {
                        margin-bottom: 18px;
                    }

                }

                &-items {
                    display: block;
                    width: 100%;
                    margin: 0;
                }

                &-item {
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: $futura-light;
font-weight:200;
                    padding: 10px 0;

                    @media (max-width: $screen-max-sm) {
                        position: relative;
                        display: block;
                        font-size: 0;
                    }

                    .photo {
                        width: 64px;
                        height: 64px;
                        background-size: cover;
                        background-position: center center;

                        @media (max-width: $screen-max-sm) {
                            width: 78px;
                            height: 78px;
                            margin-bottom: 5px;
                        }

                    }

                    .details {
                        width: 50%;
                        text-align: left;

                        @media (max-width: $screen-max-sm) {
                            display: inline-block;
                            vertical-align: top;
                            width: calc(100% - 55px);
                        }

                    }

                    .label {
                        font-size: 14px;
                        line-height: 1.1;
                        padding: 0;
                        font-weight: 300;
                        border: 0;
                        text-align: left;
                    }

                    .subtitle {
                        font-size: 12px;
                        color: rgba(0, 0, 0, .42);
                    }

                    .price {
                        font-family: $futura-heavy;
                        font-size: 14px;
                        letter-spacing: .28px;
                        margin-left: 20px;

                        @media (max-width: $screen-max-sm) {
                            position: absolute;
                            z-index: 1;
                            top: 20px;
                            right: 10px;
                        }

                    }

                    .actions {
                        display: flex;
                        display: -webkit-flex;

                        @media (max-width: $screen-max-sm) {
                            display: inline-block;
                            vertical-align: top;
                            width: 55px;
                        }

                        .switch {
                            label {
                                transform: translateY(-15px);
                            }
                        }

                    }

                }

            }

        }

    }

}

.popup-cart-overview {
    text-transform: uppercase;
    font-family: $futura;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #000;
    margin-bottom: 0;


    @media (max-width: $screen-max-sm) {
        font-size: 14px;
        line-height: 1.4;
    }

}

#mgkAdcPopup.show {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100vh;

    #ajaxConfirmMessage {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #fff;
        background-color: #313236;
        opacity: 0.74;
        z-index: 1000;

        p {
            margin: 13px 45px 14px 29px;

            i {
                margin-right: 10px;

                &::before {
                    color: #7ED321;
                }
            }
        }

        .icon-cross {
            position: absolute;
            right: 20px;
            transform: translateY(-50%);
            top: 47%;
            cursor: pointer;
        }
    }
}

.catalog-category-view {
    .select2-container {
        z-index: 100000 !important;
    }
}
