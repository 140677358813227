.breadcrumbs {
    max-width: unset;
    padding: 8px 0;
    border-top: 0;
    border-bottom: 0;
    margin-bottom: 0;

    @media (max-width: $screen-max-sm) {
        display: block;
        margin-bottom: 0;
        border-top: none;
    }

    .items {
        //@extend .container-ph;
        width: 100%;
        max-width: 100%;
        padding-right: 37px;
        padding-left: 37px;
        margin-right: auto;
        margin-left: auto;

        @media (max-width: $screen-max-sm) {
            padding-right: 22px;
            padding-left: 22px;
        }
    }

    strong, a {
        font-family: $futura-light;
        font-weight: 200;
        font-weight: inherit;
        color: $pink;
        font-size: 14px;
    }

    a {
        font-family: $futura-light;
        font-weight: 200;
        font-weight: inherit;
        color: $black;
        font-size: 14px;
        display: inline-block;
        @include transition(ease all 0.4s);

        &:visited {
            color: $black;
        }

        &:hover {
            text-decoration: none;
            color: $black;
        }
    }

    .item {
        &:not(:last-child) {
            &::after {
                content: ">";
                color: $black;
                font-family: $futura-light;
                font-weight: 200;
                margin: 0 3px;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

}
