.citation-block-widget{
  margin-bottom: 35px;
  text-align: center;
  width: 65%;
  @media (max-width: $screen-max-md){
    width: 100%;
    padding: 10px;
  }
  .contenu-citation{
    font-size: 22px;
    font-style: italic;
    letter-spacing: 0.54px;
    line-height: 35px;
    &.bold-text{
      font-family: $futura-heavy;
    }
    @media (max-width: $screen-max-md){
      font-size: 18px;
    }
    &::before{
        content: "''";
        margin: 0 5px;
      }
    &::after{
      content: "''";
      margin: 0 5px;
    }

  }
  .auteur-citation{
       font-size: 12px;
       color: $grey-widget;
       letter-spacing: 0.54px;
     }

}