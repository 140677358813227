.bandeau-banner-text{
  margin-bottom: 45px;
  .banner{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 350px;
  }
  .banner-contenu{
    background-color: $white;
    margin: 10px auto 0;

    .intro{
      text-align: center;
      font-size: 22px;
      letter-spacing: 0.77px;
      line-height: 30px;
      @media (max-width: $screen-max-md){
        font-size: 18px;
      }
    }
    .contenu{
      margin-top: 30px;
      text-align: center;
      font-size: 18px;
      letter-spacing: 0.6px;
      line-height: 30px;
      @media (max-width: $screen-max-md){
        font-size: 16px;
      }
    }

  }
}
