.add-address-modal.modal-popup.modal-slide {
  @media (max-width: $screen-max-sm) {
    left: 0;
  }
  .modal-inner-wrap {
    overflow-y: auto;
    padding: 50px 100px;
    background-color: $white;
    height: 100%;

    @media (max-width: $screen-max-sm) {
      padding: 30px 50px;
      background-color: $white;
      position: absolute;
      left: unset;
      width: 70%;
    }

    @media (max-width: $screen-max-xs) {
      width: 100%;
    }

    .modal-header {
      padding: 0;
      .modal-title {
        text-transform: uppercase;
        border: none;
        font-family: $futura-heavy;
        font-size: 20px;
        padding-bottom: 0;
      }
      .action-close {
        top: 15px;
        right: 15px;
        &:before {
          font-size: 16px;
        }
      }
    }

    .modal-content {
      overflow-y: unset;
      padding: 0;
      form {
        margin-top: 30px;
        max-width: unset;

        .field.choice {
          margin: 35px 0;
        }

        .field.street{
          .label{
            clip: unset;
            width: auto;
            height: auto;
          }
        }

        .select2-selection {
          border: 0;
          min-height: 32px;
          border-bottom: 1px solid #000;
          padding-left: 0;
        }
      }
    }

    .modal-footer {
      padding: 0;
      border: none;
      .action {
        width: 100%;
        margin-top: 35px;
      }
    }
  }
}
