.cms-noroute-index {

  main{
    margin-top: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .columns{

    .column{

      &.main{
        padding-bottom: 0;
      }

    }

  }

  .no-route-background  {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    //position: fixed;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
    padding: 0;
    position: relative;

    &::before{
      content: "";
      display: block;
      position: relative;
      padding-top: 40%;

      @media (max-width: $screen-max-sm) {
        padding-top: 100%;
      }
      @media (min-width:$screen-min-sm) and (max-width: $screen-max-md) {
        padding-top: 60%;
      }
    }

    .popin-no-route {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 50%;
      padding: 50px 80px;
      background-color: $white;
      text-align: center;

      @media (max-width:$screen-max-sm) {
        min-width: 80%;
        padding: 30px;
      }

      h1 {
        font-weight: 700;
        font-size: 50px;
        margin-bottom: 30px;
      }

      .btn-no-route {
        display: block;
        background-color: $black;
        color: $white;
        padding: 15px 30px;
        border: 1px solid $black;
        transition: all .3s;
        max-width: 70%;
        margin: 30px auto 0;
        text-transform: uppercase;
        font-size: 11px;
      }
    }
  }
}
