//.cms-index-index {
//    .product-items .product-item-actions {
//        @media (min-width: $screen-min-md) {
//            top: 377px;
//        }
//        @media (max-width: $screen-max-sm) {
//            top: 337px;
//        }
//    }
//}

.product-items {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    font-size: 0;

    .product-item {
        width: 50%;
        margin: 0;
        padding: 5px;
        .product-image-wrapper{
            padding-bottom: 98% !important;
        }

        @media (min-width: $screen-min-sm) and (max-width: $screen-max-pad) {
            width: 100%;
        }

        @media (max-width: $screen-max-md) {
            border: none;
        }

        @media (max-width: $screen-min-sm) {
            border: none;
            width: 100%;
            padding: 0;
            margin: 0 0 13px;

            &-info {
                border: 1px solid #e2e2e2;
                padding-top: 20px;
            }

            &-photo {
                display: block;
                width: 100%;
                text-align: center;
                margin-bottom: 0;
            }

        }

        .product-item-info {
            width: unset;
            border: 0;
            padding: 0;
            height: 100%;
            backface-visibility: hidden;
            margin: 0;

            &:hover {
                position: static;
                margin: 0;
                padding: 0;
                border: 0;
                box-shadow: none;

                .product-item-inner {
                    border: unset;
                    clip: unset;
                    height: unset;
                    margin: unset;
                    overflow: unset;
                    padding: unset;
                    position: unset;
                    width: unset;
                    box-shadow: unset;
                }

                .mpquickview-button {
                    display: flex;
                }
            }

            @media (max-width: $screen-max-sm) {
                border: 0;
            }

            .product-item-name,
            .product.name a,
            .price {
                margin-top: 0;
                font-weight: initial;
                font-size: 18px;
                font-family: $futura-heavy;
                color: $black;
                hyphens: none;
                line-height: 26px;
                text-transform: uppercase;

                @media (max-width: $screen-max-lg) {
                    max-width: 100%;
                    font-size: 16px !important;
                    line-height: 1.1 !important;
                }
            }

            .product-item-name {
                @media (max-width: $screen-max-sm) {
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }

            .product-image-container {
                display: block;
                margin: 0 auto;
                width: 100% !important;
            }

            .product-item-photo {
                width: 100%;
                aspect-ratio: 1 / 1;
                z-index: 3;

                .stock-info-wrapper {
                    display: none;
                }

                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: $screen-max-sm) {
                    margin: 0;
                }
            }

            .product-item-name {

                a {
                    @include transition(ease 0.4s all);

                    &:hover {
                        color: $pink;
                        text-decoration: none;
                    }

                }

            }

            .product-item-details {
                padding: 25px 0 0;
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;

                @media (max-width: $screen-max-sm) {
                    padding: 15px;
                }
                .wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    flex-direction: column;
                    @media (min-width: $screen-min-md) {
                        flex-direction: row;

                        > div:first-of-type {
                            width: 75%;
                        }
                        > .price-container-ph {
                            width: 25%;
                        }
                    }
                    .price-container-ph {
                        position: relative;
                        .price-final_price{
                            display: flex;
                            flex-direction: column;
                            .price-label {
                                @media (min-width: $screen-min-md) {
                                    position: absolute;
                                    top: -20px;
                                    right: 0;
                                }
                            }
                        }
                        @media (max-width: $screen-max-md) {
                            width: 100%;
                            .price-final_price {
                                flex-direction: row;
                                align-items: flex-end;
                                .price-label {
                                    margin-right: 5px;
                                }
                                .price-wrapper {
                                    width: auto;
                                }
                                .price-container {
                                    align-items: flex-end;
                                }
                            }
                        }
                    }
                    .type-ph {
                        min-width: 100%;
                        display: none;
                    }
                }
            }


            &.unavailable {
                position: relative;
                filter: grayscale(60%);

                .product-item-name,
                .product.name a,
                .price,
                .product-item-description {
                    color: #909090;
                }

                .stock-info-wrapper {
                    position: absolute;
                    width: 100%;
                    height: 99%;
                    background-color: rgba(240, 240, 240, 0.39);
                    left: 0;
                    top: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 14;

                    .stock-info {
                        display: block;
                        background: $white;
                        padding: 12px 25px;
                        border: 1px solid $black;
                        font-size: 18px;
                        text-transform: uppercase;
                        @media (max-width: $screen-max-sm) {
                            font-size: 14px;
                            padding: 12px;
                        }
                    }
                }
            }
        }

        .product-item-description {
            font-size: 14px;
            margin: 0 0 10px;
            text-transform: uppercase;
        }

        .price-box {
            padding-bottom: 10px;
            margin: 0;
            width: 100%;
            .price-label {
                display: block;
                font-family: $futura;
                line-height: 17.14px;
            }
            .price-wrapper {
                width: 100%;
                text-align: right;
            }
        }

        .details {

            @media (max-width: $screen-max-sm) {
                padding: 5px 15px 0;
            }

            & > p {

                @media (max-width: $screen-max-sm) {
                    padding: 0 12px;
                }

            }



        }

        &.product-slider {
            .slider-product-item-info {
                width: 100% !important;

                .slider-product-item-photo {
                    .product-image-photo {
                        width: auto !important;
                    }
                }

                .slider-product-item-details {
                    .slider-product-item-actions {
                        display: none;
                    }
                }
            }
        }

    }

    .product-item-info:not(:hover):not(.active) .product-item-inner {
        border: unset;
        clip: unset;
        height: unset;
        margin: unset;
        overflow: unset;
        padding: unset;
        position: unset;
        width: unset;
        box-shadow: unset;
    }

    .product-item-actions {
        display: flex;
        display: -webkit-flex;
        margin: 5px 0 0;
        width: 100%;

        .tocart {
            margin-top: 0;
            height: unset;
            position: unset;
            transform: unset;
            transition: all .3s;
            text-transform: uppercase;
            font-weight: normal;
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            color: $black;
            border: 1px solid $black;
            border-radius: 22px;
            width: max-content;
            font-size: 14px;
            font-family: $futura;
            padding: 6px 12px;

            &:hover {
                background-color: $black;
                color: $white;
                transition: all .3s ease;
            }
            i{
                margin-right: 14px;
                font-size: 20px;
            }
            &:before {
                content: unset;
            }

            @media (max-width: $screen-max-md) {
                font-size: 12px;

                i{
                    font-size: 16px;
                    margin-right: 8px;
                }
            }
        }

        .towishlist {
            position: relative;
            height: 41px;
            width: 39px;
            margin-left: 5px;
            border: 1px solid $black;
            transition: all .4s;

            &.action {
                color: $black;
                width: 45px;
            }

            @media (max-width: $screen-max-sm) {
                width: 46px;
                height: 46px;
                margin-left: 2px;
            }

            &:hover {
                background-color: $black;

                &:before {
                    color: white;
                }
            }

            &:before {
                display: block;
                font-size: 18px;
                line-height: 40px;
                width: unset;
                color: $black;


                @media (max-width: $screen-max-sm) {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    @include transform(translateY(-50%) translateX(-50%));
                }

            }

        }
    }

    .owl-nav {
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: 0;

        button.owl-prev,
        button.owl-next {
            position: absolute;
            top: 0;
            box-shadow: none;
            background: transparent;
            border: none;
            font-size: 22px;
        }

        .owl-prev,
        .owl-next {
            margin: 0;
            @include transition(all ease 0.4s);

            &:hover {
                color: $black;
                background-color: transparent;
            }

        }

        .owl-prev {
            left: 0;
            padding: 0;
        }

        .owl-next {
            right: 0;
            padding: 0;
        }

    }

    .owl-dots {
        .owl-dot {
            span {
                display: none;
            }
        }
    }
}

.product {

    &.details {

        &.slider-product-item-details {

            @media (max-width: $screen-max-sm) {
                margin-left: 0;
            }

        }

    }

}
