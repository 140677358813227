.banner-showcase {
    &.loading {
        .waiting-video {
            opacity: 1;
        }

        iframe {
            opacity: 0;
        }
    }

    .waiting-video {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        &.hidden {
            opacity: 0;
        }
    }

    .vimeo-wrapper {
        position: relative;
        @media (max-width: $screen-max-xs) {
            min-height: 0 !important;
            height: auto !important;
            padding-top: 56.25%;
            position: relative;
            overflow: hidden;
        }
    }

    .vimeo-wrapper-inner {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 30px;
        transition: opacity 0.5s ease;

        &.left {
            justify-content: flex-start;
        }

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: flex-end;
        }

        iframe {
            pointer-events: none;
            width: 100vw;
            height: 76.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 200.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 0.5s ease;

            &.visible {
                opacity: 1;
            }

            //@media (max-width: $screen-max-md) {
            //    height: 66.25vw;
            //    min-height: 100vh;
            //    min-width: unset;
            //}
        }

        .cta {
            z-index: 10;
            position: relative;
        }
    }

    @media (max-width: $screen-max-sm) {
        margin: 0 -20px;
        overflow: hidden;
    }
}
