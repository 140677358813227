/*
* CMS PAGE - MACARONS
*/
.macarons-page {

    #maincontent {
        width: 100%;
        max-width: $container;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
    }

    .page-title-wrapper {
        text-align: center;
        padding-top: 20px;
        margin-bottom: 50px;

        @media (max-width: $screen-max-sm) {
            margin-bottom: 20px;
        }

        h1 {
            position: relative;
            font-family: $futura-light;
font-weight:200;
            font-size: 22px;
            text-transform: uppercase;
            margin: 0;
            padding: 0;

            &::before {
                content: '';
                position: relative;
                top: auto;
                left: auto;
                display: block;
                margin: 0 auto 20px;
                width: 1px;
                height: 24px;
                background-color: #C72C48;
                @include transform(none);
            }

        }

    }

}

.slider-thumbs {
    position: relative;
    margin-bottom: 90px;
    height: auto;

    @media (max-width: $screen-max-sm) {
        height: auto;
        margin-bottom: 40px;
    }

    &-inner {
        display: block;
        width: calc(100% - 110px);
        margin: 0 auto;
        background-color: rgba(#000, .03);
        font-size: 0;
        overflow: hidden;

        @media (max-width: $screen-max-sm) {
            width: 100%;
            background-color: #FFF;
        }

    }

    .slider-thumbs-next,
    .slider-thumbs-prev,
    .slick-prev,
    .slick-next {
        position: absolute;
        z-index: 1;
        top: 0;
        width: 55px;
        height: 100%;
        background-color: #FFF;
        border: none;
        @include border-radius(0);
        padding: 0;

        @media (max-width: $screen-max-sm) {
            background-color: transparent;
        }

        &.slick-hidden {
            display: none;
        }

    }

    .slider-thumbs-next,
    .slider-thumbs-prev {

        i {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            font-size: 16px;
            @include transform(translateY(-50%) translateX(-50%));
            @include transition(color .3s $easeOutQuad);
        }

        &:hover {

            i {
                color: $black;
            }

        }

        @media (max-width: $screen-max-md) {
            &:hover {

                i {
                    color: initial;
                }

            }
        }

        @media (max-width: $screen-max-sm) {
            top: 20px;
            height: 140px;
        }

    }

    .slider-thumbs-prev,
    .slick-prev {
        left: 0;
    }

    .slider-thumbs-next,
    .slick-next {
        right: 0;
    }

    .slider-thumb {
        display: inline-block !important;
        vertical-align: top;
        font-family: $futura-light;
font-weight:200;
        font-size: 11px;
        letter-spacing: .71px;
        line-height: 1;
        width: 120px !important;
        height: 100%;
        min-height: 178px;
        padding: 20px;
        text-align: center;
        background-color: rgba(#FFF, 0);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include transition(background-color .3s $easeOutQuad);

        @media (max-width: $screen-max-sm) {
            height: auto;
            width: auto !important;
        }

        &.slick-current,
        &.active,
        &:hover {
            background-color: rgba(#FFF, 1);
        }

        .thumb {
            position: relative;
            width: 70px;
            height: 70px;
            overflow: hidden;
            margin-bottom: 2px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: $screen-max-sm) {
                width: 100%;
                height: auto;
                min-height: 180px;
            }

            img {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                width: 50px;
                height: 50px;
                max-width: 100%;
                @include transform(translateY(-50%) translateX(-50%));
                @media (max-width: $screen-max-sm) {
                    width: 180px;
                    height: 180px;
                }
            }

        }

        .thumb-subtitle,
        .thumb-link,
        .thumb-desc {
            display: none;
            @media (max-width: $screen-max-sm) {
                display: block;
            }
        }

        .thumb-link {
            @media (max-width: $screen-max-sm) {
                a {
                    display: inline-block;
                    margin-top: 20px;
                }
            }
        }

        .thumb-name {

            @media (max-width: $screen-max-sm) {
                font-family: $futura-heavy;
                font-size: 20px;
                line-height: 1.5;
                letter-spacing: .26px;
                text-transform: uppercase;
                margin: 15px 0;
            }

        }

        .thumb-subtitle {
            font-family: $futura-light;
font-weight:200;
            font-size: 20px;
            margin-bottom: 14px;
            letter-spacing: 1.29px;
        }

        .thumb-desc {
            font-family: $futura-light;
font-weight:200;
            font-size: 15px;
            line-height: 1.5;
            letter-spacing: .97px;
        }

    }

}

.macarons-slider {
    width: 100%;
    margin: 0 auto;

    .slider-thumbs {
        .slider-thumb {
            &.active,
            &.slick-current,
            &:hover {
                background-color: $white;
            }
        }
    }

    .slick-slide {
        &.active {
            &.slick-current {
                &.slick-active {
                    background-color: $white;
                }
            }
        }
    }
}

.macarons-slider-details {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    font-family: $futura-light;
font-weight:200;
    font-size: 15px;
    margin-bottom: 50px;

    @media (max-width: $screen-max-sm) {
        display: none;
    }

    .right,
    .left {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        padding: 0 15px;
    }

    .left {
        width: 45%;
        text-align: center;
    }

    .right {
        width: 50%;
        padding-right: 100px;

        @media (max-width: $screen-max-md) {
            padding-right: 0;
        }

        h2 {
            font-family: $futura-heavy;
            font-size: 25px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: .33px;
            text-transform: uppercase;
            margin: 0;
        }

        .subtitle {
            font-family: $futura-light;
font-weight:200;
            font-size: 20px;
            line-height: 2;
            letter-spacing: 1.29px;
            margin-bottom: 10px;
        }

        p {
            line-height: 1.8;
        }

        .btn {
            display: inline-block;
            width: 340px;
            max-width: 100%;

            &.hide {
                display: none;
            }

        }

    }

    .macarons-slider-details-desc {
        margin-bottom: 40px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

        }

    }

}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;

    @media (max-width: $screen-max-sm) {
        height: auto;
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: stretch;
}

.slick-slide {
    float: left;
    height: initial;
    min-height: 1px;

    @media (max-width: $screen-max-sm) {
        min-height: unset;
    }

    &.slick-initialized {
        display: block;
    }

}
