#button-back-top {
  display: flex;
  position: fixed;
  bottom: 70px;
  right: 25px;
  transform: rotate(180deg);
  z-index: 10;
  padding: 0;
  border: none;
  background-color: transparent;

  icon {
    border: 1px solid $black;
    font-size: 10px;
    color: $black;
    padding: 10px;
    background-color: rgba($white, 0.5);
  }
}
