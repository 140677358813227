:root {
    --videoHeight: 700px;
    --videoHeightMobile: 400px;
}

.banner-video {
    position: relative;

    .default-img {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }

    .waiting-video {
        position: absolute;
        inset: 0;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        transition: opacity 1s ease-in-out;

        &.hide {
            opacity: 0;
        }
    }

    .youtube-wrapper {
        pointer-events: none;
    }

    .waiting-video, .youtube-wrapper{
        height: var(--videoHeight);
        @media (max-width: $screen-max-sm) {
            height: var(--videoHeightMobile);
        }
    }

    #player {
        width: 100%;
        height: 100%;
    }
}
