.rendered-form.amform-form {
  margin-top: 35px;

  @media (max-width: $screen-max-sm) {
    padding: 0 15px;
  }
  button[type="submit"] {
    @include transition(all ease .4s);
    @extend .action;
    @extend .submit !optional;
    width: 100%;
      font-family: $futura-light;
font-weight:200;
      font-size: 13px;
      letter-spacing: .46px;
      text-transform: uppercase;
      line-height: 1.2;
      min-height: auto;
      padding: 8px 14px !important;
      border: 1px solid $black;
      box-sizing: border-box;
      text-align: center;
      border-radius: 22px !important;
      @include transition(all ease 0.4s);
      text-decoration: none;
      color: $white;
      background-color: $black;
      border-color: $black;

    &:hover, &:focus {
      color: black;
        background: inherit;
    }
  }

  .fieldset .fields {
      .select2-container {
          width: 100% !important;
      }
    .select2-selection {
      border: 0;
      border-radius: 0;
      min-height: 43px;
      border-bottom: 1px solid #000;
      padding-left: 0;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: black;
    }

    .field {
      input:not([type=checkbox]):not([type=number]) {
        color: black;
      }

      &.fb-select {
        .mage-error {
          @include transform(translateY(66px));
        }
      }

      &.amcform-gdpr {
          margin-bottom: 85px;
          min-height: 40px;

          @media (max-width: $screen-max-xs) {
              margin-bottom: 35px;
          }
        [type="checkbox"] {
          margin-right: 5px;
        }

        & > .mage-error {
          @include transform(translateY(25px));

            @media (max-width: 375px) {
                @include transform(translateY(45px));
            }
        }
      }

      &.form-group {
        margin-bottom: 45px;
      }

      &.amform-layout-two:nth-child(2n){
        margin-right: 0;
        max-width: 50%;
      }
    }

      .amcform-toolbar {
          margin-right: 2%;
      }
  }
}
.cms-page-view {
  #form_submit_loading{
    display: none !important;
  }

  .field-description {
    margin-bottom: 15px;

    .label {
      position: initial;
      margin: 20px 0;
    }

    textarea {
      color: black;
      border: 1px solid $black;
      resize: none;

      &.mage-error {
        border-color: $pink;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
